
import API from './request-mgr.service';
import BaseService from './base.service';
export default class SearchService extends BaseService {
	static async search(query, params) {
		return API.get(query, { params });
	}

	static async searchV2(query, params) {
		return API.get(`search/v2${query ? `?query=${query}` : ''}`, { params });
	}

	static async searchCount(query, params) {
		return API.get(`search/v2/count${query ? `?query=${query}` : ''}`, { params });
	}

	static async searchPlaces(query) {
		return API.get(`search/places${query ? `?query=${query}` : '?query=א'}`);
	}

}
