import * as React from 'react';
import DialogComponent from 'components/dialog/DialogComponent/DialogComponent';
import { PlaceContext, MealContext } from 'context/ContextProvider';
import { UserContext } from 'context/UserProvider';
import LoginComponent from 'components/dialog/dialogContent/user/Login/LoginComponent';
import UserGate from 'pages/routing/UserGate';
import RateMealForm from 'components/dialog/dialogContent/meal/RateMeal/RateMealForm';
import UpdateMealsForm from 'components/dialog/dialogContent/meal/UpdateMeals/UpdateMealsForm';
import UserProfile from 'components/dialog/dialogContent/user/profile/UserProfile';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ReactGA from 'react-ga';
import ExpandedFilterPanel from 'components/dialog/dialogContent/meal/ExpandedFilterPanel/ExpandedFilterPanel';
import UpdatePlaceComponent from 'components/dialog/dialogContent/place/AddPlace/UpdatePlaceComponent';
import WelcomeDialog from 'components/dialog/dialogContent/welcome/WelcomeDialog';

export type DialogContextType = {
	openDialog: string | null;
	setOpenDialog(name: string | null): void;
	setDialogProps(props: any): void;
};

export const DialogContext = React.createContext<DialogContextType>({
	openDialog: null,
	setOpenDialog: () => undefined,
	setDialogProps: () => undefined,
});
DialogContext.displayName = 'DialogContext';

const DIALOG_LIST = ['place', 'meal', 'review', 'login', 'welcome'];

export interface DialogManagerProps {
	children: React.ReactNode[];
	appLoading: boolean;
}

const DialogManager: React.FC<DialogManagerProps> = ({ children, appLoading }) => {
	const history = useHistory();
	const isHome = useRouteMatch({ path: '/', exact: true, strict: true });

	const [openDialog, setOpenDialogState] = React.useState<string | null>(null);
	const [dialogProps, setDialogProps] = React.useState<any>(null);

	const { activeMeal, setActiveMeal, isEditingMeal, setEditingMeal } = React.useContext(
		MealContext
	);
	const { activeUser } = React.useContext<any>(UserContext);
	const { isEditingPlace, setEditingPlace, setActivePlace, activePlace } = React.useContext<any>(
		PlaceContext
	);

	const setOpenDialog = (name: string | null) => {
		if (!name) {
			history.replace({ pathname: history.location.pathname, search: '' });
		} else {
			ReactGA.modalview(`/${name}`);
			history.push({
				pathname: history.location.pathname,
				search: `dialog=${name}`,
			});
		}
		setOpenDialogState(name);
	};

	const closeDialog = () => {
		setOpenDialog(null);
	};

	const shouldOpen = (name: string) => {
		return openDialog === name && history.location.search === `?dialog=${name}`;
	};

	const validateDialog = (dialogName: string) => {
		const conditionArray = [
			(dialogName === 'meal' || dialogName === 'review') &&
				(!activeMeal || activeMeal == 'not_found'),
			dialogName === 'place' && activePlace == 'not_found',
			(dialogName === 'activity' || dialogName === 'profile') && !activeUser,
		];
		return !conditionArray.includes(true) && DIALOG_LIST.includes(dialogName);
	};

	const openHelloDialog = () => {
		if (!window.localStorage.getItem('qb_h_d')) {
			setOpenDialog('welcome');
		}
		return null;
	};

	React.useEffect(() => {
		const checkDialog = () => {
			const currentSearch = history.location.search;
			if (currentSearch && currentSearch.includes('?dialog=')) {
				const currentDialogName = currentSearch.slice(currentSearch.lastIndexOf('=') + 1);
				if (validateDialog(currentDialogName)) {
					setOpenDialogState(currentDialogName);
					return currentDialogName;
				} else {
					history.replace({
						pathname: history.location.pathname,
						search: '',
					});
					return null;
				}
			}
			return null;
		};

		if (!appLoading) {
			const dialog = checkDialog();
			if (isHome && !dialog) {
				openHelloDialog();
			}
		}
	}, [appLoading]);

	return (
		<DialogContext.Provider value={{ openDialog, setOpenDialog, setDialogProps }}>
			<DialogComponent
				title={null}
				useDividers={false}
				onClose={closeDialog}
				open={shouldOpen('login')}
			>
				<LoginComponent />
			</DialogComponent>

			<DialogComponent
				disableBackdropClick
				disableEscapeKeyDown
				noClose={true}
				title={null}
				useDividers={false}
				onClose={closeDialog}
				open={shouldOpen('welcome')}
				onExited={() => window.localStorage.setItem('qb_h_d', 'exited')}
			>
				<WelcomeDialog closeDialog={closeDialog} />
			</DialogComponent>

			<DialogComponent
				title={`${dialogProps?.title} (${dialogProps?.options.length}) ` || ''}
				useDividers={false}
				onClose={closeDialog}
				open={shouldOpen('moreFilters')}
				onExited={() => setDialogProps(null)}
			>
				<ExpandedFilterPanel
					filters={dialogProps?.options || []}
					filterName={dialogProps?.title || ''}
					exitDialog={closeDialog}
				/>
			</DialogComponent>

			<DialogComponent
				title={null}
				useDividers={false}
				onClose={closeDialog}
				open={shouldOpen('profile')}
			>
				<UserProfile user={activeUser} />
			</DialogComponent>

			<DialogComponent
				title={isEditingMeal ? 'עריכת מנה' : 'הוספת מנה חדשה'}
				open={shouldOpen('meal')}
				onClose={closeDialog}
				disableBackdropClick
				onExited={() => {
					activePlace && setActiveMeal(null);
					isEditingMeal && setEditingMeal(false);
				}}
			>
				<UserGate
					component={UpdateMealsForm}
					loginMsg="על מנת לעדכן מנות יש להתחבר לחשבון"
				/>
			</DialogComponent>

			<DialogComponent
				title={null}
				useDividers={false}
				open={shouldOpen('review')}
				onClose={() => closeDialog()}
				onExited={() => {
					activePlace && setActiveMeal(null);
				}}
			>
				<UserGate
					component={RateMealForm}
					loginMsg="על מנת לדרג מנות יש להתחבר לחשבון"
					exitDialog={closeDialog}
				/>
			</DialogComponent>

			<DialogComponent
				title={isEditingPlace ? 'עריכת פרטי מסעדה' : 'הוספת מסעדה חדשה'}
				open={shouldOpen('place')}
				onClose={() => closeDialog()}
				onExited={() => {
					activeMeal && setActivePlace(null);
					setEditingPlace(false);
				}}
			>
				<UserGate
					component={UpdatePlaceComponent}
					placeToEdit={isEditingPlace ? activePlace : null}
					loginMsg="על מנת להוסיף או לערוך פרטי מסעדה יש להתחבר לחשבון"
					handleCloseDialog={() => {
						closeDialog();
						setEditingPlace(false);
					}}
				/>
			</DialogComponent>
			{children}
		</DialogContext.Provider>
	);
};

export default React.memo(DialogManager);
