import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import QuestRating from 'components/general/QuestRating';
import MultiSelectFilter from 'components/topbar/filters/MutliSelectFilter';
import Skeleton from 'react-loading-skeleton';
import { Control, Controller } from 'react-hook-form';
import { MealPart } from 'types/meal';
import CheckboxComponent from 'components/general/CheckBox/CheckBoxComponent';

interface StarRatingItemProps {
	item: MealPart;
	options: any;
	addonOptions: any;
	control: Control<Record<string, any>> | undefined;
	rules: any;
	isLoading: boolean;
	name: string;
}

const StarRatingItem: React.FC<StarRatingItemProps> = ({
	item,
	options,
	addonOptions,
	control,
	rules,
	isLoading,
	name,
}) => {
	const hasOptions =
		options && (typeof options === 'string' || (Array.isArray(options) && options.length > 0))
			? options
			: false;
	const [optionValue, setOptionValue] = useState(hasOptions || null);
	const [allowEmpty, setAllowEmpty] = React.useState<boolean>(false);

	const RATING_QUESTIONS = {
		bun: (
			<>
				<span>איך הייתה הלחמניה?</span>
				<br />
				{isLoading ? (
					<Skeleton width={100} />
				) : (
					<>
						{typeof options === 'string' ? (
							<span style={{ fontSize: 13, fontWeight: 600 }}>{options}</span>
						) : (
							<MultiSelectFilter
								theme="dark"
								currentValues={[options]}
								options={[{ value: options, label: options }]}
								handleSelectionChange={(value) => console.log(value)}
							/>
						)}
					</>
				)}
			</>
		),
		patty: 'איך הייתה הקציצה?',
		addons: (
			<>
				<span>איך היו התוספות?</span>
				<br />
				{isLoading ? (
					<Skeleton width={200} />
				) : (
					<MultiSelectFilter
						theme="dark"
						currentValues={
							options && Array.isArray(options)
								? options.map((item) => item.name)
								: []
						}
						options={addonOptions}
						handleSelectionChange={(value) => setOptionValue(value)}
						emptyValue="בחירת תוספות"
					/>
				)}
			</>
		),
		valueForMoney: 'האם המחיר הצדיק את המנה?',
	};

	return (
		<Grid container alignItems="center">
			<Grid item xs={9} md={8}>
				{RATING_QUESTIONS[item.type]}
				{/* {renderQuestion()} */}
			</Grid>
			<Grid item xs>
				<Box display="flex" justifyContent={window.innerWidth < 480 ? 'center' : 'left'}>
					<Controller
						name={name || item._id}
						control={control}
						rules={rules}
						defaultValue={null}
						render={({ value, ref, ...props }) => (
							<QuestRating
								{...props}
								ref={ref}
								value={Number(value)}
								size="large"
								dir="ltr"
								disabled={isLoading || (options && !optionValue && !allowEmpty)}
								name={`${item.type}StarRating`}
							/>
						)}
					/>
				</Box>
			</Grid>
			{options && !optionValue ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<span
						style={{
							fontSize: 12,
							color: 'var(--gunmetal-color)',
						}}
					>
						{`נראה שהשארת את ה${'תוספות'} ריקות... אכלת המבורגר בלי תוספות?`}{' '}
					</span>
					<CheckboxComponent
						label="כן, תנו לי לדרג"
						textOverflow="free"
						onChange={(value) => setAllowEmpty(value)}
					/>
				</div>
			) : null}
		</Grid>
	);
};

export default React.memo(StarRatingItem);
