import * as React from 'react';
import s from './UploadImagesMiniStyle.module.scss';
import MealsService from 'api/services/meals.service';
import { UserContext, UserContextType } from 'context/UserProvider';
import ImageDropZone from 'components/forms/ImageDropZone/ImageDropZone';
import MainButton from 'components/general/MainButton/MainButton';
import { ToastContext, ToastContextType } from 'context/ToastProvider';

export interface UploadImagesMiniProps {
	mealId: string;
	onSuccess?(): void;
}

const UploadImagesMini: React.FC<UploadImagesMiniProps> = ({ mealId, onSuccess }) => {
	const [images, setImages] = React.useState<File[] | null>(null);
	const [errorMsg, setErrorMsg] = React.useState<string>('');
	const { toast, setToast } = React.useContext<ToastContextType>(ToastContext);
	const [isSending, setIsSending] = React.useState<boolean>(false);
	const { activeUser } = React.useContext<UserContextType>(UserContext);
	const isAdmin = (activeUser && activeUser?.roles.includes('admin')) || false;

	const uploadImages = async () => {
		setErrorMsg('');
		if (images && images.length > 0) {
			setIsSending(true);
			const uploadeReq = isAdmin
				? MealsService.uploadImages
				: MealsService.newUploadImagesRequest;
			const imageData = new FormData();
			images.forEach((file) => imageData.append('images', file, file.name));
			await uploadeReq(mealId, imageData)
				.then(() => {
					setImages([]);
					setToast({ ...toast, message: 'התמונות נוספו בהצלחה! תודה מקרב לב!' });
					onSuccess && onSuccess();
				})
				.catch(() => setErrorMsg('קרתה תקלה בהעלאת התמונות, נסו שוב'))
				.finally(() => setIsSending(false));
		}
	};

	return (
		<div className={s.root}>
			<ImageDropZone
				text="לחצו להוספת תמונות"
				isMulti
				onFileChange={(imgs) => setImages(imgs)}
				onFilesReject={(msg: string) => setErrorMsg(msg)}
			/>
			<span className="errorMsg">{errorMsg}</span>
			<MainButton
				handleClick={uploadImages}
				loading={isSending}
				disabled={!images || images.length < 1}
			>
				העלאה
			</MainButton>
		</div>
	);
};

export default React.memo(UploadImagesMini);
