/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BaseService from './base.service';
import API from './request-mgr.service';

export default class MealsService extends BaseService {
	static async create(meal, params) {
		return API.post('meals', meal, { params });
	}

	static async upsert(meal, params) {
		return API.put('meals', meal, { params });
	}

	static async update(id, meal) {
		return API.patch(`meals/${id}`, meal);
	}

	static async delete(id) {
		return API.delete(`meals/${id}`);
	}

	static async createRequest(meal) {
		return API.post('meals/requests', meal);
	}

	static async createUpdateRequest(id, meal, params) {
		return API.post(`meals/${id}/requests`, meal, { params });
	}

	static async submitReview(id, review) {
		return API.post(`meals/${id}/submitReview`, review);
	}

	static async getReviews(id, userId) {
		return API.get(`meals/${id}/reviews`, userId && { params: { filter: { user: userId } } });
	}

	static async uploadImages(id, images) {
		const headers = { 'Content-Type': 'multipart/form-data' };
		return API.post(`meals/${id}/uploadImages`, images, { headers });
	}

	static async uploadImageRequest(requestId, images) {
		const headers = { 'Content-Type': 'multipart/form-data' };
		return API.post(`meals/requests/${requestId}/uploadImages`, images, { headers });
	}

	static async newUploadImagesRequest(mealId, images) {
		const headers = { 'Content-Type': 'multipart/form-data' };
		return API.post(`meals/${mealId}/requests/uploadImages`, images, { headers });
	}

	static async getTop() {
		return API.get('meals/top');
	}

	static async getMealById(id, populate) {
		return API.get(`meals/${id}`, { params: { populate } });
	}

	static async getSimilarMeals(id, params) {
		return API.get(`meals/${id}/similarMeals`, { params });
	}

	static async getRequests() {
		return API.get('meals/requests');
	}

	static async getPartTypes() {
		return API.get('meals/partTypes');
	}

	static async getRequestsOfMeal(mealId) {
		return API.get(`meals/${mealId}/requests`);
	}

	static async closeRequests(requestIds, decision) {
		return API.post('meals/requests/close', { requestIds, decision });
	}

	static async closeRequest(requestId, decision) {
		return API.post(`meals/requests/${requestId}/close`, { decision });
	}
}
