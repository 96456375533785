import API from './request-mgr.service';
import BaseService from './base.service';

export default class PlacesService extends BaseService {

	static async findById(id) {
		return API.get(`/places/${id}`);
	}

	static async getTop() {
		return API.get('/places/top');
	}

	static async getPlaceMeals(id, params) {
		return API.get(`/places/${id}/meals`, { params });
	}

	static async create(place, params) {
		return API.post('/places', place, { params });
	}

	static async upsert(place, params) {
		return API.post('/places', place, { params });
	}

	static async update(id, place) {
		return API.patch(`/places/${id}`, place);
	}

	static async createRequest(place) {
		return API.post('/places/requests', place);
	}

	static async createUpdateRequest(id, place) {
		return API.post(`/places/${id}/requests`, place);
	}

	static async getRequests() {
		return API.get('/places/requests');
	}

	static async getRequestsOfPlace(placeId) {
		return API.get(`/places/${placeId}/requests`);
	}

	static async getAddons(placeId) {
		return API.get(`/places/${placeId}/meals/parts/addons`);
	}

	static async getBuns(placeId) {
		return API.get(`/places/${placeId}/meals/parts/buns`);
	}

	static async closeRequests(requestIds, decision) {
		return API.post('/places/requests/close', { requestIds, decision });
	}

	static async closeRequest(requestId, decision) {
		return API.post(`/places/requests/${requestId}/close`, { decision });
	}
}
