import * as React from 'react';
import {
	Checkbox,
	FormControl,
	Input,
	InputLabel,
	ListItemText,
	makeStyles,
	MenuItem,
	Select,
	withStyles,
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

const MenuProps = {
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
	getContentAnchorEl: null,
	disableScrollLock: true,
};

const useStyles = makeStyles({
	menuItem: {
		textAlign: 'right',
		'&:hover': { backgroundColor: 'var(--secondary-color)' },
	},
	itemText: {
		marginLeft: 15,
	},
});

const StyledSelect = withStyles({
	root: { fontSize: 13, maxWidth: 400 },
	select: {
		color: ({ selected, controller, theme }: any) =>
			theme === 'dark'
				? 'black'
				: controller.length > 0 || selected.length > 0
				? 'var(--main-color)'
				: 'white',
		textAlign: 'right',
		paddingLeft: 24,
		paddingRight: '5px!important',
		fontWeight: 600,
		'&:hover': { color: ({ theme }: any): any => !theme && 'var(--main-color)' },
		'&:hover ~ svg': { color: ({ theme }: any): any => !theme && 'var(--main-color)' },
	},
	icon: {
		position: 'absolute',
		right: 'auto',
		top: 'auto',
		left: 4,
		color: ({ theme }) => (theme === 'dark' ? 'black' : 'white'),
	},
})(Select);

interface MultiSelectFilterProps {
	options: Array<any>;
	handleSelectionChange(value: any): void;
	currentValues: any;
	noValue?: boolean;
	name?: string;
	emptyValue?: string;
	theme?: 'dark' | 'normal';
	controller?: any;
}

const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
	name,
	options,
	emptyValue,
	handleSelectionChange,
	noValue,
	currentValues,
	theme = 'normal',
	controller,
}) => {
	const [selected, setSelected] = React.useState(currentValues || []);
	const classes = useStyles();

	const handleChange = (value) => {
		!controller && setSelected(value);
		handleSelectionChange(value.length > 0 ? value : undefined);
	};

	const findLabels = (valuesArray) => {
		const labelsArray: string[] = [];
		valuesArray.forEach((value) => {
			const foundOption = options.find((obj) => obj.value === value);
			foundOption && labelsArray.push(foundOption.label);
		});
		return labelsArray.length > 0 ? labelsArray.join(', ') : emptyValue;
	};

	return (
		<FormControl>
			{name && <InputLabel>{name}</InputLabel>}
			<StyledSelect
				selected={selected}
				theme={theme}
				multiple
				autoWidth
				displayEmpty
				disableUnderline
				MenuProps={MenuProps}
				input={<Input />}
				controller={controller || []}
				IconComponent={ExpandMoreRoundedIcon}
				value={controller || selected}
				renderValue={(selected) => (noValue ? '' : findLabels(selected))}
				onChange={(e) => handleChange(e.target.value)}
			>
				{/* {selected.length > 0 && <MenuItem>הכל</MenuItem>} */}
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value} className={classes.menuItem}>
						<ListItemText className={classes.itemText} primary={option.label} />
						<Checkbox
							style={{ padding: 2 }}
							color="primary"
							checked={
								controller
									? controller.includes(option.value)
									: selected.includes(option.value)
							}
						/>
					</MenuItem>
				))}
			</StyledSelect>
		</FormControl>
	);
};

export default React.memo(MultiSelectFilter);
