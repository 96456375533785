export default [
	{
		'featureType': 'administrative',
		'elementType': 'labels',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'administrative.locality',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'on'
			}
		]
	},
	{
		'featureType': 'administrative.locality',
		'elementType': 'labels',
		'stylers': [
			{
				'visibility': 'on'
			}
		]
	},
	{
		'featureType': 'administrative.locality',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'administrative.neighborhood',
		'elementType': 'labels',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'administrative.neighborhood',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'landscape.natural',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'simplified'
			}
		]
	},
	{
		'featureType': 'poi',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.attraction',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.business',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.government',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.park',
		'elementType': 'labels.text',
		'stylers': [
			{
				'visibility': 'on'
			}
		]
	},
	{
		'featureType': 'poi.park',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.place_of_worship',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.school',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi.sports_complex',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'road.highway',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'transit.station.airport',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	}
];