import { Input } from '@material-ui/core';
import * as React from 'react';
import s from './FormInputStyle.module.scss';

export interface FormInputTextProps {
	label: string;
	placeholderText?: string;
	helperText?: string;
	errorMsg?: string;
	inputDir?: 'ltr' | 'rtl';
	matInput?: boolean;
	[rest: string]: any;
}

const FormInputText = React.forwardRef<HTMLInputElement, FormInputTextProps>(
	({ label, placeholderText, helperText, errorMsg, matInput, inputDir, ...rest }, ref) => {
		return (
			<div className={s.formInputWrapper}>
				<p className={s.textLabel}>
					{label}
					{helperText && <span className={s.helperText}> ({helperText})</span>}
				</p>
				{matInput ? (
					<Input
						placeholder={placeholderText}
						className={s.formInput}
						inputRef={ref}
						style={{ direction: inputDir || 'inherit' }}
						autoComplete="off"
						{...rest}
					/>
				) : (
					<input
						placeholder={placeholderText}
						className={s.formInput}
						ref={ref}
						style={{ direction: inputDir || 'inherit', maxWidth: 'calc(100% - 18px)' }}
						{...rest}
					/>
				)}
				{errorMsg && <span>{errorMsg}</span>}
			</div>
		);
	}
);

export default FormInputText;
FormInputText.displayName = 'FormInputText';
