import * as React from 'react';
import Meal from 'types/meal';
import Place from 'types/place';
import { useImage } from 'react-image';
import { PulseLoader } from 'react-spinners';
import { getImageUrl } from 'utils/functions.util';
import ImagePlaceholder from 'components/general/ImagePlaceholder';

interface ImageLoaderProps {
	item: Place | Meal;
	imgStyle?: React.CSSProperties;
	index?: number;
	size?: string;
	alt?: string;
}
const imageStyle: React.CSSProperties = {
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
};

const Loader: React.FC<ImageLoaderProps> = ({ item, size, index }) => {
	const imageUrl = getImageUrl(item, index);
	if (imageUrl) {
		const { src } = useImage({
			srcList: [`${imageUrl}${size ? `-${size}` : ''}`, imageUrl],
		});
		return src ? (
			<div
				style={{
					...imageStyle,
					backgroundImage: `url(${src})`,
				}}
			/>
		) : null;
	} else
		return (
			<div style={imageStyle}>
				<ImagePlaceholder />
			</div>
		);
};

const MemoLoader = React.memo(Loader);

const ImageLoader: React.FC<ImageLoaderProps> = (props) => {
	return (
		<React.Suspense fallback={<PulseLoader color={'var(--main-color)'} size={5} margin={3} />}>
			<MemoLoader {...props} />
		</React.Suspense>
	);
};

export default React.memo(ImageLoader);
