import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core';
import { QuestTheme } from 'theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

ReactDOM.render(
	<ThemeProvider theme={QuestTheme}>
		<Router>
			<SkeletonTheme color="#d1d1d1" highlightColor="#dedede">
				<App />
			</SkeletonTheme>
		</Router>
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
