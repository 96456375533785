import * as React from 'react';
import s from './ReviewSectionStyle.module.scss';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ReviewList from 'components/pageBody/ReviewSection/ReviewList/ReviewList';
import { Box } from '@material-ui/core';

export interface ReviewSectionProps {
	mealId: string;
	onRateClick(): any;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ mealId, onRateClick }) => {
	const [reviewCount, setReviewCount] = React.useState<number>(0);

	return (
		<div className={`scrollBar ${s.root}`}>
			<div className={s.sectionTitle}>
				<ChatBubbleIcon fontSize={'small'} />
				<h3>{`חוות דעת (${reviewCount})`}</h3>
			</div>
			<div className={s.rateMealInput} onClick={onRateClick}>
				כתבו חוות דעת
			</div>
			<Box mt={1} mb={1}>
				<ReviewList
					mealId={mealId}
					setReviewCount={(count: number) => setReviewCount(count)}
				/>
			</Box>
		</div>
	);
};

export default React.memo(ReviewSection);
