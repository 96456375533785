import * as React from 'react';
import StarRatingItem from 'components/dialog/dialogContent/meal/RateMeal/StarRatingItem';
import { Box, TextField, useMediaQuery } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import MainButton from 'components/general/MainButton/MainButton';
import { MealContext, PlaceContext } from 'context/ContextProvider';
import { UserContext, UserContextType } from 'context/UserProvider';
import NumberScaleComponent from 'components/dialog/dialogContent/meal/RateMeal/NumberScaleComponent';
import StageStepper from 'components/general/StageStepper';
import { addonsToObjectArray, getMealParts, roundNumber } from 'utils/functions.util';
import MealsService from 'api/services/meals.service';
import 'components/dialog/dialogContent/DialogFormStyle.scss';
import PlacesService from 'api/services/places.service';
import { MOBILE_SIZE } from 'utils/values.util';
import { AnimatePresence, motion } from 'framer-motion';
import CheckboxComponent from 'components/general/CheckBox/CheckBoxComponent';
import { MealContextType } from 'types/meal';
import { ToastContext, ToastContextType } from 'context/ToastProvider';

interface RateMealFormProps {
	exitDialog(): void;
}

const RateMealForm: React.FC<RateMealFormProps> = ({ exitDialog }) => {
	const [isLoading, setLoadingState] = React.useState<boolean>(false);
	const [formStep, setStep] = React.useState<number>(0);
	const [recommendChance, setRecommendChance] = React.useState<number | null>(null);
	const { activeUser } = React.useContext<UserContextType>(UserContext);
	const { activeMeal } = React.useContext<MealContextType>(MealContext);
	const { activePlace } = React.useContext<any>(PlaceContext);
	const [orderType, setOrderType] = React.useState<'delivery' | 'take-away' | 'in-place'>(
		'delivery'
	);
	const { toast, setToast } = React.useContext<ToastContextType>(ToastContext);

	const [ratingRequest, setRatingRequest] = React.useState<any>(null);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
	const isMobile = useMediaQuery(MOBILE_SIZE);
	const [addonOptions, setAddonOption] = React.useState<any>([]);
	const [bunOptions, setBunOptions] = React.useState<any>([]);

	const { register, handleSubmit, watch, formState, control } = useForm({
		mode: 'onChange',
	});

	const meal = activeMeal !== 'not_found' ? activeMeal : null;

	const onSubmit = (formData) => {
		setLoadingState(true);
		let currentRequest = {
			user: activeUser?._id,
			meal: meal?._id,
			parts: formStep === 0 ? getMealPartsScore(formData) : ratingRequest.parts,
			orderType: orderType,
		};
		if (formStep === 1) {
			currentRequest = Object.assign(ratingRequest, {
				rtaf: recommendChance ? recommendChance : undefined,
				comment: formData.extraComments ? formData.extraComments : undefined,
			});
		}
		MealsService.submitReview(meal?._id, currentRequest)
			.then((response) => {
				if (response) {
					setRatingRequest(currentRequest);
					setErrorMessage(null);
					if (formStep < 1) {
						setStep(formStep + 1);
					} else {
						setToast({ ...toast, message: 'המשוב נשלח בהצלחה! רב תודות' });
						exitDialog();
					}
				} else {
					setErrorMessage('משהו השתבש, מצטערים! אנא נסו שוב...');
				}
			})
			.catch((error) => {
				setErrorMessage('משהו השתבש, מצטערים! נבדוק את העניין...');
				console.log('There was a problem rating the meal:\n', error);
			})
			.finally(() => setLoadingState(false));
	};

	function getMealPartsScore(scoreData) {
		let mealParts: Array<{ mealPart: string; score: number }> = [];
		Object.keys(scoreData).map((mealPart) => {
			if (mealPart !== 'extraComments' && mealPart !== 'addon') {
				mealParts = [
					...mealParts,
					{ mealPart: mealPart, score: Number(scoreData[mealPart]) },
				];
			}
			return mealPart;
		});
		return mealParts;
	}

	const sendRecommended = async (value: number) => {
		setRecommendChance(value);
		const request = { ...ratingRequest, rtaf: value };
		await MealsService.submitReview(meal?._id, request).catch((e) => console.log(e));
	};

	const SubmitButton = () => (
		<Box alignItems="center" display="flex" flexDirection="column">
			<span style={{ color: 'red' }}>{errorMessage}</span>
			<MainButton
				handleClick={() => handleSubmit(onSubmit)()}
				loading={isLoading}
				width={isMobile ? '98%' : '15vw'}
				disabled={!formState.isValid || (!formState.isDirty && !recommendChance)}
			>
				{formStep === 0 ? <>שליחת דירוג</> : <>הוספת חוות דעת</>}
			</MainButton>
		</Box>
	);

	React.useEffect(() => {
		async function getPlaceData(placeId: string) {
			setLoadingState(true);
			await PlacesService.getAddons(placeId)
				.then((response) => setAddonOption(addonsToObjectArray(response)))
				.catch((e) => console.log(e));
			await PlacesService.getBuns(placeId)
				.then((response) => setBunOptions(addonsToObjectArray(response)))
				.catch((e) => console.log(e));
		}
		if (meal) {
			const placeId = meal?.place._id || activePlace._id;
			getPlaceData(placeId);
			setLoadingState(false);
		}
	}, []);

	const currentRating =
		roundNumber(
			Object.values(watch()).reduce((total, value) => total + Number(value), 0) /
				Object.keys(watch()).length
		) || '0';

	return (
		<StageStepper step={formStep}>
			<form id="rateMealStep1" className="formWrapper" style={{ height: 'unset' }}>
				<Box width="100%" textAlign="center">
					{meal && <h2>דרג את {meal?.title}</h2>}
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-around"
					height="100%"
				>
					{meal &&
						meal?.parts
							.filter((part) => part.type !== 'addon')
							.map((item) => (
								<Box key={item._id} mt={2} mb={1}>
									<StarRatingItem
										name={item._id}
										isLoading={isLoading}
										item={item}
										control={control}
										rules={{ required: true }}
										options={
											{
												addons: getMealParts(meal, 'addon'),
												bun: item.name,
											}[item.type]
										}
										addonOptions={addonOptions}
									/>
								</Box>
							))}
					<Box display="flex" justifyContent="space-around" width="50%" m="auto" mb={1}>
						<CheckboxComponent
							label="נאכל בהזמנה"
							textOverflow="free"
							controller={orderType === 'delivery'}
							onChange={(checked) => setOrderType(checked ? 'delivery' : 'in-place')}
						/>
						<CheckboxComponent
							label="נאכל במקום"
							textOverflow="free"
							controller={orderType === 'in-place'}
							onChange={(checked) => setOrderType(checked ? 'in-place' : 'delivery')}
						/>
					</Box>
					<Box textAlign="center" minHeight={25}>
						<AnimatePresence>
							{formState.isValid && formState.isDirty && (
								<motion.h4
									initial={{ opacity: 0, margin: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.5 }}
								>
									{`ציון ממוצע: ${currentRating}`}
								</motion.h4>
							)}
						</AnimatePresence>
					</Box>
				</Box>
				<SubmitButton />
			</form>
			<form id="rateMealStep2" className="formWrapper">
				<Box
					justifyContent="center"
					alignItems="center"
					display="flex"
					flexDirection="column"
				>
					<h1>הדירוג נשלח בהצלחה!</h1>
					<h3>האם תמליץ על {meal?.title} לאחרים?</h3>
					<NumberScaleComponent
						size={10}
						onChange={(value: number) => sendRecommended(value)}
					/>
					<h3>הוספת חוות דעת על {meal?.title} (אופציונלי)</h3>
					<TextField
						variant="outlined"
						name="extraComments"
						fullWidth
						multiline={true}
						rows={3}
						inputRef={register}
					/>
					<br />
					<SubmitButton />
				</Box>
			</form>
		</StageStepper>
	);
};

export default React.memo(RateMealForm);
