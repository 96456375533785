import * as React from 'react';
import s from './ItemTagStyle.module.scss';

export interface ItemTagProps {
	label: string;
	style?: string;
}

const ItemTag: React.FC<ItemTagProps> = ({ label, style }) => {
	return <div className={`${s.root} ${s[style || '']}`}>{label}</div>;
};

export default React.memo(ItemTag);
