import * as React from 'react';
import s from './MainFeedStyle.module.scss';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List } from 'react-window';
import MealFeedItem from 'components/sidebar/new/MealFeedItem/MealFeedItem';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import { useHistory } from 'react-router-dom';
import Meal from 'types/meal';
import { MOBILE_SIZE } from 'utils/values.util';
import { useMediaQuery } from '@material-ui/core';
export interface InfiniteScrollerProps {
	list: Meal[];
	loadMoreItems(num1: number, num2: number): any;
	listHeight: number;
	hasMoreItems: boolean;
	threshold?: number;
	ref?: any;
}

const InfiniteScroller = React.forwardRef<InfiniteLoader, InfiniteScrollerProps>(
	({ list, loadMoreItems, listHeight, hasMoreItems, threshold = 3 }, ref) => {
		const history = useHistory();
		let scrollPosition: number = Number(window.sessionStorage.getItem('qb_s_pos')) || 0;

		const isMobile = useMediaQuery(MOBILE_SIZE);

		const isItemLoaded = (index: number) => {
			return !hasMoreItems || index < list.length;
		};

		const listLength = hasMoreItems ? list.length + 1 : list.length;
		return (
			<InfiniteLoader
				isItemLoaded={isItemLoaded}
				itemCount={listLength}
				threshold={threshold}
				loadMoreItems={loadMoreItems}
				ref={ref}
			>
				{({ onItemsRendered, ref }) => (
					<List
						height={listHeight}
						className="scrollBar"
						width={isMobile ? window.innerWidth : 590}
						direction="rtl"
						itemSize={isMobile ? 350 : 170}
						overscanCount={4}
						itemCount={listLength}
						onScroll={({ scrollOffset }) => (scrollPosition = scrollOffset)}
						initialScrollOffset={scrollPosition}
						ref={ref}
						onItemsRendered={onItemsRendered}
					>
						{({ index, style }) => (
							<div style={style} className={s.infiniteScroll}>
								{isItemLoaded(index) ? (
									<MealFeedItem
										style={style}
										meal={list[index]}
										handleClick={(item) => {
											history.push(`/meal/${item._id}`);
											window.sessionStorage.setItem(
												'qb_s_pos',
												scrollPosition.toString()
											);
										}}
									/>
								) : (
									<LoadingOverlay size={15} />
								)}
							</div>
						)}
					</List>
				)}
			</InfiniteLoader>
		);
	}
);
InfiniteScroller.displayName = 'InfiniteScroller';

export default InfiniteScroller;
