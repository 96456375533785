import * as React from 'react';
import s from './FiltersColumnStyle.module.scss';
import { Box, Button, ButtonBase } from '@material-ui/core';
import MealsService from 'api/services/meals.service';
import FilterOptionsList from 'components/sidebar/new/FiltersColumn/FilterOptionsList/FilterOptionsList';
import SearchBox from 'components/topbar/SearchBox/SearchBox';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import { MealPart } from 'types/meal';
import { AnimatePresence, motion } from 'framer-motion';
import CheckboxComponent from 'components/general/CheckBox/CheckBoxComponent';
import { GEO_AREA_LIST, KOSHER_OPTIONS_LIST, ORDER_METHODS_LIST } from 'utils/values.util';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import { isMobile } from 'react-device-detect';
import { CloseRounded } from '@material-ui/icons';
import { omit } from 'lodash';
import { QuestSlider } from 'components/sidebar/new/FiltersColumn/QuestSlider';
import Select from 'react-select';
import { selectStyles } from 'components/forms/FormInput/FormInputSelect';

const MEAL_DIET_TYPES = [
	{ label: 'בשרית', value: 'meat' },
	{ label: 'צמחונית', value: 'vegetarian' },
	{ label: 'טבעונית', value: 'vegan' },
];

interface FIltersColumnProps {
	closeDrawer?(): void;
}

const FiltersColumn: React.FC<FIltersColumnProps> = ({ closeDrawer }) => {
	const {
		checkFilters,
		setFilters,
		cleanFilters,
		searchFilters,
		searchQuery,
		setPartFilters,
		partFilters,
	} = React.useContext<SearchContextType>(SearchContext);
	const { setOpenDialog, setDialogProps } = React.useContext<DialogContextType>(DialogContext);
	const [sliderValue, setSliderValue] = React.useState<number>(searchFilters?.costRange || 0);

	const [filtersArray, setFiltersArray] = React.useState<unknown[]>([]);
	const [fetchingData, setFetchingData] = React.useState<boolean>(false);

	const isFiltered: boolean = checkFilters() || searchQuery || searchQuery !== '' ? true : false;
	const filtersRef = React.useRef<any[]>([]);

	const filtersUpdate = (
		type: string,
		value: string | string[] | boolean | undefined | number,
		name: string | null = null
	) => {
		if (type === 'props') {
			let vegProps = searchFilters && searchFilters?.props ? searchFilters.props : [];
			if (value) {
				vegProps.push(name);
			} else {
				vegProps = vegProps.filter((item) => item !== name);
			}
			setFilters({
				...searchFilters,
				props: vegProps.length > 0 ? vegProps : undefined,
			});
		} else {
			if (searchFilters && searchFilters[type] && !value) {
				setFilters({ ...searchFilters, [type]: undefined });
			} else {
				setFilters({ ...searchFilters, [type]: value });
			}
		}
	};

	const updatePartFilter = (filter: MealPart, action: 'add' | 'remove') => {
		const filterKeyValues = new Set(
			partFilters && partFilters[filter.parentType] ? partFilters[filter.parentType] : []
		);
		action === 'add'
			? filterKeyValues.add(filter.sharedId)
			: filterKeyValues.delete(filter.sharedId);
		if (filterKeyValues.size === 0) {
			setPartFilters((current) => omit(current, [filter.parentType]));
		} else {
			setPartFilters((current: Record<string, MealPart[]>) => ({
				...current,
				[filter.parentType]: filterKeyValues,
			}));
		}
	};

	const updateKosherFilters = (kosherLevel: string, checked: boolean) => {
		if (!checked && searchFilters?.kosher.includes(kosherLevel)) {
			const tempArray = searchFilters.kosher.filter((level: string) => level !== kosherLevel);
			setFilters({ ...searchFilters, kosher: tempArray.length > 0 ? tempArray : undefined });
		} else {
			setFilters({
				...searchFilters,
				kosher: searchFilters?.kosher
					? [...searchFilters?.kosher, kosherLevel]
					: [kosherLevel],
			});
		}
	};

	const updateOrderFilter = (filter: string, state: boolean) => {
		const activeOrderFilters: string[] | null = searchFilters?.orderMethods;
		if (state) {
			if (activeOrderFilters) {
				setFilters({
					...searchFilters,
					orderMethods: [...activeOrderFilters, filter],
				});
			} else {
				setFilters({ ...searchFilters, orderMethods: [filter] });
			}
		} else {
			if (activeOrderFilters && activeOrderFilters.length > 1) {
				const tempArray: string[] = activeOrderFilters.filter((item) => item !== filter);
				setFilters({ ...searchFilters, orderMethods: tempArray });
			} else {
				setFilters({ ...searchFilters, orderMethods: undefined });
			}
		}
	};

	const clearAllFilters = () => {
		filtersArray.forEach((_: any, index: number) => {
			filtersRef.current[index].clearSelected();
		});
		setSliderValue(0);
		cleanFilters();
	};

	React.useEffect(() => {
		const getPartsFilters = async () => {
			setFetchingData(true);
			await MealsService.getPartTypes()
				.then((res: any) => {
					setFiltersArray(res);
					window.sessionStorage.setItem('qb_ft_op', JSON.stringify(res));
				})
				.catch((e) => console.log(e))
				.finally(() => setFetchingData(false));
		};
		const sessionData = window.sessionStorage.getItem('qb_ft_op');
		if (sessionData) {
			setFiltersArray(JSON.parse(sessionData));
		} else {
			getPartsFilters();
		}
	}, []);

	return (
		<div className={s.root}>
			{fetchingData && <LoadingOverlay />}
			{isMobile && (
				<ButtonBase className={s.closeDrawerBtn} onClick={closeDrawer}>
					<CloseRounded fontSize="large" />
				</ButtonBase>
			)}
			<h3 className={s.sectionHeader}>חיפוש המבורגר</h3>
			<div className={`scrollBar ${s.filterSection}`}>
				<Select
					name={'select'}
					placeholder="חיפוש באזור..."
					className={s.geoSelect}
					options={GEO_AREA_LIST}
					isSearchable={false}
					styles={selectStyles}
					isRtl={true}
					value={
						GEO_AREA_LIST.find((f) => f.value === searchFilters?.zone) ||
						GEO_AREA_LIST[0]
					}
					onChange={(e) =>
						setFilters((current) => ({
							...current,
							geo: undefined,
							zone: e?.value === 'all' ? undefined : e?.value,
						}))
					}
				/>
				<h3 className={s.sectionTitle}>לפי שם</h3>
				<SearchBox placeholder="חיפוש מסעדה או מנה" />
				<h3 className={s.sectionTitle}>מנה</h3>
				<Box mr={1}>
					{MEAL_DIET_TYPES.map((type, index) => (
						<CheckboxComponent
							key={index}
							label={type.label}
							controller={
								(searchFilters?.props &&
									searchFilters?.props?.includes(type.value)) ||
								false
							}
							onChange={(checked) => filtersUpdate('props', checked, type.value)}
						/>
					))}
				</Box>
				<h3 className={s.sectionTitle}>משלוחים</h3>
				<Box mr={1}>
					{ORDER_METHODS_LIST.map((method, index) => (
						<CheckboxComponent
							key={index}
							label={method.label}
							onChange={(checked: boolean) => {
								updateOrderFilter(method.value, checked);
							}}
							controller={
								(searchFilters?.orderMethods &&
									searchFilters?.orderMethods?.includes(method.value)) ||
								false
							}
						/>
					))}
				</Box>
				<h3 className={s.sectionTitle}>כשרות</h3>
				<Box mr={1}>
					{KOSHER_OPTIONS_LIST.map((level, index) => (
						<CheckboxComponent
							key={index}
							label={level.label}
							onChange={(checked: boolean) => {
								updateKosherFilters(level.value, checked);
							}}
							controller={
								(searchFilters?.kosher &&
									searchFilters?.kosher?.includes(level.value)) ||
								false
							}
						/>
					))}
				</Box>
				<Box>
					{filtersArray.map((item: any, index: number) => (
						<FilterOptionsList
							key={item._id}
							title={item.name}
							typeId={item.sharedId}
							ref={(el) => (filtersRef.current[index] = el)}
							options={item.childTypes}
							onAdded={(filter) => updatePartFilter(filter, 'add')}
							onRemoved={(filter) => updatePartFilter(filter, 'remove')}
							onMoreItemsClick={(items) => {
								setDialogProps({ title: item.name, options: items });
								setOpenDialog('moreFilters');
							}}
							initialState={
								partFilters ? partFilters[item.sharedId] || undefined : undefined
							}
						/>
					))}
				</Box>
				<Box mb={isFiltered ? 8 : 0}>
					<h3 className={s.sectionTitle}>מחיר מקסימלי</h3>
					<span className={s.sliderValue}>
						{sliderValue ? (sliderValue === 100 ? '100+' : sliderValue) : 'ללא'}
					</span>
					<Box width={'calc(100% - 50px)'} mr={2}>
						<QuestSlider
							step={5}
							max={100}
							value={sliderValue}
							onChange={(_, value: any) => setSliderValue(value as number)}
							onChangeCommitted={() =>
								sliderValue > 0
									? filtersUpdate('costRange', sliderValue)
									: filtersUpdate('costRange', undefined)
							}
						/>
					</Box>
				</Box>
			</div>
			<AnimatePresence>
				{isFiltered && (
					<motion.div
						initial={{ opacity: 0, y: 20, x: '-50%' }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 20 }}
						transition={{
							y: { type: 'spring', stiffness: 300, damping: 10 },
							opacity: { duration: 0.2 },
						}}
						className={s.clearBtnWrapper}
					>
						<Button
							color="inherit"
							className={s.clearBtn}
							onClick={() => clearAllFilters()}
						>
							איפוס פילטרים
						</Button>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default React.memo(FiltersColumn);
