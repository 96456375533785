import * as React from 'react';
import s from './MealCrownStyle.module.scss';
import { roundNumber } from 'utils/functions.util';
import ScoreStars from 'components/sidebar/new/ScoreStars';
import Meal from 'types/meal';

export interface MealCrownProps {
	meal: Meal;
}

const MealCrown: React.FC<MealCrownProps> = ({ meal }) => (
	<div className={`crownSection ${s.root}`}>
		<div className={s.circle} />
		<span className={s.rating}>
			{meal.activeScore ? roundNumber(meal.activeScore) : 'טרם דורג'}
		</span>
		<ScoreStars
			count={meal.activeScore}
			rank={meal.rank}
			starSize={{ small: 14, large: 14 }}
			width={80}
		/>
		<span className={s.reviewCount}>{`(${meal.reviewsCount} דירוגים)`}</span>
	</div>
);

export default MealCrown;
