import * as React from 'react';
import s from './ImageCarouselStyle.module.scss';
import ImageLoader from 'components/general/ImageLoader';
import { wrap } from '@popmotion/popcorn';
import Meal from 'types/meal';
import { motion } from 'framer-motion';
import { ImageType } from 'types/common';
import { openInNewTab } from 'utils/functions.util';
import { ReactComponent as SwipeArrow } from 'assets/arrow-move-right.svg';
import Place from 'types/place';

const variants = {
	enter: (direction: number) => {
		return {
			x: direction > 0 ? 1000 : -1000,
			opacity: 0.8,
		};
	},
	center: {
		x: 0,
		opacity: 1,
	},
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
	return Math.abs(offset) * velocity;
};

export interface ImageCarouselProps {
	item: Meal | Place;
	imageSize?: 'small' | 'medium' | 'large';
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ item, imageSize }) => {
	const images: Array<string | ImageType> = item.images || item.imageUrls || item.imagesUrls;
	const [[page, direction], setPage] = React.useState([0, 0]);
	const imageIndex = wrap(0, images.length, page);

	const paginate = (newDirection: number) => {
		setPage([page + newDirection, newDirection]);
	};

	return (
		<div className={s.root}>
			<motion.div
				key={page}
				className={s.image}
				custom={direction}
				variants={variants}
				initial="enter"
				animate="center"
				transition={{
					x: { type: 'spring', stiffness: 300, damping: 30 },
					opacity: { duration: 0.2 },
				}}
				drag="x"
				dragConstraints={{ left: 0, right: 0 }}
				dragElastic={1}
				onDragEnd={(e, { offset, velocity }) => {
					if (images.length > 1) {
						const swipe = swipePower(offset.x, velocity.x);
						if (swipe < -swipeConfidenceThreshold) {
							paginate(1);
						} else if (swipe > swipeConfidenceThreshold) {
							paginate(-1);
						}
					}
				}}
			>
				<ImageLoader item={item} index={imageIndex} size={imageSize} />{' '}
				{(images[imageIndex] as ImageType)?.source && (
					<div className={s.imgSource}>
						<span> התמונה באדיבות</span>
						<div className={s.imgSourceLink}>
							<a
								className="mainTextLink"
								onClick={() =>
									openInNewTab((images[imageIndex] as ImageType).source.url || '')
								}
							>
								{(images[imageIndex] as ImageType).source.name}
							</a>
						</div>
					</div>
				)}
			</motion.div>

			{images.length > 1 && (
				<>
					<div className={s.imgCount}>{`${imageIndex + 1}/${images.length}`}</div>
					<motion.div
						initial={{ y: '-50%' }}
						whileHover={{ scale: 1.2 }}
						whileTap={{ scale: 0.9 }}
						className={s.rightArrow}
						onClick={() => paginate(1)}
					>
						<SwipeArrow style={{ marginLeft: 3 }} height="14px" />
					</motion.div>
					<motion.div
						initial={{ y: '-50%' }}
						whileHover={{ scale: 1.2 }}
						whileTap={{ scale: 0.9 }}
						className={s.leftArrow}
						onClick={() => paginate(-1)}
					>
						<SwipeArrow
							style={{ transform: 'rotate(180deg)', marginRight: 5 }}
							height="14px"
						/>
					</motion.div>
				</>
			)}
		</div>
	);
};

export default React.memo(ImageCarousel);
