import * as React from 'react';
import LoginComponent from 'components/dialog/dialogContent/user/Login/LoginComponent';
import { UserContext } from 'context/UserProvider';

export interface UserGateProps {
	component: React.FC<any>;
	loginMsg: string;
	[x: string]: any;
}

const UserGate: React.FC<UserGateProps> = ({ component: Component, loginMsg, ...rest }) => {
	const { activeUser } = React.useContext<any>(UserContext);
	return activeUser ? (
		<Component {...rest} />
	) : (
		<LoginComponent closeOnLogin={false} errorMsg={loginMsg} />
	);
};

export default UserGate;
