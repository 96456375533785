import * as React from 'react';
import s from './UpdateMealFormStyle.module.scss';
import { PlaceContext, MealContext } from 'context/ContextProvider';
import { UserContext } from 'context/UserProvider';
import { Controller, useForm } from 'react-hook-form';
import FormInputText from 'components/forms/FormInput/FormInputText';
import FormButtonFooter from 'components/forms/FormButtonFooter';
import FormInputSelect from 'components/forms/FormInput/FormInputSelect';
import { FormControlLabel, Box, RadioGroup, Radio, Button } from '@material-ui/core';
import MainButton from 'components/general/MainButton/MainButton';
import StageStepper from 'components/general/StageStepper';
import { getMealParts, isUserRole } from 'utils/functions.util';
import MealsService from 'api/services/meals.service';
import { BUN_OPTIONS } from 'utils/values.util';
import 'components/dialog/dialogContent/DialogFormStyle.scss';
import AutoCompleteInput from 'components/topbar/filters/AutoCompleteInput';
import PlacesService from 'api/services/places.service';
import { DialogContext } from 'context/DialogManager';
import Place from 'types/place';
import Meal, { MealContextType, MealPart } from 'types/meal';
import { OptionTypeBase, ValueType } from 'react-select';
import SearchService from 'api/services/search.service';
import { DietPropsType } from 'types/common';
import { isMobile } from 'react-device-detect';
import ImageDropZone from 'components/forms/ImageDropZone/ImageDropZone';

type MealEditType = {
	_id: string;
	title: string;
	bun: MealPart;
	patty: MealPart;
	addonList: MealPart[];
	addons: MealPart;
	valueForMoney: MealPart;
	price: number;
	diet: DietPropsType;
	place: Place;
};

const getDiet = (meal: Meal): DietPropsType => {
	if (meal.vegan !== 'nope') return 'vegan';
	else if (meal.vegetarian !== 'nope') return 'vegetarian';
	else return 'meat';
};

const UpdateMealForm: React.FC = () => {
	const { activeMeal, setActiveMeal, isEditingMeal } = React.useContext<MealContextType>(
		MealContext
	);

	const mealToEdit: MealEditType | null =
		isEditingMeal && activeMeal && activeMeal !== 'not_found'
			? {
					_id: activeMeal._id,
					title: activeMeal.title,
					bun: getMealParts(activeMeal, 'bun')[0],
					patty: getMealParts(activeMeal, 'patty')[0],
					addonList: getMealParts(activeMeal, 'addon'),
					addons: getMealParts(activeMeal, 'addons')[0],
					valueForMoney: getMealParts(activeMeal, 'valueForMoney')[0],
					price: activeMeal.costRange.min,
					diet: getDiet(activeMeal),
					place: activeMeal.place,
			  }
			: null;

	const [files, setFiles] = React.useState<any>();
	const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

	const [isLoading, setLoadingState] = React.useState(false);
	const { setOpenDialog } = React.useContext<any>(DialogContext);
	const { activePlace } = React.useContext<any>(PlaceContext);
	const [placeInfo, setPlaceInfo] = React.useState<Place | null>(
		(activePlace !== 'not_found' && activePlace) || mealToEdit?.place || null
	);
	const { activeUser } = React.useContext<any>(UserContext);
	const [stage, setStage] = React.useState(0);
	const isAdmin = isUserRole(activeUser, 'admin');

	const [addOns, setAddOns] = React.useState<MealPart[]>(mealToEdit?.addonList || []);

	const { register, control, watch, setValue, errors, handleSubmit, formState } = useForm({
		mode: 'onChange',
		defaultValues: {
			mealTitle: mealToEdit?.title || null,
			mealBun: mealToEdit
				? BUN_OPTIONS.find((item) => item.value === mealToEdit.bun.name) || BUN_OPTIONS[2]
				: null,
			mealPatty: mealToEdit?.patty.name || null,
			mealPrice: mealToEdit?.price || null,
			pattyType: mealToEdit?.diet || null,
			pattyWeight: mealToEdit?.patty?.extra?.weight || null,
		},
	});

	const mealBun = watch('mealBun');
	const pattyType = watch('pattyType');

	const sendRequest = ({ request, type }) => {
		if (type === 'update') {
			return isAdmin
				? MealsService.update(mealToEdit?._id, request)
				: MealsService.createUpdateRequest(mealToEdit?._id, request);
		} else {
			return isAdmin ? MealsService.create(request) : MealsService.createRequest(request);
		}
	};

	const uploadImages = (mealId: string, images: FormData) => {
		return isAdmin
			? MealsService.uploadImages(mealId, images)
			: MealsService.uploadImageRequest(mealId, images);
	};

	const getPlaceAutocomplete = (inputValue: string) => {
		return new Promise((resolve) => {
			resolve(SearchService.searchPlaces(inputValue));
		});
	};

	const onSubmit = async (formData) => {
		setLoadingState(true);
		setErrorMsg(null);
		const mealRequest = {
			place: activePlace?._id || mealToEdit?.place._id || placeInfo?._id,
			title: formData.mealTitle !== mealToEdit?.title ? formData.mealTitle : undefined,
			parts: [
				{
					...(mealToEdit?.bun || {}),
					name:
						formData.mealBun.value === 'אחר'
							? formData.otherMealBun
							: formData.mealBun.value
							? formData.mealBun.value
							: formData.mealBun[0].value,
					type: 'bun',
				},
				{
					...(mealToEdit?.patty || {}),
					name: formData.mealPatty,
					extra: formData.pattyWeight
						? { weight: Number(formData.pattyWeight), scale: 'grams' }
						: undefined,
					type: 'patty',
				},
				mealToEdit?.addons || {
					name: 'תוספות',
					type: 'addons',
				},
				mealToEdit?.valueForMoney || {
					name: 'ערך מנה',
					type: 'valueForMoney',
				},
				...addOns,
			],
			vegan: formData.pattyType === 'vegan' ? 'definite' : 'nope',
			vegetarian: formData.pattyType === 'vegetarian' ? 'definite' : 'nope',
			costRange: {
				min: Number(formData.mealPrice),
				max: Number(formData.mealPrice),
			},
		};
		await sendRequest({
			request: mealRequest,
			type: mealToEdit ? 'update' : 'new',
		})
			.then((response: any) => {
				if (response) {
					if (files && files.length > 0) {
						const imageData = new FormData();
						files.forEach((file) => imageData.append('images', file, file.name));
						uploadImages(mealToEdit?._id || response._id, imageData)
							.then(() => {
								setStage(stage + 1);
							})
							.catch(() => {
								setErrorMsg(
									'תקלה בהעלאת תמונות לשרת. העדכון בוצע בהצלחה אך התמונה לא עלתה...'
								);
							})
							.finally(() => setLoadingState(false));
					} else {
						setLoadingState(false);
						setStage(stage + 1);
					}
				}
				setLoadingState(false);
				setStage(stage + 1);
			})
			.catch(() => {
				setErrorMsg('קרתה תקלה בשליחת העדכון, נסה שוב');
				setLoadingState(false);
			});
		// const res = await uploadImages().catch(e=>e)
		// if(res instanceof Error){
		// 	//handle error
		// 	return;
		// }
		// //success
	};

	const [addonOptions, setAddonOptions] = React.useState([]);

	React.useEffect(() => {
		async function getAutocompleteValues(placeId) {
			await PlacesService.getAddons(placeId)
				.then((res: any) => {
					res && setAddonOptions(res);
				})
				.catch((e) => console.error(e));
		}
		if (mealToEdit && mealBun?.value === 'אחר') {
			setValue('otherMealBun', mealToEdit?.bun.name);
		}
		const placeId = activePlace
			? activePlace._id
			: mealToEdit?.place._id || placeInfo?._id || null;
		placeId && getAutocompleteValues(placeId);
	}, [placeInfo]);

	const showForm: boolean =
		(activePlace && activePlace !== 'not_found') || mealToEdit?.place || placeInfo;

	return (
		<StageStepper step={stage}>
			<form id="updateMealStep1" className="formWrapper" autoComplete="off">
				<div
					className={`scrollBar ${s.formContent}`}
					style={{ overflowY: showForm ? 'auto' : 'hidden' }}
				>
					{(!activePlace || activePlace === 'not_found') && !mealToEdit?.place && (
						<>
							<h5>מסעדה</h5>
							<AutoCompleteInput
								type="async"
								cacheOptions
								isClearable={true}
								defaultOptions
								defaultValue={(placeInfo as Place) || null}
								placeholder="התחילו להקליד על מנת לבחור מסעדה"
								noOptionsMessage={() =>
									'אין לנו מסעדה בשם זה, אם המקום חסר - הוסיפו!'
								}
								loadOptions={getPlaceAutocomplete}
								onChange={(value: Place) => setPlaceInfo(value)}
								getOptionLabel={(place: Place) => place.title}
								getOptionValue={(place: Place) => place._id}
							/>
							{!placeInfo && (
								<>
									<span>לא מצאת? </span>
									<Button
										className="smallLink"
										color="primary"
										onClick={() => setOpenDialog('place')}
									>
										הוסיפו מסעדה חדשה
									</Button>
								</>
							)}
						</>
					)}
					<div style={{ visibility: showForm ? 'visible' : 'hidden', marginTop: 10 }}>
						<FormInputText
							matInput
							error={errors.mealTitle}
							label="שם המנה"
							name="mealTitle"
							ref={register({ required: true })}
						/>
						<h5>לחמניה</h5>
						<FormInputSelect
							control={control}
							rules={{ required: true }}
							name="mealBun"
							placeholder="בחירת סוג לחמניה"
							options={BUN_OPTIONS}
							isClearable={true}
							isSearchable={false}
						/>
						{mealBun && mealBun.value === 'אחר' && (
							<FormInputText
								matInput
								name="otherMealBun"
								placeholder="פירוט"
								ref={register}
							/>
						)}
						<Box
							mt={2}
							display="flex"
							flexDirection={isMobile ? 'column' : 'row'}
							justifyContent="space-between"
							alignItems="center"
						>
							<Controller
								name="pattyType"
								control={control}
								defaultValue="meat"
								as={
									<RadioGroup className={s.meatTypeRadio}>
										<Box>
											<FormControlLabel
												value="meat"
												control={<Radio color="primary" size="small" />}
												label="בשרי"
											/>
										</Box>
										<Box>
											<FormControlLabel
												value="vegetarian"
												control={<Radio color="primary" size="small" />}
												label="צמחוני"
											/>
										</Box>
										<Box>
											<FormControlLabel
												value="vegan"
												control={<Radio color="primary" size="small" />}
												label="טבעוני"
											/>
										</Box>
									</RadioGroup>
								}
							/>
							<Box>
								<FormInputText
									matInput
									error={errors.mealPatty}
									label={
										pattyType === 'vegan' || pattyType === 'vegetarian'
											? 'שם הקציצה'
											: 'סוג הבשר'
									}
									name="mealPatty"
									ref={register({ required: true })}
								/>
							</Box>
							<Box>
								<FormInputText
									matInput
									label={'משקל'}
									name="pattyWeight"
									helperText="אופציונלי"
									error={errors.pattyWeight}
									type="number"
									ref={register({ required: false, min: 10, max: 600 })}
								/>
							</Box>
						</Box>
						<h5>תוספות</h5>
						<AutoCompleteInput
							type="createable"
							options={addonOptions}
							defaultValue={addOns}
							isMulti
							onChange={(value: ValueType<OptionTypeBase, false>) =>
								setAddOns(
									(value && value.forEach((v: any) => delete v.label)) ||
										value ||
										[]
								)
							}
							formatCreateLabel={(value) => `תוספת חדשה: ${value}`}
							getOptionLabel={(part) => part.label || part.name}
							getOptionValue={(part) => part.name}
							getNewOptionData={(value, node) => ({
								label: node,
								name: value,
								type: 'addon',
							})}
							placeholder="בחר מתוך תוספות קיימות או הקלד אחת חדשה"
						/>
						<Box mt={1}>
							<FormInputText
								matInput
								error={errors.mealPrice}
								name="mealPrice"
								ref={register({ required: true, min: 5, max: 300 })}
								label="מחיר"
								helperText="ללא עלויות נוספות כגון משלוח ותוספות"
								type="number"
							/>
						</Box>
						<h5>העלאת תמונה</h5>
						<Box display="flex" justifyContent="center" mt={1} mb={1}>
							<ImageDropZone
								isMulti
								onFileChange={(files) => setFiles(files)}
								onFilesReject={(errorMessage) => setErrorMsg(errorMessage)}
								text={
									isMobile ? (
										<span className="mainTextLink">בחירת תמונה מהמכשיר</span>
									) : (
										<span>
											ניתן לגרור את התמונה לכאן או
											{<span className="mainTextLink"> להעלות</span>} ישירות
											מהמכשיר
										</span>
									)
								}
							/>
						</Box>
					</div>
				</div>
				<span style={{ fontSize: '13px', color: 'red' }}>{errorMsg}</span>
				<FormButtonFooter
					handleCancel={() => {
						setOpenDialog(null);
						activePlace && setActiveMeal(null);
					}}
					handleSubmit={() => handleSubmit(onSubmit)()}
					disabled={!formState.isValid}
					loading={isLoading}
				/>
			</form>
			<Box
				className="formWrapper"
				maxHeight={300}
				id="updateMealFinalStep"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
			>
				<h2>העדכון נשלח בהצלחה!</h2>
				<p> הפרטים החדשים עברו למערכת הקווסט לבדיקה ויעלו לאתר במידה ויאושרו</p>
				<p>רב תודות</p>
				<MainButton
					backgroundColor="black"
					color="white"
					width="20%"
					handleClick={() => {
						setOpenDialog(null);
						activePlace && setActiveMeal(null);
					}}
				>
					סגור
				</MainButton>
			</Box>
		</StageStepper>
	);
};

export default UpdateMealForm;
