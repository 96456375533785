import * as React from 'react';
import s from './UserProfileStyle.module.scss';
import { Button } from '@material-ui/core';
import UsersService from 'api/services/users.service';
import MainButton from 'components/general/MainButton/MainButton';
import { UserContext } from 'context/UserProvider';
import User from 'types/user';
import { useForm } from 'react-hook-form';
import { SocialLinks } from 'social-links';
import FormInputText from 'components/forms/FormInput/FormInputText';

type Updates = {
	image: File | undefined;
};
interface UserProfileProps {
	user: User;
}
const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
	const [profileImage, setImage] = React.useState(user.photoUrl || null);
	const [updates, setUpdates] = React.useState<Updates>({ image: undefined });
	const [isLoading, setLoading] = React.useState(false);
	const { setActiveUser } = React.useContext<any>(UserContext);
	const [displayMsg, setDisplayMsg] = React.useState<{ msg: string; style: 'sucMsg' | 'errMsg' }>(
		{
			msg: '',
			style: 'sucMsg',
		}
	);
	const socialLinks = new SocialLinks();

	const handleImagePicked = (files: FileList | null) => {
		if (files) {
			setImage(URL.createObjectURL(files[0]));
			setUpdates((current) => ({ ...current, image: files[0] }));
		}
	};

	const uploadImage = (image: File) => {
		const imageData = new FormData();
		imageData.append('image', image);
		return UsersService.uploadPhoto(user._id, imageData);
	};

	const { register, handleSubmit, errors, formState, watch } = useForm({
		mode: 'onChange',
	});

	const facebookUrl: string = watch('facebookUrl');
	const instagramUrl: string = watch('instagramUrl');

	const onSubmit = async (formData) => {
		setLoading(true);
		const facebookLink = facebookUrl
			? socialLinks.sanitize('facebook', facebookUrl.toLowerCase())
			: undefined;

		const instagramLink = instagramUrl
			? socialLinks.sanitize('instagram', instagramUrl.toLowerCase())
			: undefined;

		const updateRequest = {
			_id: user._id,
			firstName: formData.firstName,
			lastName: formData.lastName,
			social: {
				facebookLink: facebookLink,
				instagramLink: instagramLink,
				website: formData.website || undefined,
			},
		};

		await UsersService.update(user._id, updateRequest)
			.then((res) => {
				setActiveUser(res);
				setDisplayMsg({ msg: 'הפרטים עודכנו בהצלחה', style: 'sucMsg' });
			})
			.catch(() => {
				setDisplayMsg({ msg: 'קרתה תקלה בעדכון הפרטים', style: 'errMsg' });
			})
			.then(async () => {
				if (updates.image) {
					await uploadImage(updates.image)
						.then((res) => {
							setActiveUser(res);
							setDisplayMsg({ msg: 'התמונה עודכנה בהצלחה', style: 'sucMsg' });
						})
						.catch(() => {
							setDisplayMsg({ msg: 'קרתה תקלה בהעלאת התמונה', style: 'errMsg' });
						});
				}
			})
			.finally(() => {
				setLoading(false);
				setUpdates((current) => ({ ...current, image: undefined }));
			});
	};

	return (
		<form className={s.root}>
			<input
				accept="image/*"
				className={s.input}
				id="profile-image-upload"
				type="file"
				onChange={(e) => handleImagePicked(e.target.files)}
			/>
			<label
				htmlFor="profile-image-upload"
				className={s.imageCircle}
				style={{ backgroundImage: `url(${profileImage})` }}
			>
				<Button component="div" className={s.imageButton} />
			</label>
			{/* TOFIX: Add a button to remove existing image */}
			{/* <span className={s.delImgBtn}>מחיקת תמונה</span> */}
			<h3 className={s.userName}>{`${user.firstName} ${user.lastName || ''}`}</h3>
			<span>{user.points}</span>
			<div className={s.updateFields}>
				<div className={s.nameWrapper}>
					<FormInputText
						matInput
						defaultValue={user.firstName}
						label="שם פרטי"
						name="firstName"
						ref={register({ required: true })}
						error={Boolean(errors.firstName)}
					/>
					<FormInputText
						matInput
						defaultValue={user.lastName || ''}
						label="שם משפחה"
						name="lastName"
						ref={register({ required: false })}
						error={Boolean(errors.lastName)}
					/>
				</div>
				<FormInputText
					matInput
					inputDir="ltr"
					name="instagramUrl"
					defaultValue={user?.social?.instagramLink || ''}
					label="קישור לעמוד אינסטגרם"
					placeholder="https://instagram.com/...."
					ref={register}
					error={
						instagramUrl &&
						!socialLinks.isValid('instagram', instagramUrl.toLowerCase() || '')
					}
				/>
				<FormInputText
					matInput
					name="facebookUrl"
					inputDir="ltr"
					defaultValue={user?.social?.facebookLink || ''}
					label="קישור לעמוד פייסבוק"
					placeholder="https://facebook.com/...."
					ref={register}
					error={
						facebookUrl &&
						!socialLinks.isValid('facebook', facebookUrl.toLowerCase() || '')
					}
				/>
				{/* <FormInputText
					matInput
					name="website"
					inputDir="ltr"
					defaultValue={user?.social?.website || ''}
					placeholder="https://yoursite.yourdomain/"
					ref={register({
						pattern: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i,
					})}
					error={Boolean(errors.website)}
					label="קישור לאתר אישי"
				/> */}
			</div>

			<MainButton
				loading={isLoading}
				disabled={!updates.image && !formState.isValid}
				handleClick={() => handleSubmit(onSubmit)()}
			>
				עדכון
			</MainButton>
			<p className={`${displayMsg.style === 'errMsg' ? s.errMsg : s.sucMsg}`}>
				{displayMsg.msg}
			</p>
		</form>
	);
};

export default React.memo(UserProfile);
