import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_ROOT_URL || '/api',
	timeout: 20000,
	headers: {
		'Content-type': 'application/json'
	}
});


// handle response of every request
instance.interceptors.response.use(
	response => {
		return response.data;
	},
	err => {
		return Promise.reject(err);
	}
);

export default instance;