import React from 'react';
import { Box } from '@material-ui/core';
import PlaceholderImage from 'assets/image-placeholder.svg';

interface ImagePlaceholderProps {
	size?: number;
}

const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({ size = 24 }) => {
	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<img src={PlaceholderImage} width={size} alt="placeholder" />
		</Box>
	);
};

export default React.memo(ImagePlaceholder);
