import * as React from 'react';
import QuestSnakbar from 'components/general/QuestSnakbar';
import { SnackbarCloseReason } from '@material-ui/core';

export type ToastType = {
	message: string | null;
	type: 'warning' | 'success' | 'error' | 'info' | undefined;
	icon?: React.ReactNode;
};
export type ToastContextType = {
	toast: ToastType;
	setToast(toast: ToastType): any;
};
export const ToastContext = React.createContext<ToastContextType>({
	toast: { message: null, type: 'success', icon: null },
	setToast: () => undefined,
});
ToastContext.displayName = 'ToastContext';

interface ToastProviderProps {
	children: React.ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
	const [toast, setToast] = React.useState<ToastType>({
		message: null,
		type: 'success',
		icon: null,
	});

	const handleCloseSnackbar = (
		_event: React.SyntheticEvent<any, Event>,
		reason: SnackbarCloseReason
	) => {
		if (reason === 'clickaway') {
			return;
		}
		setToast({ ...toast, message: null });
	};

	return (
		<ToastContext.Provider value={{ toast, setToast }}>
			<QuestSnakbar
				handleClose={handleCloseSnackbar}
				message={toast.message}
				alertType={toast.type}
				noIcon={toast.icon ? false : true}
			/>
			{children}
		</ToastContext.Provider>
	);
};

export default React.memo(ToastProvider);
