import React from 'react';
import FormInputText from 'components/forms/FormInput/FormInputText';
import { Controller } from 'react-hook-form';
import { Box, RadioGroup, FormControlLabel, Radio, Grid } from '@material-ui/core';

export const SenderDetailsForm = ({ register, errors, control }) => {
	return (
		<>
			<Box mb={1}>
				<h3>פרטי מגיש הבקשה</h3>
			</Box>
			<Grid container spacing={1}>
				<Grid item xs>
					<FormInputText
						matInput
						error={errors.senderFirstName}
						name="senderFirstName"
						label="שם פרטי"
						ref={register({ required: true })}
					/>
				</Grid>
				<Grid item xs>
					<FormInputText
						matInput
						error={errors.senderLastName}
						name="senderLastName"
						label="שם משפחה"
						ref={register({ required: true })}
					/>
				</Grid>
			</Grid>
			<span className="errorMsg">
				{errors.senderLastName && errors.senderLastName && 'יש להזין שם מלא'}
			</span>
			<FormInputText
				matInput
				error={errors.senderEmail}
				type="email"
				name="senderEmail"
				label="דואר אלקטרוני"
				ref={register({
					required: true,
					pattern: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/i,
				})}
			/>
			<span className="errorMsg">{errors.senderEmail && 'יש להזין כתובת דוא"ל תקינה'}</span>
			<FormInputText
				name="senderPhone"
				matInput
				error={errors.senderPhone}
				label="טלפון"
				helperText="אופציונלי"
				ref={register}
			/>
			<h5>האם הנך נציג המסעדה?</h5>
			<Controller
				control={control}
				name="isSenderOwner"
				defaultValue="false"
				as={
					<RadioGroup>
						<Box>
							<FormControlLabel
								value="true"
								control={<Radio color="primary" size="small" />}
								label="כן"
							/>
						</Box>
						<Box mt={-1}>
							<FormControlLabel
								value="false"
								control={<Radio color="primary" size="small" />}
								label="לא"
							/>
						</Box>
					</RadioGroup>
				}
			/>
		</>
	);
};
