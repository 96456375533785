import * as React from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { MealContext, PlaceContext } from 'context/ContextProvider';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Meal from 'types/meal';
import { DietPropsType } from 'types/common';
import { parsePartTypes } from 'utils/functions.util';
import ReactGA from 'react-ga';

export type SearchFiltersType = {
	costRange: number;
	kosher: string[];
	orderMethods: string[];
	partTypes: string[];
	props: DietPropsType[]; //Diet type
};

export interface SearchContextType {
	searchQuery: string;
	setSearchQuery(query: string): void;
	searchFilters: any;
	setFilters(filters: React.Dispatch<React.SetStateAction<any[]>>): void;
	isSearching: boolean;
	setSearching(state: boolean): void;
	checkFilters(): any;
	cleanFilters(): void;
	hasMoreItems: boolean;
	setHasMoreItems(bool: boolean): void;
	partFilters: Record<string, any> | null;
	setPartFilters(filters: any): void;
}

export const SearchContext = React.createContext<SearchContextType>({
	searchQuery: '',
	setSearchQuery: () => undefined,
	searchFilters: undefined,
	setFilters: () => undefined,
	isSearching: false,
	setSearching: () => undefined,
	checkFilters: () => undefined,
	cleanFilters: () => undefined,
	setHasMoreItems: () => undefined,
	hasMoreItems: true,
	partFilters: null,
	setPartFilters: () => undefined,
});
SearchContext.displayName = 'SearchContext';

interface SearchProviderProps {
	children: React.ReactNode;
	getTopResults(): any;
	getMealData(
		query?: string,
		filters?: { [x: string]: any },
		skip?: number,
		limit?: number
	): Promise<{ meals: Meal[]; count: number; newMealsCount: number }>;
}

const SearchProvider: React.FC<SearchProviderProps> = ({
	children,
	getTopResults,
	getMealData,
}) => {
	const [searchQuery, setSearchQuery] = React.useState<string>('');
	const [searchFilters, setFilters] = React.useState<any>(null);
	const [partFilters, setPartFilters] = React.useState<Record<string, any> | null>(null);
	const [isSearching, setSearching] = React.useState<boolean>(false);
	const [hasMoreItems, setHasMoreItems] = React.useState<boolean>(true);
	const isInitialMount = React.useRef(true);
	const { setActiveMeal } = React.useContext(MealContext);
	const { setActivePlace } = React.useContext<any>(PlaceContext);
	const isHome = useRouteMatch({ path: '/', exact: true, strict: true });
	const history = useHistory();

	const checkFilters = React.useCallback(() => {
		let activeFilters: null | Record<string, any> = null;
		searchFilters &&
			Object.entries(searchFilters).map(([key, value]) => {
				if (value) {
					if (!activeFilters) {
						activeFilters = {};
					}
					return (activeFilters[key] = value);
				}
				return null;
			});
		return activeFilters || (partFilters && Object.keys(partFilters).length > 0);
	}, [searchFilters, partFilters]);

	const cleanFilters = () => {
		setSearchQuery('');
		setFilters(null);
		setSearching(false);
		setPartFilters(null);
	};

	const clearFocus = () => {
		history.push('/');
		// setActivePlace(null);
		setActiveMeal(null);
	};

	const debouncedSearch = React.useMemo(
		() =>
			AwesomeDebouncePromise(
				async (
					searchQuery: string,
					searchFilters: any,
					hasActiveFilters: null | boolean
				) => {
					if (
						(!searchQuery || searchQuery === '') &&
						(!searchFilters || (searchFilters && !hasActiveFilters))
					) {
						getTopResults();
						setHasMoreItems(true);
						return null;
					}
					const { meals, count, newMealsCount } = await getMealData(
						searchQuery,
						searchFilters
					);
					setHasMoreItems(newMealsCount === 30);
					return { values: meals, count: count };
				},
				500
			),
		[]
	);

	React.useEffect(() => {
		async function applySearch() {
			setSearching(true);
			if (!isHome) {
				clearFocus();
			}
			const hasActiveFilters = checkFilters();
			const filters = partFilters
				? { ...searchFilters, partTypes: parsePartTypes(partFilters) }
				: searchFilters;
			await debouncedSearch(searchQuery, filters, hasActiveFilters).finally(() =>
				setSearching(false)
			);
		}

		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			applySearch();
			ReactGA.event({
				category: 'Search',
				action: 'Searched using filters',
				label: `Search phrase: ${searchQuery.toString()}; Search filters? ${checkFilters.toString()}`,
			});
		}
	}, [searchQuery, searchFilters, partFilters]);

	return (
		<SearchContext.Provider
			value={{
				searchQuery,
				setSearchQuery,
				searchFilters,
				setFilters,
				checkFilters,
				cleanFilters,
				isSearching,
				setSearching,
				hasMoreItems,
				setHasMoreItems,
				partFilters,
				setPartFilters,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};

export default SearchProvider;
