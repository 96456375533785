import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from 'context/UserProvider';
import { isUserRole } from 'utils/functions.util';

export interface PrivateRouteProps {
	component: React.FC<any>;
	[x: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
	const { activeUser } = React.useContext<any>(UserContext);
	return (
		<Route
			{...rest}
			render={(props) =>
				!activeUser ? (
					<Redirect to="/" />
				) : isUserRole(activeUser, 'admin') ? (
					<Component {...props} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
};

export default React.memo(PrivateRoute);
