import * as React from 'react';
import s from './ReviewItemStyle.module.scss';
import { Grid } from '@material-ui/core';
import UserImg from 'components/general/user/UserImg/UserImg';
import { Review } from 'types/meal';
import ScoreStars from 'components/sidebar/new/ScoreStars';
import { roundNumber } from 'utils/functions.util';
import { useHistory } from 'react-router-dom';

const dateFormat = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
};

export interface ReviewItemProps {
	review: Review;
}

const ReviewItem: React.FC<ReviewItemProps> = ({ review }) => {
	const { user } = review;
	const history = useHistory();
	return (
		<div className={s.root}>
			<Grid container>
				<Grid
					item
					xs={1}
					className={s.userSection}
					onClick={() => history.push(`/profile/${review.user._id}`)}
				>
					<UserImg size={46} user={user} />
					<h4 className={s.userName}>
						{user && user.firstName
							? `${user.firstName} ${user.lastName || ''}`
							: 'פלוני אלמוני'}
					</h4>
				</Grid>
				<Grid item xs className={s.reviewSection}>
					<div className={s.metaData}>
						<ScoreStars count={review.score} />
						<span className={s.scoreNumber}>{roundNumber(review.score)}</span>
						<span className={s.date}>
							{new Date(review.updatedOn).toLocaleDateString('he-IL', dateFormat)}
						</span>
					</div>
					<div className={s.comment}>{review?.comment}</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default React.memo(ReviewItem);
