import React, { MouseEventHandler, ReactNode } from 'react';
import './MainButtonStyle.scss';
import ClipLoader from 'react-spinners/ClipLoader';

interface MainButtonProps {
	handleClick: MouseEventHandler;
	children: ReactNode;
	type?: 'button' | 'submit' | 'reset' | undefined;
	disabled?: boolean;
	className?: string;
	loading?: boolean;
	fontSize?: number;
	color?: string;
	[x: string]: any;
}

const MainButton: React.FC<MainButtonProps> = ({
	handleClick,
	children,
	type,
	disabled = false,
	className,
	loading = false,
	fontSize,
	color,
	...rest
}) => {
	const onClicked = (e: React.MouseEvent<Element, MouseEvent>) => {
		e.preventDefault();
		!loading && handleClick(e);
	};

	return (
		<button
			className={`mainBtnContainer disabled-${disabled} loading-${loading} ${className}`}
			style={{
				...rest,
				color: color,
				fontSize: fontSize,
			}}
			onClick={(e) => onClicked(e)}
			type={type}
			disabled={disabled}
		>
			{loading ? (
				<ClipLoader size={fontSize ? fontSize : 10} color={color} />
			) : (
				children
			)}
		</button>
	);
};
export default MainButton;
