import * as React from 'react';
import Meal, { MealContextType } from 'types/meal';
import s from './MainFeedStyle.module.scss';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import MainButton from 'components/general/MainButton/MainButton';
import { DialogContext } from 'context/DialogManager';
import { MealContext } from 'context/ContextProvider';
import InfiniteScroller from 'components/sidebar/new/MainFeed/InfiniteScroller';
import { parsePartTypes } from 'utils/functions.util';
import Select from 'react-select';
import { hiddenSelectStyle } from 'components/forms/FormInput/FormInputSelect';
import { FEED_SORTING_OPTIONS } from 'utils/values.util';

const FETCH_LIMIT = 30;

const MainFeed: React.FC = () => {
	const listHeight = window.innerHeight - 100;
	const { setOpenDialog } = React.useContext(DialogContext);
	const {
		isSearching,
		hasMoreItems,
		setHasMoreItems,
		setFilters,
		searchQuery,
		searchFilters,
		partFilters,
		checkFilters,
	} = React.useContext<SearchContextType>(SearchContext);
	const initial = React.useRef<boolean>(true);

	const {
		mealsList: list,
		mealsCount: itemCount,
		loadingMeal,
		getMealData,
	} = React.useContext<MealContextType>(MealContext);

	const loadMoreItems = (): Promise<any> => {
		return new Promise(() => {
			if (!initial.current) {
				const filters = partFilters
					? { ...searchFilters, partTypes: parsePartTypes(partFilters) }
					: searchFilters;
				return getMealData(searchQuery, filters, list.length, FETCH_LIMIT).then(
					(res: { meals: Meal[]; count: number; newMealsCount: number }) => {
						setHasMoreItems(res.newMealsCount === FETCH_LIMIT);
					}
				);
			} else return null;
		});
	};
	const scrollRef = React.useRef<any>();

	const scrollTop = () => {
		scrollRef?.current?._listRef.scrollTo(0);
	};

	React.useEffect(() => {
		if (isSearching) {
			scrollTop();
			initial.current = true;
		} else {
			initial.current = false;
		}
	}, [isSearching, list, itemCount]);

	return (
		<div className={s.root}>
			<div className={s.headerSection}>
				<h3 className={s.headerTitle} onClick={scrollTop}>
					{`${
						searchQuery || checkFilters() ? 'ההמבורגרים שנמצאו' : 'כל ההמבורגרים'
					} (${itemCount}) `}
					{searchFilters?.geo && (
						<span style={{ fontWeight: 600 }}>
							- מחפש לפי המפה{' '}
							<span
								className="mainTextLink"
								onClick={() =>
									setFilters((current) => ({ ...current, geo: undefined }))
								}
							>
								ביטול
							</span>
						</span>
					)}
				</h3>
				{/* <div className={s.sortBy}>
					<span>סדר</span>
					<Select
						styles={hiddenSelectStyle}
						isSearchable={false}
						isRtl={true}
						options={FEED_SORTING_OPTIONS}
						defaultValue={FEED_SORTING_OPTIONS[1]}
						onChange={(e) => window.sessionStorage.setItem('qb_r_t', `${e?.value}`)}
					/>
				</div> */}
			</div>

			{(isSearching || loadingMeal) && <LoadingOverlay />}
			{!isSearching && !loadingMeal && list.length === 0 && (
				<div className={s.noResultWrapper}>
					<p>לצערינו אין לנו תוצאות מתאימות לחיפוש שלך</p>
					<div className={s.noResultsBtns}>
						<MainButton
							className={s.actionBtn}
							handleClick={() => setOpenDialog('place')}
						>
							<span>+ הוספת מסעדה</span>
						</MainButton>
						<MainButton
							className={s.actionBtn}
							handleClick={() => setOpenDialog('meal')}
						>
							<span>+ הוספת מנה</span>
						</MainButton>
					</div>
				</div>
			)}
			<InfiniteScroller
				list={list}
				listHeight={listHeight}
				hasMoreItems={hasMoreItems}
				loadMoreItems={loadMoreItems}
				threshold={5}
				ref={scrollRef}
			/>
		</div>
	);
};

export default React.memo(MainFeed);
