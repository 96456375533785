import * as React from 'react';
import s from './ExpandedFilterPanelStyle.module.scss';
import { Input } from '@material-ui/core';
import CheckboxComponent from 'components/general/CheckBox/CheckBoxComponent';
import MainButton from 'components/general/MainButton/MainButton';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import { MealPart } from 'types/meal';

export interface ExpandedFilterPanelProps {
	filters: MealPart[];
	filterName?: string;
	exitDialog(): void;
}

const ExpandedFilterPanel: React.FC<ExpandedFilterPanelProps> = ({
	filters,
	exitDialog,
	filterName,
}) => {
	const { isSearching, setPartFilters, partFilters } = React.useContext<SearchContextType>(
		SearchContext
	);
	const [selectedFilters, setSelectedFilters] = React.useState<any>(
		partFilters && partFilters[filters[0].parentType]
			? partFilters[filters[0].parentType]
			: new Set()
	);
	const [searchValue, setSearchValue] = React.useState<string>('');
	const displayFilters =
		searchValue.length > 0
			? filters.filter((mealPart) => mealPart.name.includes(searchValue))
			: filters;

	const handleChange = (filter: MealPart, checked: boolean) => {
		const activeFilters = new Set(selectedFilters);
		checked ? activeFilters.add(filter.sharedId) : activeFilters.delete(filter.sharedId);
		setSelectedFilters(activeFilters);
		return selectedFilters;
	};

	const sendFilters = () => {
		setPartFilters((current) => ({ ...current, [filters[0].parentType]: selectedFilters }));
		exitDialog();
	};

	return (
		<div className={s.root}>
			<Input
				placeholder={`חיפוש ${filterName} לפי שם`}
				onChange={(e) => setSearchValue(e.target.value)}
				className={s.filterSearchInput}
			/>
			<div className={`scrollBar ${s.filtersWrapper}`}>
				{displayFilters.map((filter, index) => (
					<div key={index} className={s.filterItem}>
						<CheckboxComponent
							label={filter.name}
							onChange={(checked) => handleChange(filter, checked)}
							controller={selectedFilters.has(filter.sharedId)}
						/>
					</div>
				))}
			</div>
			<MainButton loading={isSearching} className={s.sendBtn} handleClick={sendFilters}>
				חיפוש
			</MainButton>
		</div>
	);
};

export default React.memo(ExpandedFilterPanel);
