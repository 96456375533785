import * as React from 'react';
import MainButton from 'components/general/MainButton/MainButton';
// import UpdatePlaceForm from './forms/UpdatePlaceForm';
import StageStepper from 'components/general/StageStepper';
import { Box } from '@material-ui/core';
import 'components/dialog/dialogContent/DialogFormStyle.scss';
import Place from 'types/place';
import UpdatePlaceForm from 'components/dialog/dialogContent/place/UpdatePlaceForm/UpdatePlaceForm';

export interface UpdatePlaceComponentProps {
	placeToEdit: Place;
	handleCloseDialog(): void;
}

const UpdatePlaceComponent: React.FC<UpdatePlaceComponentProps> = ({
	placeToEdit,
	handleCloseDialog,
}) => {
	const [stage, setStage] = React.useState(0);

	return (
		<div className="formWrapper">
			<StageStepper step={stage}>
				<UpdatePlaceForm
					handleCloseDialog={handleCloseDialog}
					stage={stage}
					setStage={setStage}
					placeToEdit={placeToEdit}
				/>
				<Box display="flex" flexDirection="column" alignItems="center">
					<h3>רב תודות!</h3>
					<p>הפרטים נשלחו למערכת הקווסט לבדיקה ידנית, במידה ויאושרו יעודכנו באתר</p>
					<MainButton
						bgColor="black"
						textColor="white"
						width="20%"
						handleClick={() => handleCloseDialog()}
					>
						סגור
					</MainButton>
				</Box>
			</StageStepper>
		</div>
	);
};

export default UpdatePlaceComponent;
