import * as React from 'react';
import DesktopSideBar from 'components/sidebar/desktop/DesktopSideBar';
import MobileSideBar from 'components/sidebar/mobile/MobileSideBar';
import { BrowserView, MobileView } from 'react-device-detect';
import '../SideBarStyle.scss';

const MainSideBar: React.FC = () => {
	return (
		<>
			<BrowserView>
				<DesktopSideBar />
			</BrowserView>
			<MobileView>
				<MobileSideBar />
			</MobileView>
		</>
	);
};

export default MainSideBar;
