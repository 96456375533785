import { GeoLiteral, GeoPoint } from 'types/map';
import { Meal, MealPart } from 'types/meal';
import Place from 'types/place';

export function getGeoLiteral(locationObject: GeoPoint): GeoLiteral {
	return {
		lng: locationObject.coordinates[0],
		lat: locationObject.coordinates[1],
	};
}

export function isUserRole(user, role) {
	if (!user || !role) return null;
	return user.roles.includes(role);
}

export function getMealParts(meal: Meal, partType: string): MealPart[] {
	if (meal && meal.parts && meal.parts.length > 0) {
		const filteredArray = meal.parts.filter((part) => part.type === partType);
		return filteredArray.length > 0 ? filteredArray : [];
	} else return [];
}

export function objToArray(propObject) {
	if (propObject && Object.keys(propObject).length > 0) {
		const keysArray: string[] = [];
		Object.entries(propObject).map(([key, value]) => value && keysArray.push(key.toString()));
		return keysArray.length > 0 ? keysArray : null;
	}
	return null;
}

export function addonsToObjectArray(partsObject) {
	const addonsArray: Array<{ value: string; label: string }> = [];
	const filteredParts = partsObject.filter((part) => part.type === 'addon');
	filteredParts.map((part) => addonsArray.push({ value: part.name, label: part.name }));
	return addonsArray;
}

export function roundNumber(number: number) {
	const formattedNumber = Number(number);
	if (formattedNumber <= 0) {
		return 0.0;
	}
	return formattedNumber % 1 === 0
		? formattedNumber.toFixed(1)
		: Number(formattedNumber.toFixed(1));
}

export function openInNewTab(url: string): void {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) newWindow.opener = null;
	return;
}

export const getImageUrl = (item: Meal | Place, index?: number): string | null => {
	if (!item || (!item.images && !item.imageUrls)) return null;
	const displayImage = item.images ? item?.images[index || 0] : item?.imageUrls[index || 0];
	if (displayImage) {
		const imageUrl: string = typeof displayImage === 'string' ? displayImage : displayImage.url;
		return imageUrl;
	} else return null;
};

export const parsePartTypes = (partTypeObject: any): Array<string[]> => {
	const parsedArray: Array<string[]> = [];
	Object.entries(partTypeObject).map(([, partTypeSet]: any) => {
		const tempArray: string[] = [];
		[...partTypeSet].forEach((partId: string) => tempArray.push(partId));
		parsedArray.push(tempArray);
	});
	return parsedArray;
};
