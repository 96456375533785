import React from 'react';
import { ButtonBase, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import Skeleton from 'react-loading-skeleton';

const StyledButton = withStyles({
	root: {
		backgroundColor: 'var(--main-color)',
		color: 'black',
		width: 280,
		height: 45,
		boxShadow: '0 2px 6px 0 #ffdd81',
		borderRadius: ({ type }) =>
			type === 'google' ? '22px 22px 0 22px' : '22px 22px 22px 0',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '0 20px',
		fontWeight: 600,
	},
})(ButtonBase);

const LoginButton = ({ type, ...props }) => {
	return (
		<>
			{props.disabled ? (
				<Skeleton
					width={280}
					height={45}
					style={{
						borderRadius:
							type === 'google' ? '22px 22px 0 22px' : '22px 22px 22px 0',
					}}
				/>
			) : (
				<StyledButton type={type} {...props}>
					{type === 'facebook' && (
						<FontAwesomeIcon fontSize="40px" icon={faFacebookF} />
					)}
					<span>
						באמצעות {type === 'google' && 'גוגל'}{' '}
						{type === 'facebook' && 'פייסבוק'}
					</span>
					{type === 'google' && (
						<FontAwesomeIcon fontSize="16px" icon={faGoogle} />
					)}
				</StyledButton>
			)}
		</>
	);
};

export default LoginButton;
