import * as React from 'react';
import s from './UpdatePlaceFormStyle.module.scss';
import 'components/dialog/dialogContent/DialogFormStyle.scss';
import { Box, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import PlacesService from 'api/services/places.service';
import OrderMethodInput from 'components/dialog/dialogContent/place/AddPlace/forms/OrderMethodsInput';
import { SenderDetailsForm } from 'components/dialog/dialogContent/place/AddPlace/forms/SenderDetailsForm';
import PlacesAutoComplete from 'components/dialog/dialogContent/place/PlacesAutoComplete';
import FormButtonFooter from 'components/forms/FormButtonFooter';
import FormInputSelect from 'components/forms/FormInput/FormInputSelect';
import FormInputText from 'components/forms/FormInput/FormInputText';
import { UserContext, UserContextType } from 'context/UserProvider';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import { GeoLiteral } from 'types/map';
import Place from 'types/place';
import { getGeoLiteral } from 'utils/functions.util';
import { KOSHER_OPTIONS_LIST, ORDER_METHODS_LIST } from 'utils/values.util';

const getValidationPattern = (value?: string, pattern?: 'phone' | 'email') => {
	if (pattern === 'phone' || (value && !Number.isNaN(Number(value.charAt(0))))) {
		return /^[0,1]{1}([0-9-+()]).{8,16}$/gm;
	} else
		return /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;
};

export interface UpdatePlaceFormProps {
	stage: number;
	setStage(num: number): void;
	placeToEdit: Place;
	handleCloseDialog(): void;
	[x: string]: any;
}

const UpdatePlaceForm: React.FC<UpdatePlaceFormProps> = ({
	stage,
	setStage,
	placeToEdit,
	handleCloseDialog,
	...props
}) => {
	const { activeUser } = React.useContext<UserContextType>(UserContext);
	const [isLoading, setLoadingState] = React.useState<boolean>(false);
	const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
	const [placePosition, setPlacePosition] = React.useState<GeoLiteral | null>(
		placeToEdit ? getGeoLiteral(placeToEdit.location) : null
	);

	const { register, control, watch, handleSubmit, errors, formState } = useForm({
		mode: 'onChange',
		defaultValues: {
			placeTitle: placeToEdit ? placeToEdit.title : null,
			placeAddress: placeToEdit ? placeToEdit.address : null,
			placeWebsite: placeToEdit ? placeToEdit.website : null,
			placePhone: placeToEdit ? placeToEdit.phoneNumbers[0] : null,
			placeLocation: placeToEdit ? getGeoLiteral(placeToEdit.location) : null,
			hasDelivery: placeToEdit && placeToEdit.orderMethods.self ? 'true' : 'false',
			deliveryLink:
				(placeToEdit &&
					placeToEdit.orderMethods.self &&
					placeToEdit.orderMethods.self.url) ||
				null,
			kosherLevel: placeToEdit
				? KOSHER_OPTIONS_LIST.find(
						(item) =>
							item.value !== 'false' && Number(item.value) === placeToEdit.kosher
				  )
				: null,

			senderFirstName: activeUser?.firstName,
			senderLastName: activeUser?.lastName,
			senderEmail: activeUser?.email,
			isSenderOwner: 'false',
		},
	});

	const hasDelivery = watch('hasDelivery');
	const deliveryValue = watch('deliveryLink');

	const sendRequest = async ({ request, type }) => {
		const isAdmin = activeUser?.roles.includes('admin');
		if (type === 'update') {
			return isAdmin
				? PlacesService.update(request._id, request)
				: PlacesService.createUpdateRequest(request._id, request);
		} else {
			return isAdmin ? PlacesService.create(request) : PlacesService.createRequest(request);
		}
	};

	const onSubmit = async (formData) => {
		setLoadingState(true);
		setErrorMsg(null);
		const placeRequest = {
			_id: placeToEdit ? placeToEdit._id : null,
			title: formData.placeTitle,
			address: formData.placeAddress,
			location: {
				coordinates: [placePosition?.lng, placePosition?.lat],
			},
			website: formData.placeWebsite,
			kosher: formData.kosherLevel.value,
			orderMethods: {
				wolt: { delivery: formData.wolt, url: formData.woltLink },
				tenbis: { delivery: formData.tenbis, url: formData.tenbisLink },
				cibus: { delivery: formData.cibus, url: formData.cibusLink },
				self: {
					delivery: formData.hasDelivery === 'true' ? true : false,
					url: formData.deliveryLink,
				},
			},
			phoneNumbers: [formData.placePhone],
			firstName: formData.senderFirstName,
			lastName: formData.senderLastName,
			email: formData.senderEmail,
			phoneNumber: formData.senderPhone,
			isOwner: formData.isSenderOwner,
		};
		await sendRequest({
			request: placeRequest,
			type: placeToEdit ? 'update' : 'new',
		})
			.then((response) => {
				response ? setStage(stage + 1) : setErrorMsg('קרתה תקלה בשליחת הנתונים');
			})
			.catch((e) => {
				console.log(e);
				setErrorMsg('קרתה תקלה בשליחת הנתונים');
			});
		setLoadingState(false);
	};

	return (
		<form id="updatePlaceForm1" className={s.formWrapper} autoComplete="off">
			<div className={`scrollBar ${s.formContent}`}>
				<Box mt={-2} mb={-1}>
					<h3>פרטי המסעדה</h3>
				</Box>
				<FormInputText
					matInput
					error={errors.placeTitle}
					name="placeTitle"
					label="שם"
					ref={register({ required: true })}
				/>

				{errors.placeTitle && <span className="errorMsg">יש להזין שם עסק</span>}

				<PlacesAutoComplete
					initialPlace={props.placePosition ? props.placePosition : null}
					handlePlacePicked={(placePosition) => {
						setPlacePosition(placePosition);
					}}
					inputRef={register({ required: true })}
				/>
				{formState.touched.placeAddress && !placePosition && (
					<span className="errorMsg">כדי להוסיף מסעדה יש לבחור כתובת מרשימת התוצאות</span>
				)}
				{errors.placeAddress && <span className="errorMsg">יש להזין כתובת תקינה</span>}
				<Box mt={1}>
					<FormInputText
						matInput
						name="placeWebsite"
						label="אתר או עמוד עסקי"
						placeholder="קישור לאתר המסעדה"
						helperText="אופציונלי"
						ref={register()}
					/>
				</Box>
				<Box mt={1}>
					<FormInputText
						matInput
						error={errors.placePhone}
						name="placePhone"
						label="מספר טלפון"
						placeholder="מספר הטלפון של המסעדה"
						helperText="אופציונלי"
						ref={register({ pattern: getValidationPattern(undefined, 'phone') })}
					/>
				</Box>
				<Box mb={-1}>
					<h3>מידע כללי על המסעדה</h3>
				</Box>

				<h5>כשרות</h5>
				<FormInputSelect
					control={control}
					rules={{ required: true }}
					name="kosherLevel"
					placeholder="בחירת סוג כשרות"
					options={KOSHER_OPTIONS_LIST}
					isClearable={true}
					isSearchable={false}
				/>

				<h5>משלוחים</h5>
				<Box mb={-1}>
					<Controller
						control={control}
						name="hasDelivery"
						as={
							<RadioGroup>
								<Box>
									<FormControlLabel
										value={'false'}
										control={<Radio color="primary" size="small" />}
										label="אין שירות משלוחים"
									/>
								</Box>
								<Box mt={-1}>
									<FormControlLabel
										value={'true'}
										control={<Radio color="primary" size="small" />}
										label="יש שירות משלוחים"
									/>
								</Box>
							</RadioGroup>
						}
					/>
				</Box>
				<AnimatePresence exitBeforeEnter>
					{hasDelivery && hasDelivery === 'true' && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							<FormInputText
								matInput
								error={errors.deliveryLink}
								name="deliveryLink"
								ref={register({ pattern: getValidationPattern(deliveryValue) })}
								placeholder="לינק לאתר או מספר טלפון למשלוחים"
							/>
						</motion.div>
					)}
				</AnimatePresence>

				{errors.deliveryLink && (
					<span className="errorMsg">יש להזין אתר או טלפון תקין</span>
				)}

				<h5>הזמנות</h5>
				<Box mt={1}>
					{ORDER_METHODS_LIST.map(({ value, label }) => (
						<OrderMethodInput
							key={value}
							name={value}
							label={label}
							register={register}
							defaultValue={
								placeToEdit &&
								placeToEdit.orderMethods[value] &&
								placeToEdit.orderMethods[value].delivery &&
								true
							}
							defaultLink={
								placeToEdit &&
								placeToEdit.orderMethods[value].url &&
								placeToEdit.orderMethods[value].url
							}
							errors={errors}
						/>
					))}
				</Box>
				<SenderDetailsForm register={register} errors={errors} control={control} />
			</div>
			<span style={{ fontSize: 'small', color: 'red' }}>{errorMsg}</span>
			<FormButtonFooter
				handleSubmit={() => handleSubmit(onSubmit)()}
				handleCancel={() => handleCloseDialog()}
				disabled={!formState.isValid || !placePosition}
				loading={isLoading}
			/>
		</form>
	);
};

export default React.memo(UpdatePlaceForm);
