import * as React from 'react';
import { Button, styled } from '@material-ui/core';

const ROOT_STYLE: React.CSSProperties = {
	position: 'fixed',
	display: 'flex',
	justifyContent: 'center',
	zIndex: 1200,
	bottom: 10,
	right: '50%',
	transform: 'translateX(50%)',
};

const StyledButton = styled(Button)({
	background: 'black',
	border: 0,
	borderRadius: 3,
	boxShadow: '0 3px 9px 0 #0000003d',
	color: 'white',
	fontWeight: 600,
	fontSize: '1.3em',
	'&:hover': { backgroundColor: 'black' },
});

export interface FloatButtonProps {
	children: React.ReactNode;
	[x: string]: any;
}

const FloatButton: React.FC<FloatButtonProps> = ({ children, ...props }) => {
	return (
		<div style={ROOT_STYLE}>
			<StyledButton {...props} variant="contained" color="primary">
				{children}
			</StyledButton>
		</div>
	);
};

export default React.memo(FloatButton);
