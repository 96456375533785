import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import FormInputText from 'components/forms/FormInput/FormInputText';
import { AnimatePresence, motion } from 'framer-motion';

const OrderMethodInput = ({ name, label, defaultValue, defaultLink, register, errors }) => {
	const [checked, setChecked] = useState(defaultValue ? defaultValue : false);

	return (
		<>
			<Box m={-1} mr={0.5}>
				<FormControlLabel
					name={name}
					inputRef={register}
					control={
						<Checkbox
							color="primary"
							size="small"
							checked={checked}
							onChange={(e) => setChecked(e.target.checked)}
						/>
					}
					label={label}
				/>
			</Box>
			<AnimatePresence exitBeforeEnter>
				{checked && name !== 'cibus' && (
					<motion.div
						initial={{ marginBottom: 10, opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<FormInputText
							name={`${name}Link`}
							defaultValue={defaultLink}
							inputDir="ltr"
							ref={register({
								pattern: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i,
							})}
							placeholder={`קישור לעמוד ${label} (אופציונלי)`}
						/>
						{errors[`${name}Link`] && (
							<span className="errorMsg">יש להזין כתובת אתר תקינה</span>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default OrderMethodInput;
