export function distinct(array, func) {
	const map = new Map();

	array.forEach(element => {
		let key = element;
		if (func) {
			key = func(element);
			if (!key) throw new Error('no key for element ' + element);
		}

		map.set(key, element);
	});

	return Array.from(map.values());
};