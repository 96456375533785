import { createMuiTheme } from '@material-ui/core';

export const QuestTheme = createMuiTheme(
	{
		palette: {
			primary: { main: '#ffbf00' },
			secondary: { main: '#E60000' }
		},
		spacing: 10,
		typography: {
			fontSize: 13,
			button: {
				fontSize: 16
			},
			fontFamily: [
				'Assistant',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'"Helvetica Neue"',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		props: {
			MuiButtonBase: {
				disableRipple: true
			},
		},
		overrides: {
			MuiInputBase: {
				input: {
					padding: 'none',
				}
			},
			MuiButtonBase: {
				root: {
					fontFamily:
						'Assistant'
				}
			},
			MuiButton: {
				containedPrimary: {
					boxShadow: 'none', fontWeight: 600, borderRadius: 3, '&:hover': {
						backgroundColor: '#ffbf00', boxShadow: '0 2px 6px 0 #00000025'
					}
				}
			},
			MuiPaper: { root: { backgroundColor: 'var(--background-color)' } },
			MuiOutlinedInput: {
				root: {
					borderRadius: 0,
					'&:focus $notchedOutline': {
						border: 'none'
					},
					'&:hover $notchedOutline': {
						borderColor: 'none'
					}
				}
			},
			MuiFormControlLabel: { root: { marginRight: 'none' } },
			MuiChip: { deleteIcon: { position: 'relative', left: 8, } },
			MuiRating: { root: { top: '2px' }, sizeLarge: { fontSize: 'xx-large' } },
			MuiGrid: { item: { boxSizing: 'content-box' } },
			MuiAccordion: {
				root: {
					fontSize: 14,
					border: 'none', boxShadow: 'none', '&:before': {
						display: 'none',
					}
				}
			},
			MuiAccordionSummary: {
				root: {
					minHeight: 0,
					'&$expanded': {
						minHeight: 0,
					}
				},
				content: {
					margin: '10px 0',
					'&$expanded': {
						margin: 0,
					}
				},
				expandIcon: { margin: 0, padding: 0 }
			},
			MuiAccordionDetails: {
				root: {
					textAlign: 'start',
					// opacity: 0.75,
					paddingBottom: 10
				}
			},
			MuiAccordionActions: { root: { padding: '5px 14px' } }
		}
	}
);