import * as React from 'react';

interface StageStepperProps {
	children: React.ReactNode[];
	step: number;
}

const StageStepper: React.FC<StageStepperProps> = ({ children, step }) => {
	const stepperChildren = React.Children.toArray(children);
	return <>{stepperChildren[step]}</>;
};

export default React.memo(StageStepper);
