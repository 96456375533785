import * as React from 'react';
import { ReactComponent as BurgerMenuIcon } from 'assets/icons/icons-menu.svg';
import { ReactComponent as ReturnArrow } from 'assets/buttons/arrow-return-white.svg';
import SearchIcon from 'assets/buttons/search.svg';
import { UserContext } from 'context/UserProvider';
import {
	Badge,
	Box,
	Button,
	ListItem,
	makeStyles,
	SwipeableDrawer,
	withStyles,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import UserMenuSection from './drawer/UserMenuSection';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import { MealContext } from 'context/ContextProvider';
import { AnimatePresence, motion } from 'framer-motion';
import Meal, { MealContextType } from 'types/meal';
import ShareIcon from '@material-ui/icons/Share';
import FiltersColumn from 'components/sidebar/new/FiltersColumn/FiltersColumn';
import LogoImg from 'assets/quest-logo.png';
import { isUserRole } from 'utils/functions.util';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles({
	itemTitle: { fontWeight: 'normal', fontSize: 26, margin: 0 },
	itemDesc: { marginBottom: 5, marginTop: 3, fontSize: 16, color: '#52555c' },
	drawerPaper: {
		width: '100%',
		backgroundColor: '#fffdf8',
		height: '100%',
	},
	closeButton: {
		position: 'relative',
		top: -8,
		right: -5,
		'&:hover': { cursor: 'pointer' },
	},
});

const StyledListItem: any = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
		alignItems: 'flex-start',
		color: (props: any) => (props.disabled ? 'gray' : 'black'),
	},
	divider: {
		borderColor: 'rgba(0,0,0,.2)',
	},
})(ListItem);
interface MobileTopBarProps {
	versionNum: string;
}
const MobileTopBar: React.FC<MobileTopBarProps> = ({ versionNum }) => {
	const { setOpenDialog } = React.useContext<DialogContextType>(DialogContext);
	const { checkFilters, searchQuery } = React.useContext<SearchContextType>(SearchContext);
	const { activeUser, favoriteItem } = React.useContext<any>(UserContext);
	const { activeMeal, setActiveMeal } = React.useContext<MealContextType>(MealContext);
	const [openDrawer, setOpenDrawer] = React.useState<string | null>(null);
	const classes = useStyles();
	const history = useHistory();
	const { profileId } = useParams<any>();

	const meal: Meal | null | undefined = activeMeal !== 'not_found' ? activeMeal : null;

	const LIST_ITEMS = [
		{
			title: 'הוספת מסעדה',
			desc: 'בעלי מסעדות או משתמשים התורמים לקהילה - שימו עצמכם על המפה',
			onClick: () => {
				setOpenDialog('place');
				setOpenDrawer(null);
			},
		},
		{
			title: 'הוספת מנה',
			desc: 'אין קווסט בלי מנות! מצאת משהו שחסר? עדכנו אותנו',
			onClick: () => {
				setOpenDialog('meal');
				setOpenDrawer(null);
			},
		},
		{
			title: 'יצירת קשר',
			desc: 'שיתוף פעולה, תרומה לקהילה, אוזן קשבת',
			onClick: () => {
				window.location.assign('mailto:questburger1@gmail.com');
				setOpenDrawer(null);
			},
		},

		{
			title: 'Admin Panel',
			desc: 'פה עושים דברים סודיים',
			onClick: () => {
				history.push('/adminpanel');
				setOpenDrawer(null);
			},
		},
	];

	const [isFav, setFav] = React.useState<boolean>(
		activeUser && activeMeal !== 'not_found' && activeMeal?.isFavorite ? true : false
	);

	const favoriteMeal = async (mealId: string) => {
		const result = await favoriteItem(mealId, isFav, 'Meal');
		if (result && result === 'success') {
			setActiveMeal({ ...(activeMeal as Meal), isFavorite: !isFav });
			setFav((currnetState) => !currnetState);
		}
	};

	const pageTitle = meal
		? `קווסטבורגר - ${meal.title} (${meal.place.title || meal.place})`
		: profileId
		? 'עמוד משתמש בקווסטבורגר'
		: undefined;
	const shareText = meal
		? `רציתי לשתף אותך במנה שמצאתי: ${meal?.title}`
		: profileId
		? 'רציתי לשתף אותך בפרופיל שמצאתי:'
		: undefined;

	const shareData = {
		title: pageTitle,
		text: shareText,
		url: history.location.pathname,
	};

	const sharePage = async () => {
		if (navigator.share) {
			navigator.share(shareData).catch((err) => {
				console.log('Sharing failed: ', err.message);
			});
		} else {
			console.log('web share not supported');
		}
	};

	return (
		<div className="topbarWrapper">
			<div className="barContainter">
				<AnimatePresence exitBeforeEnter initial={false}>
					<Switch location={history.location} key={history.location.pathname}>
						<Route exact path={['/']}>
							<motion.div
								initial={{ opacity: 0, width: '100%' }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="topBarSection"
							>
								<Box mr={1} mt={0.5}>
									<BurgerMenuIcon
										className="paddedBtn-left"
										onClick={() => setOpenDrawer('menu')}
									/>
								</Box>
								<SwipeableDrawer
									anchor={'right'}
									classes={{ paper: classes.drawerPaper }}
									open={openDrawer === 'menu'}
									onClose={() => setOpenDrawer(null)}
									onOpen={() => setOpenDrawer('menu')}
								>
									<Box
										p={2}
										display="flex"
										flexDirection="column"
										dir="rtl"
										overflow="auto"
									>
										<CloseRounded
											className={classes.closeButton}
											fontSize="large"
											onClick={() => setOpenDrawer(null)}
										/>
										<UserMenuSection
											user={activeUser}
											afterClickEvent={() => setOpenDrawer(null)}
										/>
										{LIST_ITEMS.map((item, index) => {
											if (
												item.title === 'Admin Panel' &&
												!activeUser &&
												!isUserRole(activeUser, 'admin')
											) {
												return null;
											} else
												return (
													<StyledListItem
														disabled={!item.onClick}
														divider={
															index < LIST_ITEMS.length - 1 && true
														}
														key={index}
														onClick={item.onClick || null}
													>
														<h2 className={classes.itemTitle}>
															{item.title}
														</h2>
														<p className={classes.itemDesc}>
															{item.desc}
														</p>
													</StyledListItem>
												);
										})}
										<span
											className="smallLink"
											style={{
												fontSize: 15,
												position: 'absolute',
												bottom: 40,
											}}
											onClick={() => {
												history.push('/terms');
												setOpenDrawer(null);
											}}
										>
											תנאי השימוש באתר קווסט בורגר
										</span>
										<div
											dir="ltr"
											style={{
												fontSize: 13,
												position: 'fixed',
												bottom: 5,
												right: '50%',
												transform: 'translateX(50%)',
												color: '#52555c',
											}}
										>
											{`© QuestBurger Version ${versionNum}`}
										</div>
									</Box>
								</SwipeableDrawer>
								<div className="logoWrapper" onClick={() => history.push('/')}>
									<img src={LogoImg} alt="QuestBurger Logo" width={28} />
									<h3 className="logoImage">קווסט בורגר</h3>
								</div>
								<InfoOutlinedIcon
									fontSize="small"
									className={'infoIcon'}
									onClick={() => setOpenDialog('welcome')}
								/>
								<div
									className="paddedBtn-right"
									onClick={() => setOpenDrawer('search')}
								>
									<Badge
										color="primary"
										variant="dot"
										overlap="circle"
										invisible={!checkFilters() && !searchQuery}
									>
										<img src={SearchIcon} width={22} className="barsMenu" />
									</Badge>
								</div>
								<SwipeableDrawer
									anchor={'left'}
									classes={{ paper: classes.drawerPaper }}
									open={openDrawer === 'search'}
									onClose={() => setOpenDrawer(null)}
									onOpen={() => setOpenDrawer('search')}
								>
									<Box p={'0 10px'} dir="rtl" overflow="hidden">
										<FiltersColumn closeDrawer={() => setOpenDrawer(null)} />
										<Button
											color="primary"
											onClick={() => setOpenDrawer(null)}
											variant="contained"
											style={{
												position: 'fixed',
												width: '93vw',
												bottom: 15,
												right: '50%',
												transform: 'translateX(50%)',
											}}
										>
											חיפוש
										</Button>
									</Box>
								</SwipeableDrawer>
							</motion.div>
						</Route>
						<Route
							exact
							path={['/meal/:mealId', '/terms', '/profile/:profileId', '/adminpanel']}
						>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="topBarSection"
							>
								<ReturnArrow
									className={'paddedBtn-left'}
									onClick={() =>
										history.length > 1 ? history.goBack() : history.push('/')
									}
								/>
								<div className="flexGroup">
									<ShareIcon className={'paddedBtn-right'} onClick={sharePage} />
									{/* <motion.div
										whileHover={{ scale: 1.2 }}
										whileTap={{ scale: 0.9 }}
									>
										{isFav ? (
											<FavoriteIcon
												color="primary"
												onClick={() => favotiveMeal(meal?._id || '')}
											/>
										) : (
											<FavoriteBorderIcon
												onClick={() => favotiveMeal(meal?._id || '')}
											/>
										)}
									</motion.div> */}
								</div>
							</motion.div>
						</Route>
					</Switch>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default React.memo(MobileTopBar);
