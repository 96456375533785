import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import s from './SearchBoxStyle.module.scss';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import CloseIcon from '@material-ui/icons/Close';

export interface SearchBoxProps {
	[x: string]: any;
}

const SearchBox: React.FC<SearchBoxProps> = (props) => {
	const { searchQuery, setSearchQuery } = React.useContext<SearchContextType>(SearchContext);

	return (
		<div className={s.searchContainer}>
			<input
				{...props}
				type="text"
				className={s.searchBox}
				style={{
					color: searchQuery ? 'var(--main-color)' : 'black',
					fontWeight: searchQuery ? 'bold' : 'normal',
				}}
				onChange={(e) => setSearchQuery(e.target.value)}
				value={searchQuery}
			/>
			<FontAwesomeIcon className={s.searchIcon} icon={faSearch} />
			<CloseIcon
				className={s.cancelBtn}
				onClick={() => setSearchQuery('')}
				fontSize="small"
			/>
		</div>
	);
};

export default SearchBox;
