import * as React from 'react';
import s from './WelcomeDialogStyle.module.scss';
import { GEO_AREA_LIST } from 'utils/values.util';
import { Button, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import QuestLogo from 'assets/quest-logo-symbol.svg';
export interface WelcomeDialogProps {
	closeDialog(): void;
}

const WelcomeDialog: React.FC<WelcomeDialogProps> = ({ closeDialog }) => {
	const [selected, setSelected] = React.useState<string | null>(GEO_AREA_LIST[1].value);
	const { setFilters } = React.useContext<SearchContextType>(SearchContext);

	const handleClick = () => {
		if (selected !== 'all') {
			setFilters((current) => ({ ...current, zone: selected }));
		}
		closeDialog();
	};

	return (
		<div className={s.root}>
			<div className={s.contentWrapper}>
				<img src={QuestLogo} width={50} />
				<h3 className={s.title}>ברוכים הבאים לקווסט בורגר</h3>
				<p className={s.pText}>
					הגעת לרשת חברתית חדשה לדירוג, שיתוף ומציאת המבורגרים מגניבים, טעימים ומעניינים.
					הפוקוס של הקווסט הוא
					<strong> המנה עצמה </strong>
					ומרכיביה, כך שתוכלו למצוא כל פעם את הארוחה המושלמת הבאה בלי להתאמץ.
				</p>
				<span className={s.pTextTitle}>ומה עושים פה בעצם?</span>
				<p className={s.pText}>
					מגלים המבורגרים, מדרגים, כותבים ביקורות, משתפים את החוויה עם החברים והקהילה
					ועוזרים לפתח את הקווסט על ידי הוספת מידע אמין ותמונות שוות.
				</p>
				<h4 className={s.secondTitle}>אני רוצה לחפש באזור:</h4>
				<RadioGroup
					className={s.btnsWrapper}
					aria-label="geo-focus"
					name="geo-focus"
					value={selected}
					onChange={(e) => setSelected(e.target.value)}
				>
					{GEO_AREA_LIST.map((item, index) => (
						<FormControlLabel
							key={index}
							className={`${s.geoBtn} ${
								selected === item.value ? s.selectedBtn : ''
							}`}
							value={item.value}
							control={
								<Radio
									icon={<></>}
									className={s.radio}
									checkedIcon={<></>}
									color="primary"
								/>
							}
							label={item.label}
						/>
					))}
				</RadioGroup>
				<Button
					onClick={handleClick}
					type="submit"
					className={s.submitBtn}
					variant="contained"
					color="primary"
				>
					חפשו לי המבורגר!
				</Button>
			</div>
		</div>
	);
};

export default React.memo(WelcomeDialog);
