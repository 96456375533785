import * as React from 'react';
import UserImg from 'components/general/user/UserImg/UserImg';
import User from 'types/user';
import s from './ReviewPreviewStyle.module.scss';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

interface ReviewPreviewProps {
	reviewer: User;
	reviewCount: number;
}

const ReviewPreview: React.FC<ReviewPreviewProps> = ({
	reviewer,
	reviewCount,
}) => {
	const revNumber: number = reviewCount - 1;
	return (
		<div className={s.root}>
			<UserImg user={reviewer} size={20} />
			<div className={s.names}>
				<span className={s.userName}>{reviewer.firstName}</span>
				{revNumber > 0 ? (
					<span>{`ועוד ${revNumber} אנשים חוו דעה`}</span>
				) : (
					<span>חווה דעה על מנה זו</span>
				)}
				<ChatBubbleIcon
					style={{ color: 'var(--steel-color)' }}
					fontSize={'small'}
					className={s.icon}
				/>
			</div>
		</div>
	);
};

export default React.memo(ReviewPreview);
