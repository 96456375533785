import API from './request-mgr.service';
import BaseService from './base.service';
import User from 'types/user';
import { Review } from 'types/meal';

export default class UsersService extends BaseService {
	static async loginWithGoogle(tokenId: string): Promise<any> {
		return API.post('users/googleLogin', { tokenId: tokenId });
	}

	static async loginWithFacebook(tokenId: string): Promise<any> {
		return API.post('users/facebookLogin', { tokenId: tokenId });
	}

	static async update(id: string, user: unknown): Promise<User> {
		return API.patch(`users/${id}`, user);
	}

	static async delete(id: string): Promise<unknown> {
		return API.delete(`users/${id}`);
	}

	static async uploadPhoto(userId: string, images: FormData): Promise<User> {
		const headers = { 'Content-Type': 'multipart/form-data' };
		return API.post(`users/${userId}/uploadProfilePhoto`, images, { headers });
	}

	static async inviteByEmail(email: string): Promise<unknown> {
		return API.post('users/registerUser', { email: email, assignRoles: ['beta-tester'] });
	}

	static async registerForAlpha(email: string): Promise<unknown> {
		return API.post('users/registerForAlpha', { email: email });
	}

	static async getAll(skip?: number, limit?: number): Promise<User[]> {
		return API.get('users', { params: { skip: skip || 0, limit: limit || 200 } });
	}

	static async getMe(userToken: string): Promise<User> {
		const headers = { Authorization: `Bearer ${userToken}` };
		return API.get('users/me', { headers });
	}

	static async getUserById(id: string): Promise<User> {
		return API.get(`users/${id}`);
	}

	//TOFIX: enable cancelToken for requests as a general solution
	static async getReviewsById(
		userId: string,
		populate?: unknown,
		cancelToken?: any
	): Promise<Review[]> {
		return API.get(`users/${userId}/reviews`, {
			params: { populate: `${populate}` },
			cancelToken: cancelToken,
		});
	}

	static async getFavoritesById(
		userId: string,
		populate?: unknown,
		cancelToken?: any
	): Promise<Array<any>> {
		return API.get(`users/${userId}/favorites`, {
			params: { populate: populate },
			cancelToken: cancelToken,
		});
	}

	static async addToFavorites(
		userId: string,
		itemId: string,
		itemType: string,
		populate?: any
	): Promise<unknown> {
		return API.post(
			`users/${userId}/favorites`,
			{ itemType: itemType, itemId: itemId },
			{ params: populate }
		);
	}

	static async removeFavorite(userId: string, itemId: string): Promise<unknown> {
		return API.delete(`users/${userId}/favorites/${itemId}`);
	}
}
