import * as React from 'react';
import MainFeed from 'components/sidebar/new/MainFeed/MainFeed';
import FloatButton from './FloatButton';
import { useRouteMatch } from 'react-router-dom';
import { motion } from 'framer-motion';
import { SearchContext, SearchContextType } from 'context/SearchProvider';

const showMapVarients = {
	hidden: { y: '90vh', opacity: 0 },
	shown: { y: 0, opacity: 1 },
};

const MobileSideBar: React.FC = () => {
	const isHome = useRouteMatch({ path: '/', exact: true, strict: true });
	const { isSearching, searchFilters, searchQuery } = React.useContext<SearchContextType>(
		SearchContext
	);
	const [showMap, setShowMap] = React.useState<boolean>(
		window.sessionStorage.getItem('qb_m_ms') === 'true' || false
	);

	React.useEffect(() => {
		if (
			(isSearching && !searchFilters?.geo) ||
			(searchFilters?.geo && searchQuery.length !== 0)
		) {
			setShowMap(false);
			window.sessionStorage.setItem('qb_m_ms', 'false');
		}
	}, [isSearching]);

	return (
		<div>
			{isHome && (
				<FloatButton
					onClick={() => {
						setShowMap((currentState) => {
							window.sessionStorage.setItem('qb_m_ms', (!currentState).toString());
							return !currentState;
						});
					}}
				>
					{showMap ? 'תצוגת רשימה' : 'תצוגת מפה'}
				</FloatButton>
			)}
			<motion.div
				className="sideBarContainer"
				transition={{ duration: 0.4 }}
				animate={showMap ? 'hidden' : 'shown'}
				variants={showMapVarients}
				initial={false}
			>
				<MainFeed />
			</motion.div>
		</div>
	);
};

export default React.memo(MobileSideBar);
