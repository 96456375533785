/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, withStyles, IconButton, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { MOBILE_SIZE } from 'utils/values.util';

const styles = (theme) => ({
	root: {
		margin: 0,
	},
	closeButton: {
		position: 'absolute',
		left: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[800],
	},
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, noClose, ...rest }) => {
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...rest}>
			<Typography variant="h6">{children}</Typography>
			{!noClose && (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			)}
		</MuiDialogTitle>
	);
});

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Zoom ref={ref} {...props} />;
// });

const DialogComponent = ({
	title,
	onClose,
	children,
	useDividers = true,
	noClose = false,
	...props
}) => {
	const isMobile = useMediaQuery(MOBILE_SIZE);

	return (
		<Dialog
			dir="rtl"
			maxWidth={props.maxWidth || false}
			fullScreen={isMobile ? true : false}
			onClose={onClose}
			{...props}
		>
			<DialogTitle onClose={onClose} noClose>
				{title}
			</DialogTitle>
			<DialogContent dividers={useDividers}>{children}</DialogContent>
		</Dialog>
	);
};

export default React.memo(DialogComponent);
