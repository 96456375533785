import * as React from 'react';
import s from './ResponsiveMealPageStyle.module.scss';
import { Box, Button, ButtonBase, Grid, Popover } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ScoreStars from 'components/sidebar/new/ScoreStars';
import MealInfoTable from 'components/sidebar/focus/common/MealInfoTable/MealInfoTable';
import ImagePlaceholder from 'components/general/ImagePlaceholder';
import Meal, { MealContextType } from 'types/meal';
import { getImageUrl, roundNumber } from 'utils/functions.util';
import { ReactComponent as StarIcon } from 'assets/score/star-full.svg';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { UserContext, UserContextType } from 'context/UserProvider';
import NoLocationComponent from 'components/sidebar/common/NoLocationComponent';
import { ReactComponent as EditIcon } from 'assets/icons/icon-edit.svg';
import ReviewSection from 'components/pageBody/ReviewSection/ReviewSection';
import ItemTag from 'components/general/ItemTag/ItemTag';
import { KOSHER_OPTIONS_LIST } from 'utils/values.util';
import { MealContext } from 'context/ContextProvider';
import SimilarMealsSection from 'components/sidebar/new/SimilarMealsSection/SimilarMealsSection';
import PlacesService from 'api/services/places.service';
import MealFeedItem from 'components/sidebar/new/MealFeedItem/MealFeedItem';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import ShareIcon from '@material-ui/icons/Share';
import { isMobile } from 'react-device-detect';
import { ReactComponent as AlmondIcon } from 'assets/buttons/almond.svg';
import MealsService from 'api/services/meals.service';
import PlaceInfoSection from 'components/pageBody/PlaceInfoSection/PlaceInfoSection';
import ImageCarousel from 'components/general/ImageCarousel/ImageCarousel';
import UploadImagesMini from 'pages/MealPage/UploadImagesMini/UploadImagesMini';
import { SearchContext, SearchContextType } from 'context/SearchProvider';

enum ViewTabs {
	reviews = 1,
	placeInfo,
}
export interface ResponsiveMealPageProps {
	meal: Meal | 'not_found';
}

const ResponsiveMealPage: React.FC<ResponsiveMealPageProps> = ({ meal }) => {
	const history = useHistory();
	const { activeUser, favoriteItem } = React.useContext<UserContextType>(UserContext);
	const { setOpenDialog } = React.useContext<DialogContextType>(DialogContext);
	const { setEditingMeal } = React.useContext<MealContextType>(MealContext);
	const { setSearchQuery } = React.useContext<SearchContextType>(SearchContext);
	const [placeMeals, setPlaceMeals] = React.useState<Meal[]>([]);
	const [isFav, setFav] = React.useState(meal && (meal as Meal).isFavorite ? true : false);
	const mealImage = meal !== 'not_found' && getImageUrl(meal);
	const similarSectionRef = React.useRef<React.ElementRef<typeof SimilarMealsSection>>(null);
	const pageRootRef = React.useRef<HTMLDivElement>(null);
	const [showScrollBtn, setShowScrollBtn] = React.useState<boolean>(false);
	const [selectedViewTab, setSelectedViewTab] = React.useState<number>(ViewTabs.reviews);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const onScroll = (e: React.SyntheticEvent) => {
		const { scrollHeight, scrollTop, clientHeight } = e.target as HTMLTextAreaElement;
		const bottom = scrollHeight - scrollTop <= clientHeight + 1;
		if (bottom) {
			similarSectionRef.current?.loadMore();
		}
		if (scrollTop > 700) {
			setShowScrollBtn(true);
		} else if (scrollTop < 700) setShowScrollBtn(false);
	};

	const scrollTop = () => {
		pageRootRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const favoriteMeal = async (mealId: string) => {
		const result = await favoriteItem(mealId, isFav, 'Meal');
		if (result && result === 'success') {
			setFav((currnetState) => !currnetState);
		}
	};
	const pageTitle =
		meal !== 'not_found'
			? `${meal.title} (${meal.place.title || meal.place}) - QuestBurger`
			: undefined;

	const shareData = {
		title: pageTitle,
		text: `רציתי לשתף אותך במנה שמצאתי: ${(meal as Meal)?.title}`,
		url: history.location.pathname,
	};
	const sharePage = async () => {
		if (navigator.share) {
			navigator.share(shareData).catch((err) => {
				console.log('Sharing failed: ', err.message);
			});
		} else {
			console.log('web share not supported');
		}
	};
	const deleteMeal = () => {
		const consent = window.confirm('בטוח שרוצה למחוק את המנה המדהימה הזו??').valueOf();
		if (consent && activeUser?.roles.includes('admin') && meal !== 'not_found') {
			MealsService.delete(meal._id)
				.then(() => {
					window.alert('נמחק בהצלחה, ביי ביי');
					history.replace('/');
				})
				.catch((e) => {
					window.alert('תקלה במחיקת המנה');
					console.log(e);
				});
		}
	};

	React.useEffect(() => {
		const fetchSamePlaceMeals = async (placeId: string) => {
			return PlacesService.getPlaceMeals(placeId)
				.then((res) => setPlaceMeals(res.filter((item) => item._id !== (meal as Meal)._id)))
				.catch((e) => console.log(e));
		};
		meal !== 'not_found' && fetchSamePlaceMeals(meal.place._id);
	}, []);

	return (
		<div className={`scrollBar ${s.root}`} ref={pageRootRef} onScroll={onScroll}>
			{meal !== 'not_found' && (
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			)}
			{meal == 'not_found' ? (
				<NoLocationComponent message="נראה שהמנה שניסית לחפש לא קיימת... " />
			) : (
				meal && (
					<>
						<AnimatePresence>
							{!isMobile && (
								<>
									<div className={s.navBar}>
										<Button
											className={s.backBtn}
											onClick={() => history.push('/')}
										>
											{' < חזרה לרשימת ההמבורגרים'}
										</Button>
									</div>
									{showScrollBtn && (
										<motion.div
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
										>
											<Button
												variant="contained"
												className={s.scrollTopBtn}
												onClick={scrollTop}
											>
												<ArrowUpwardIcon />
											</Button>
										</motion.div>
									)}
								</>
							)}
						</AnimatePresence>
						<div className={s.contentWrapper}>
							<Grid container className={s.heroSection}>
								<Grid item xs={12} md={4} className={s.details}>
									<h1 className={s.title}>{meal.title}</h1>
									<h3
										className={s.place}
										onClick={() => setSearchQuery(`"${meal.place.title}"`)}
									>
										מסעדת{' '}
										<span className={s.placeLink}>{meal.place?.title}</span>
									</h3>
									<Box display="flex" flexDirection="row" alignItems="center">
										<span className={s.reviewsCount}>
											{`(${meal.reviewsCount})`}
										</span>
										<Box width={180} mt={0.5}>
											<ScoreStars
												count={meal.activeScore || 0}
												starSize={{ small: 22, large: 22 }}
											/>
										</Box>
										<span className={s.rating}>
											{meal.activeScore
												? roundNumber(meal.activeScore)
												: 'טרם דורג'}
										</span>
									</Box>
									<div className={s.mealInfoWrapper}>
										<Box m={'20px 0 20px 20px'}>
											<MealInfoTable meal={meal} />
										</Box>
										<div className={s.tagList}>
											{meal.vegan !== 'nope' ? (
												<ItemTag label="טבעוני" style="green" />
											) : meal.vegetarian !== 'nope' ? (
												<ItemTag label="צמחוני" style="green" />
											) : (
												<ItemTag label="בשרי" style="red" />
											)}
											<ItemTag
												label={
													KOSHER_OPTIONS_LIST.find(
														(level) =>
															Number(level?.value) ==
															meal.place.kosher
													)?.label || ''
												}
											/>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} md className={s.imageCarusel}>
									{mealImage ? (
										<div className={s.img}>
											<ImageCarousel item={meal} />
										</div>
									) : (
										<ImagePlaceholder size={60} />
									)}
									<Popover
										anchorReference="anchorPosition"
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										anchorPosition={{ top: 30, left: window.innerWidth / 2 }}
										open={Boolean(anchorEl)}
										// anchorEl={anchorEl}
										onClose={() => setAnchorEl(null)}
									>
										<UploadImagesMini
											mealId={meal._id}
											onSuccess={() => setAnchorEl(null)}
										/>
									</Popover>
									{activeUser && (
										<Button
											className={s.addImgBtn}
											dir="ltr"
											variant="contained"
											onClick={(e) => setAnchorEl(e.currentTarget)}
											endIcon={<AddAPhotoIcon />}
										>
											העלאת תמונות
										</Button>
									)}
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={12} md>
									<div className={s.actionSection}>
										<Button
											color="primary"
											dir="ltr"
											variant="contained"
											onClick={() => setOpenDialog('review')}
											endIcon={<StarIcon fill="black" />}
											className={s.actionBtn}
										>
											<span className={s.btnText}>
												{`דרגו את ${meal.title}`}
											</span>
										</Button>
										<Button
											className={`${s.favBtn} ${s.actionBtn}`}
											dir="ltr"
											variant="contained"
											onClick={() => favoriteMeal(meal._id)}
											endIcon={
												isFav ? (
													<FavoriteIcon
														style={{
															color: isFav
																? 'var(--main-color)'
																: 'inherit',
														}}
													/>
												) : (
													<FavoriteBorderIcon />
												)
											}
										>
											<span className={s.btnText}>
												{isFav ? 'הסרה מהאוסף הפרטי' : 'הוספה לאוסף הפרטי'}
											</span>
										</Button>
										{navigator.share && (
											<Button
												className={`${s.favBtn} ${s.actionBtn}`}
												dir="ltr"
												variant="contained"
												onClick={sharePage}
												endIcon={<ShareIcon />}
											>
												<span className={s.btnText}>שיתוף</span>
											</Button>
										)}
										<span
											className={`${s.btnText} smallLink`}
											onClick={() => {
												setEditingMeal(true);
												setOpenDialog('meal');
											}}
										>
											<EditIcon
												style={{ position: 'relative', top: 1, left: 2 }}
											/>
											הצעות לעריכת פרטי המנה
										</span>
										{activeUser?.roles.includes('admin') && (
											<Button
												color="secondary"
												onClick={deleteMeal}
												style={{ marginRight: 40 }}
											>
												<AlmondIcon width={30} height={30} />
												<span className={s.deleteBtnLabel}>מחק מנה</span>
											</Button>
										)}
									</div>
									{isMobile && (
										<div className={s.mobileViewTabs}>
											<ButtonBase
												className={`${s.viewTabBtn} ${
													selectedViewTab === ViewTabs.reviews &&
													s.selectedTab
												}`}
												onClick={() => setSelectedViewTab(ViewTabs.reviews)}
											>
												חוות דעת
											</ButtonBase>
											<ButtonBase
												className={`${s.viewTabBtn} ${
													selectedViewTab === ViewTabs.placeInfo &&
													s.selectedTab
												}`}
												onClick={() =>
													setSelectedViewTab(ViewTabs.placeInfo)
												}
											>
												משלוחים ופרטים
											</ButtonBase>
										</div>
									)}
									<div hidden={selectedViewTab !== ViewTabs.reviews && isMobile}>
										<ReviewSection
											mealId={meal._id}
											onRateClick={() => setOpenDialog('review')}
										/>
										{placeMeals.length > 0 && (
											<div className={s.otherPlaceMeals}>
												<div className={s.sectionTitle}>
													<FastfoodIcon fontSize="small" />
													<h3>{`מנות נוספות של מסעדת ${meal.place.title}`}</h3>
												</div>
												{placeMeals.slice(0, 2).map((meal: Meal) => (
													<MealFeedItem
														key={meal._id}
														meal={meal}
														handleClick={() =>
															history.push(`/meal/${meal._id}`)
														}
													/>
												))}
											</div>
										)}
										<SimilarMealsSection meal={meal} ref={similarSectionRef} />
									</div>
								</Grid>
								{(selectedViewTab === ViewTabs.placeInfo || !isMobile) && (
									<Grid item md={3} xs>
										<PlaceInfoSection meal={meal} />
									</Grid>
								)}
							</Grid>
						</div>
					</>
				)
			)}
		</div>
	);
};

export default React.memo(ResponsiveMealPage);
