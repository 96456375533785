import * as React from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import PlacesService from 'api/services/places.service';
import MealsService from 'api/services/meals.service';
import UserProvider from './UserProvider';
import SearchProvider from './SearchProvider';
import ReactGA from 'react-ga';
import ToastProvider from './ToastProvider';
import Meal from 'types/meal';
import Place from 'types/place';
import SearchService from 'api/services/search.service';
import { AxiosResponse } from 'axios';
import { MealContextType } from 'types/meal';
import ReactPixel from 'react-facebook-pixel';

ReactGA.initialize(process.env.REACT_APP_GA_KEY || 'NOPE', {
	titleCase: false,
	gaOptions: { cookieDomain: 'questburger.com' },
});
const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID || '', undefined, options);

export const PlaceContext = React.createContext({});
PlaceContext.displayName = 'ActivePlaceContext';

export const MealContext = React.createContext<MealContextType>({
	mealsList: [],
	mealsCount: 0,
	loadingMeal: false,
	isEditingMeal: false,
	setEditingMeal: () => undefined,
	setActiveMeal: () => undefined,
	refreshMeal: async () => undefined,
	setMealsCount: () => undefined,
	getMealData: () => undefined,
	getTopMeals: () => undefined,
});
MealContext.displayName = 'ActiveMealContext';

interface ContextProviderProps {
	children: React.ReactNode;
}

const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
	const [currentMealType, setCurrentMealType] = React.useState('burgers');
	const [loadingUser, setLoadingUser] = React.useState(true);

	const [activeMeal, setActiveMeal] = React.useState<Meal | null | 'not_found'>(null);
	const [isEditingMeal, setEditingMeal] = React.useState<boolean>(false);

	const [mealsList, setMealsList] = React.useState<Meal[]>([]);
	const [mealsCount, setMealsCount] = React.useState<number>(0);
	const [loadingMeal, setLoadingMeal] = React.useState<boolean>(true);

	const [activePlace, setActivePlace] = React.useState<Place | null | 'not_found'>(null);
	const [isEditingPlace, setEditingPlace] = React.useState<boolean>(false);
	const [loadingPlace, setLoadingPlace] = React.useState<boolean>(false);

	const location = useLocation();
	const isHome = useRouteMatch({ path: '/', exact: true, strict: true });

	const topListCache = { places: [], meals: [], count: -1 };
	const initialRender = React.useRef(true);

	const refreshPlace = async (placePath: any) => {
		setLoadingPlace(true);
		return PlacesService.findById(placePath.params.placeId)
			.then((res: AxiosResponse<Place>) => {
				const response = (res as unknown) as Place;
				setActivePlace(response);
			})
			.catch((e) => {
				console.error('Failed to find a place: \n', e);
				setActivePlace('not_found');
			})
			.finally(() => setLoadingPlace(false));
	};

	const refreshMeal = async (mealPath: any): Promise<any> => {
		setLoadingMeal(true);
		return MealsService.getMealById(mealPath.params.mealId, 'place')
			.then((res: AxiosResponse<Meal>) => {
				const response = (res as unknown) as Meal;
				setActiveMeal(response);
				setActivePlace(null);
			})
			.catch((e) => {
				console.error('Failed to find a meal: \n', e);
				setActiveMeal('not_found');
			})
			.finally(() => setLoadingMeal(false));
	};

	const updateMealData = (meals: Meal[], count: number) => {
		setMealsList(meals);
		setMealsCount(count);
	};

	const getMealData = async (
		query?: string,
		filters?: { [x: string]: any },
		skip = 0,
		limit?: number
	) => {
		const searchProps = { ...filters, skip: skip, limit: limit || 30 };
		const randToken = window.sessionStorage.getItem('qb_r_t');
		let meals: Meal[] = [],
			count: number = mealsCount || 0;
		if (randToken) {
			searchProps['token'] = randToken;
		}
		await SearchService.searchV2(query, searchProps)
			.then((res: any) => {
				if (res?.token && res?.token !== randToken)
					window.sessionStorage.setItem('qb_r_t', res.token);
				return (meals =
					skip > 0 && mealsList.length !== 0 ? mealsList.concat(res.values) : res.values);
			})
			.then(
				() =>
					skip === 0 &&
					SearchService.searchCount(query, searchProps)
						.then((res: any) => (count = res))
						.catch((e) => console.log(e))
			)
			.catch((e) => console.log(e))
			.finally(() => {
				updateMealData(meals, count);
			});
		return { meals: meals, count: count, newMealsCount: meals.length - mealsList.length };
	};

	const getTopMeals = () => {
		if (topListCache.meals.length === 0 && topListCache.count < 0) {
			setLoadingMeal(true);
			getMealData().then((data: any) => {
				topListCache.meals = data.meals;
				topListCache.count = data.count;
				setLoadingMeal(false);
			});
		} else {
			updateMealData(topListCache.meals, topListCache.count);
		}
		return topListCache;
	};

	React.useEffect(() => {
		function checkCurrentPath() {
			ReactGA.pageview(location.pathname);
			ReactPixel.pageView();
			const checkPlacePath = matchPath<{ placeId: string }>(location.pathname, {
				path: '/place/:placeId',
				exact: true,
			});
			const checkMealPath = matchPath<{ mealId: string }>(location.pathname, {
				path: '/meal/:mealId',
				exact: true,
			});
			if (isHome) {
				setActivePlace(null);
				setActiveMeal(null);
			} else if (
				checkPlacePath &&
				(!activePlace || (activePlace as Place)._id !== checkPlacePath.params.placeId)
			) {
				refreshPlace(checkPlacePath);
			} else if (
				checkMealPath &&
				(!activeMeal || (activeMeal as Meal)._id !== checkMealPath.params.mealId)
			) {
				refreshMeal(checkMealPath);
			}
		}

		if (!loadingUser) {
			if (initialRender.current) {
				getTopMeals();
				initialRender.current = false;
			}
			checkCurrentPath();
		}
	}, [location.pathname, loadingUser]);

	return (
		<ToastProvider>
			<UserProvider loadingUser={loadingUser} setLoadingUser={setLoadingUser}>
				<SearchProvider getTopResults={getTopMeals} getMealData={getMealData}>
					<PlaceContext.Provider
						value={{
							activePlace,
							setActivePlace,
							isEditingPlace,
							setEditingPlace,
							loadingPlace,
							refreshPlace,
						}}
					>
						<MealContext.Provider
							value={{
								mealsList,
								mealsCount,
								activeMeal,
								isEditingMeal,
								setEditingMeal,
								setActiveMeal,
								loadingMeal,
								getTopMeals,
								getMealData,
								refreshMeal,
								currentMealType,
								setCurrentMealType,
								setMealsCount,
							}}
						>
							{children}
						</MealContext.Provider>
					</PlaceContext.Provider>
				</SearchProvider>
			</UserProvider>
		</ToastProvider>
	);
};

export default React.memo(ContextProvider);
