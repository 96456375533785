import * as React from 'react';
import s from './PlaceInfoSectionStyle.module.scss';
import { Box, Tooltip } from '@material-ui/core';
import RaisedLinkButton from 'components/sidebar/focus/common/RaisedLinkButton';
import { ReactComponent as EditIcon } from 'assets/icons/icon-edit.svg';
import GMapsIcon from 'assets/icons/google-maps-icon.svg';
import WazeIcon from 'assets/icons/waze-icon.svg';
import { PlaceContext } from 'context/ContextProvider';
import { ORDER_METHODS_LIST, ValueType } from 'utils/values.util';
import Meal from 'types/meal';
import { getGeoLiteral, openInNewTab } from 'utils/functions.util';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import { GoogleMap, Marker } from '@react-google-maps/api';
import mapStyle from 'components/pageBody/Map/MapComponentStyle';

const mapContainer = { height: 200, width: '100%' };
const mapOptions: google.maps.MapOptions = {
	styles: mapStyle as any,
	disableDefaultUI: true,
	zoomControl: true,
	gestureHandling: 'cooperative',
	zoomControlOptions: { position: 1 },
};

type StyleType = {
	background: string;
	shadow: string;
	blob: string;
};
const orderMethodsStyle: {
	tenbis: StyleType;
	wolt: StyleType;
	cibus: StyleType;
} = {
	tenbis: { background: '#ffeedc', shadow: '#ffcf9d', blob: '#ff9e00' },
	wolt: { background: '#dce9ff', shadow: '#bbd5ff', blob: '#0d00ff' },
	cibus: { background: 'transparent', shadow: '#lightgray', blob: 'black' },
};

export interface PlaceInfoSectionProps {
	meal: Meal;
}

const PlaceInfoSection: React.FC<PlaceInfoSectionProps> = ({ meal }) => {
	const { setActivePlace, setEditingPlace } = React.useContext<any>(PlaceContext);
	const { setOpenDialog } = React.useContext<DialogContextType>(DialogContext);

	const { orderMethods } = meal.place;

	const checkOrderMethods = (): boolean => {
		let result = false;
		Object.entries(orderMethods).map(([key, value]: [string, any]) => {
			if (key === 'self') {
				value?.url && (result = true);
			} else if (value?.url || value?.delivery) {
				result = true;
			}
		});
		return result;
	};

	const NavigateToPlace = (service: 'gMaps' | 'waze') => {
		const { lat, lng } = getGeoLiteral(meal.place.location);
		let navURL: string;
		if (service === 'waze') {
			navURL = `https://waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&zoom=17`;
		} else {
			// navURL = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
			navURL = `https://www.google.com/maps/dir/?api=1&destination=${lat}%2C${lng}`;
		}
		return openInNewTab(navURL);
	};

	return (
		<div className={s.placeSection}>
			<h3 className={s.placeTitle}>הזמינו משלוח</h3>
			{checkOrderMethods() ? (
				<Box width="100%">
					{orderMethods?.self?.url && (
						<Tooltip title={orderMethods.self.url}>
							<div>
								<RaisedLinkButton
									label={'ישירות מהמסעדה'}
									onClick={() => openInNewTab(orderMethods.self.url)}
									disabled={!orderMethods.self.url}
								/>
							</div>
						</Tooltip>
					)}
					{Object.entries(orderMethods).map(
						([key, value]: [string, any], index: number) =>
							key !== 'self' &&
							value.delivery && (
								<RaisedLinkButton
									key={index}
									{...orderMethodsStyle[key]}
									label={
										ORDER_METHODS_LIST.find(
											(item: ValueType) => item.value === key
										)?.label
									}
									helperText={value.url ? 'משלוח' : 'שימוש במסעדה בלבד'}
									disabled={!value.url}
									onClick={() => openInNewTab(value.url)}
								/>
							)
					)}
				</Box>
			) : (
				<Box width="100%">
					<div className={s.placeInfo}>אין מידע על שירות משלוחים</div>
				</Box>
			)}
			<h3 className={s.placeTitle}>כתובת</h3>
			<span className={s.placeInfo}>{meal.place.address}</span>
			<h3 className={s.placeTitle}>אתר</h3>
			{meal.place?.website ? (
				<span
					className={`smallLink ${s.placeInfo} ${s.websiteLink}`}
					onClick={() => openInNewTab(meal.place.website || '')}
				>
					{meal.place.website}
				</span>
			) : (
				<span className={s.placeInfo}>אין כתובת אתר</span>
			)}
			<h3 className={s.placeTitle}>טלפון</h3>
			{meal.place.phoneNumbers[0] ? (
				<span className={s.placeInfo}>{meal.place.phoneNumbers[0]}</span>
			) : (
				<span className={s.placeInfo}>אין מספרי טלפון</span>
			)}
			<h3 className={s.placeTitle}>מיקום</h3>
			<div className={s.placeMap}>
				<GoogleMap
					mapContainerStyle={mapContainer}
					center={getGeoLiteral(meal.place.location)}
					zoom={16}
					options={mapOptions}
				>
					<Marker position={getGeoLiteral(meal.place.location)} draggable={false} />
				</GoogleMap>
			</div>
			<RaisedLinkButton
				label="נווט עם וייז"
				iconUrl={WazeIcon}
				onClick={() => NavigateToPlace('waze')}
			/>
			<RaisedLinkButton
				label="נווט עם גוגל מפות"
				iconUrl={GMapsIcon}
				onClick={() => NavigateToPlace('gMaps')}
			/>
			<Box mt={2} mb={3} width="100%" textAlign="center">
				<span
					className={`${s.btnText} smallLink`}
					onClick={() => {
						setActivePlace(meal.place);
						setEditingPlace(true);
						setOpenDialog('place');
					}}
				>
					<EditIcon
						style={{
							position: 'relative',
							top: 1,
							left: 2,
						}}
					/>
					הצעות לתיקון פרטי המסעדה
				</span>
			</Box>
		</div>
	);
};

export default PlaceInfoSection;
