import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import s from './CheckboxStyle.module.scss';
import { isMobile } from 'react-device-detect';
export interface CheckboxComponentProps {
	label: string;
	onChange(checked: boolean): any;
	textOverflow?: 'limit' | 'free';
	initialState?: boolean;
	controller?: boolean;
	[x: string]: any;
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
	label,
	onChange,
	initialState,
	textOverflow = 'limit',
	controller = 'none',
	...props
}) => {
	const [, setFocused] = useState(false);
	const [checked, setChecked] = useState(initialState || false);

	const focusBorderColor = 'var(--main-color)';
	const backgroundWhenChecked = 'transparent';
	const backgroundWhenNotChecked = 'transparent';

	const onStateChange = (e) => {
		onChange && onChange(e.target.checked);
		controller === 'none' && setChecked(e.target.checked);
	};

	return (
		<label className={`${s.root} ${props.className}`}>
			<input
				type="checkbox"
				checked={controller === 'none' ? checked : (controller as boolean)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onChange={(e) => onStateChange(e)}
				className={s.hiddenCheckBox}
			/>
			<div
				className={s.styledCheckBox}
				style={{
					borderColor:
						controller === 'none'
							? checked
								? focusBorderColor
								: ''
							: controller
							? focusBorderColor
							: '',
					background:
						controller === 'none'
							? checked
								? backgroundWhenChecked
								: backgroundWhenNotChecked
							: controller
							? backgroundWhenChecked
							: backgroundWhenNotChecked,
				}}
			>
				<svg
					className={s.icon}
					style={{
						fill: 'var(--main-color)',
						visibility:
							controller === 'none'
								? checked
									? 'visible'
									: 'hidden'
								: controller
								? 'visible'
								: 'hidden',
					}}
				>
					<rect x="2" y="2" width="6" height="6" />
				</svg>
			</div>
			<Tooltip
				title={
					label.length > (isMobile ? 15 : 25) ? (
						<span style={{ fontSize: 13 }}>{label}</span>
					) : (
						''
					)
				}
				placement="top"
				arrow
			>
				<span
					className={`${s.labelWrapper} ${textOverflow === 'limit' && s.limitTextLength}`}
					style={{
						color:
							controller === 'none'
								? checked
									? 'black'
									: 'var(--steel-color)'
								: controller
								? 'black'
								: 'var(--steel-color)',
						fontWeight:
							controller === 'none'
								? checked
									? 600
									: 'normal'
								: controller
								? 600
								: 'normal',
					}}
				>
					<span className={s.label}>{label || 'Label Text'}</span>
				</span>
			</Tooltip>
		</label>
	);
};

export default CheckboxComponent;
