import * as React from 'react';
import s from './ReviewListStyle.module.scss';
import MealsService from 'api/services/meals.service';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import { Review } from 'types/meal';
import ReviewItem from 'components/pageBody/ReviewSection/ReviewItem/ReviewItem';
import { UserContext } from 'context/UserProvider';

export interface ReviewListProps {
	mealId: string;
	setReviewCount(count: number): any;
}

const ReviewList: React.FC<ReviewListProps> = ({ mealId, setReviewCount }) => {
	const [fetching, setFetching] = React.useState(true);
	const [reviews, setReviews] = React.useState<Review[] | 'error'>([]);
	const { activeUser } = React.useContext<any>(UserContext);

	const fetchReviews = React.useCallback(async () => {
		setFetching(true);
		return MealsService.getReviews(mealId)
			.then((reviews: Review[]) => {
				if (activeUser) {
					MealsService.getReviews(mealId, activeUser._id)
						.then((res) => {
							if (res && res.length > 0) {
								const userReview: Review = res[0];
								const tempList: Review[] = reviews.filter(
									(review: Review) => review._id !== userReview._id
								);
								tempList.unshift(userReview);
								setReviews(tempList);
								setReviewCount(tempList.length || 0);
							} else {
								setReviews(reviews || []);
								setReviewCount(reviews.length || 0);
							}
						})
						.catch((e) => {
							setReviews('error');
							console.log(e);
						});
				} else {
					setReviews(reviews || []);
					setReviewCount(reviews.length || 0);
				}
			})
			.catch((e) => {
				setReviews('error');
				console.log(e);
			})
			.finally(() => setFetching(false));
	}, [mealId]);

	React.useEffect(() => {
		fetchReviews();
	}, [mealId]);

	return (
		<div className={s.root}>
			{fetching && <LoadingOverlay size={15}/>}
			{reviews === 'error' ? (
				<p>
					קרתה תקלה בקבלת התגובות{' '}
					<span className="mainTextLink" onClick={fetchReviews}>
						רענון
					</span>
				</p>
			) : reviews.length > 0 ? (
				reviews.map((review: Review, index: number) => (
					<ReviewItem key={index} review={review} />
				))
			) : (
				<p style={{ fontSize: 14 }}>למנה זו עוד אין חוות דעת, זאת ההזדמנות שלך!</p>
			)}
		</div>
	);
};

export default React.memo(ReviewList);
