import * as React from 'react';
import MealsService from 'api/services/meals.service';
import PlacesService from 'api/services/places.service';
import { distinct } from 'utils/array.util';
import { Helmet } from 'react-helmet';
const AdminPage = React.lazy(() => import('pages/AdminPage/AdminPage'));
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import { MealUpdateReq } from 'types/meal';
import { ToastContext, ToastContextType } from 'context/ToastProvider';
import { PlaceUpdateReq } from 'types/place';

const AdminPageContainer: React.FC = () => {
	const [isLoadingPlaceReqs, setLoadingPlaceReqs] = React.useState(false);
	const [isLoadingMealReqs, setLoadingMealReqs] = React.useState(false);

	const [errorLoadingPlaceReqs, setErrorLoadingPlaceReqs] = React.useState(null);
	const [errorLoadingMealReqs, setErrorLoadingMealReqs] = React.useState(null);

	const [placeReqs, setPlaceReqs] = React.useState<PlaceUpdateReq[]>([]);
	const [mealReqs, setMealReqs] = React.useState<MealUpdateReq[]>([]);

	const { toast, setToast } = React.useContext<ToastContextType>(ToastContext);

	const loadMealReqs = async () => {
		if (isLoadingMealReqs) return;

		setLoadingMealReqs(true);

		return MealsService.getRequests()
			.then((reqs) => {
				setErrorLoadingMealReqs(null);
				reqs.forEach((r) =>
					Object.keys(r.changes || {}).forEach(
						(k) => (r.changes[k].options[0].selected = true)
					)
				);
				setMealReqs(reqs);
				console.log(reqs);
			})
			.catch((e) => {
				setErrorLoadingMealReqs(e);
			})
			.then(() => setLoadingMealReqs(false));
	};

	const loadPlaceReqs = async () => {
		if (isLoadingPlaceReqs) return;

		setLoadingPlaceReqs(true);

		return PlacesService.getRequests()
			.then((reqs) => {
				setErrorLoadingPlaceReqs(null);
				setPlaceReqs(reqs);
				console.log('Places:\n', reqs);
			})
			.catch((e) => {
				setErrorLoadingPlaceReqs(e);
			})
			.then(() => setLoadingPlaceReqs(false));
	};

	const showMessage = (message: string, isError = false) => {
		return setToast({ ...toast, message: message, type: isError ? 'error' : 'success' });
	};

	/**
	 * Select/Unselect a specific change
	 * @param {*} req the request
	 * @param {*} propName the property name
	 * @param {*} change the specific change (null to unselect)
	 */
	const selectPropChange = (req, propName, change) => {
		// TODO: will this update the component?
		req.changes[propName].options.forEach((r) => (r.selected = false));

		req[propName] = change ? change.value : req.before[propName];
		if (change) change.selected = true;
	};

	/**
	 * make a decision for a req object
	 * @param {string} type Meal|Place
	 * @param {*} req a full req object
	 * @param {*} specificChanges (Optional) object of specific changes to apply by property
	 * @param {string} decision approve|reject
	 * @param {boolean} rejectUnselected Will close all unselected changes
	 */
	const makeDecision = (
		type: 'Meal' | 'Place',
		req: any,
		decision: 'approve' | 'reject',
		rejectUnselected: boolean
	) => {
		console.log('type: \n', type);
		console.log('req: \n', req);
		console.log('desicion: \n', decision);
		console.log('rejectUnselected: \n', rejectUnselected);
		const save = decision === 'approve' ? applyChanges(type, req) : Promise.resolve();
		save.then(() => closeReq(type, req, decision, rejectUnselected))
			.then(() => loadMealReqs())
			.catch((e) => console.log(e));
	};

	const applyChanges = async (type, req) => {
		if (!req.before) {
			const service = type === 'Place' ? PlacesService.upsert : MealsService.upsert;
			return service(req).catch((e) => {
				showMessage('יצירה נכשלה', true);
				throw e;
			});
		}

		const changes: any = {};
		Object.keys(req.changes).forEach((k) => {
			const selected = req.changes[k].options.find((c) => c.selected);
			if (!selected) return;
			switch (k) {
				case 'images':
					if (!changes.$push) changes.$push = {};
					changes.$push[k] = { $each: selected.value };
					break;
				default:
					changes[k] = selected.value;
					break;
			}
		});

		const service = type === 'Place' ? PlacesService.update : MealsService.update;
		return service(req._id, changes).catch((e) => {
			showMessage('עדכון נכשל', true);
			throw e;
		});
	};

	const closeReq = async (
		type: 'Meal' | 'Place',
		req: any,
		decision: 'approve' | 'reject',
		rejectUnselected
	) => {
		const service = type === 'Place' ? PlacesService.closeRequests : MealsService.closeRequests;

		if (decision === 'reject') {
			const reqIds = req.requests.map((r) => r._id);
			return service(reqIds, decision)
				.then(() => {
					if (type == 'Place') {
						loadPlaceReqs();
					} else {
						loadMealReqs();
					}
					showMessage('נדחה בהצלחה');
				})
				.catch((e) => {
					showMessage('דחיה נכשלה', true);
					throw e;
				});
		}

		const toApprove = distinct(
			!req.before
				? [req._id]
				: Object.keys(req.changes)
						.map((k) => {
							const selected = req.changes[k].options.find((o) => o.selected);
							return selected ? selected.reqIds : [];
						})
						.flat()
		);

		const res1 = await service(toApprove, 'approve');

		if (!rejectUnselected || !req.requests) return res1;

		const toApproveMap = {};
		toApprove.forEach((ta) => (toApproveMap[ta] = true));
		const toReject = req.requests.map((r) => r._id).filter((id) => !toApproveMap[id]);

		if (toReject.length === 0) return res1;

		const res2 = await service(toReject, 'reject');
		if (type == 'Place') {
			loadPlaceReqs();
		} else {
			loadMealReqs();
		}
		showMessage('אושר בהצלחה');
		return [res1, res2];
	};

	React.useEffect(() => {
		loadMealReqs();
		loadPlaceReqs();
	}, []);

	return (
		<>
			<Helmet>
				<title>Admin Panel - QuestBurger</title>
			</Helmet>
			<React.Suspense fallback={<LoadingOverlay />}>
				<AdminPage mealReqs={mealReqs} placeReqs={placeReqs} makeDecision={makeDecision} />
			</React.Suspense>
		</>
	);
};

export default React.memo(AdminPageContainer);
