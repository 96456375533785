import React, { Component } from 'react';
import './App.scss';
import TopBarComponent from 'components/topbar/TopBarComponent';
import DialogManager from 'context/DialogManager';
import ContextProvider from 'context/ContextProvider';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'pages/routing/PrivateRoute';
import versionConfig from 'config/version.json';
import Div100vh from 'react-div-100vh';
import { Helmet } from 'react-helmet';
import MealPage from 'pages/MealPage/MealPage';
import NoLocationComponent from 'components/sidebar/common/NoLocationComponent';
import MainMapComponent from 'components/pageBody/Map/MainMapComponent';
import MainSideBar from 'components/sidebar/new/MainSideBar';
import AdminPageContainer from 'pages/AdminPage/AdminPageContainer';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
const TermsPage = React.lazy(() => import('pages/TermsPage/TermsPage'));
const DeleteProfileInstructionsPage = React.lazy(() => import('pages/DeleteProfileInstructionsPage/DeleteProfileInstructionsPage'));
const ProfilePageContainer = React.lazy(() => import('pages/ProfilePage/ProfilePageContainer'));

interface AppState {
	isLoading: boolean;
}

class App extends Component<RouteComponentProps, AppState> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}
	componentCleanup() {
		sessionStorage.clear();
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.componentCleanup);
		this.setState({ isLoading: false });
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.componentCleanup);
	}

	render() {
		return (
			<Div100vh>
				<div className="App">
					<ContextProvider>
						<DialogManager appLoading={this.state.isLoading}>
							<TopBarComponent versionNum={versionConfig.version} />
							<Switch>
								<Route exact path="/">
									<Helmet>
										<title>QuestBurger - הקווסט החברתי להמבורגר המושלם!</title>
									</Helmet>
									<MainSideBar />
								</Route>
								<PrivateRoute path="/adminpanel" component={AdminPageContainer} />
								<Route exact path="/meal/:mealId">
									<MealPage />
								</Route>
								<Route exact path="/profile/:profileId">
									<React.Suspense fallback={<LoadingOverlay />}>
										<ProfilePageContainer />
									</React.Suspense>
								</Route>
								<Route exact path="/terms">
									<React.Suspense fallback={<LoadingOverlay />}>
										<TermsPage />
									</React.Suspense>
								</Route>
								<Route exact path="/delete-account">
									<React.Suspense fallback={<LoadingOverlay />}>
										<DeleteProfileInstructionsPage />
									</React.Suspense>
								</Route>
								<Route path="*">
									<NoLocationComponent message="אופס נראה שהגעת לעמוד שלא קיים..." />
								</Route>
							</Switch>
							<MainMapComponent versionNum={versionConfig.version} />
						</DialogManager>
					</ContextProvider>
				</div>
			</Div100vh>
		);
	}
}

export default withRouter(App);
