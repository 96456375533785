import * as React from 'react';
import s from './FilterOptionsListStyle.module.scss';
import { Box } from '@material-ui/core';
import { MealPart } from 'types/meal';
import CheckboxComponent from 'components/general/CheckBox/CheckBoxComponent';
import { SearchContextType, SearchContext } from 'context/SearchProvider';
import { shuffle } from 'lodash';

export interface FilterOptionsListProps {
	title: string;
	options: any[];
	displayLimit?: number;
	onAdded(added: MealPart): any;
	onRemoved(removed: MealPart): any;
	onMoreItemsClick(list: any[]): void;
	initialState?: Set<string>;
	typeId?: string;
}

const FilterOptionsList = React.forwardRef<React.ReactNode, FilterOptionsListProps>(
	(
		{
			title,
			options,
			initialState,
			displayLimit = 5,
			onAdded,
			onRemoved,
			onMoreItemsClick,
			typeId,
		},
		ref
	) => {
		const [selected, setSelected] = React.useState<Set<string>>(initialState || new Set());
		const [displayOptions, setDisplayOptions] = React.useState<MealPart[]>([]);
		const extraOptionsCount = options.length - displayOptions.length;
		const { partFilters } = React.useContext<SearchContextType>(SearchContext);
		const initial = React.useRef<boolean>(true);

		React.useImperativeHandle(ref, () => ({
			clearSelected() {
				setSelected(new Set());
			},
		}));

		const handleChange = (option: any, checked: boolean) => {
			const tempSet = selected;
			if (checked) {
				tempSet.add(option.sharedId);
				setSelected(tempSet);
				onAdded(option);
			} else {
				tempSet.delete(option.sharedId);
				setSelected(tempSet);
				onRemoved(option);
			}
		};

		const checkState = (option: MealPart) => {
			if (partFilters && typeId && partFilters[typeId]?.size > 0) {
				return partFilters[typeId].has(option.sharedId);
			} else return selected.has(option.sharedId);
		};

		React.useEffect(() => {
			if (partFilters && typeId && partFilters[typeId]?.size > 0) {
				const selectedArray = Array.from(partFilters[typeId]);
				const selectedOptions = options.filter((item) =>
					selectedArray.includes(item.sharedId)
				);
				const unSelectedOptions = options.filter(
					(item) => !selectedArray.includes(item.sharedId)
				);
				setDisplayOptions(selectedOptions.concat(unSelectedOptions).slice(0, displayLimit));
			} else if (initial.current) {
				setDisplayOptions(
					options.length > displayLimit
						? shuffle(options).slice(0, displayLimit)
						: options
				);
			}
			initial.current && (initial.current = false);
		}, [partFilters]);

		return (
			<div className={s.root}>
				<p className={s.title}>{title}</p>
				{displayOptions.map((option, index) => (
					<Box key={index} mr={1}>
						<CheckboxComponent
							label={option.name}
							onChange={(checked) => handleChange(option, checked)}
							controller={checkState(option)}
						/>
					</Box>
				))}
				{extraOptionsCount ? (
					<Box className={s.checkboxLabel} onClick={() => onMoreItemsClick(options)}>
						<span className={s.extraOptionsLink}>{`${extraOptionsCount}+`}</span>
					</Box>
				) : null}
			</div>
		);
	}
);

FilterOptionsList.displayName = 'FilterOptionsList';
export default React.memo(FilterOptionsList);
