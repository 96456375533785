import * as React from 'react';
import s from './MealInfoTableStyle.module.scss';
import { Box, Grid } from '@material-ui/core';
import { getMealParts } from 'utils/functions.util';
import { roundNumber } from 'utils/functions.util';
import { ReactComponent as StarIcon } from 'assets/score/star-full.svg';
import Meal, { MealPart } from 'types/meal';
import { isArray } from 'lodash';

const MEAL_PARTS = [
	{
		name: 'לחמניה',
		value: 'bun',
	},
	{
		name: 'קציצה',
		value: 'patty',
	},
	{
		name: 'תוספות',
		value: 'addons',
	},
	{
		name: 'מחיר',
		value: 'valueForMoney',
	},
];

interface MealPartListItemProps {
	title: string;
	details: any;
	rating: number;
}
const MealPartListItem: React.FC<MealPartListItemProps> = ({
	title,
	details,
	rating,
}) => {
	return (
		<Grid container className={s.root}>
			<Grid className={s.title} item xs={2}>
				{title}
			</Grid>
			<Grid className={s.details} item xs={8}>
				<Box ml={'3px'}>{details}</Box>
			</Grid>
			<Grid className={s.rating} item xs={2}>
				{rating && rating !== 0 ? (
					<>
						{roundNumber(rating)}
						<StarIcon className={s.ratingStar} />
					</>
				) : (
					'טרם דורג'
				)}
			</Grid>
		</Grid>
	);
};

interface MealInfoTableProps {
	meal: Meal;
}
const MealInfoTable: React.FC<MealInfoTableProps> = ({ meal }) => {
	function formatMealAddons() {
		const filteredParts: MealPart[] | MealPart = getMealParts(meal, 'addon');
		return (
			filteredParts &&
			isArray(filteredParts) &&
			filteredParts.map((addon, index) => (
				<span className="arrayListItem" key={index}>
					{addon.name}
				</span>
			))
		);
	}

	const getMealDetails = (value: string) => {
		switch (value) {
			case 'valueForMoney':
				if (meal.costRange) return `₪${meal.costRange.min}`;
				break;
			case 'addons':
				return formatMealAddons();
			default:
				return getMealParts(meal, value)[0].name;
		}
	};

	return (
		<Box width="100%" display="flex" flexDirection="column">
			{MEAL_PARTS.map(
				({ name, value }, index) =>
					meal.parts.length > 0 && (
						<MealPartListItem
							key={meal._id + index}
							title={name}
							details={getMealParts(meal, value) && getMealDetails(value)}
							rating={getMealParts(meal, value)[0]?.score || 0}
						/>
					),
			)}
		</Box>
	);
};

export default MealInfoTable;
