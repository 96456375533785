import * as React from 'react';
import mapStyle from 'components/pageBody/Map/MapComponentStyle';
import FormInputText from 'components/forms/FormInput/FormInputText';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import Alert from '@material-ui/lab/Alert';

export interface PlacesAutoCompleteProps {
	inputRef: React.ForwardedRef<HTMLInputElement>;
	handlePlacePicked(x: any): void;
	initialPlace: google.maps.LatLngLiteral;
}
const mapContainer = { height: 200, width: '100%' };

const mapOptions: google.maps.MapOptions = {
	styles: mapStyle as any,
	disableDefaultUI: true,
	zoomControl: true,
	gestureHandling: 'cooperative',
	zoomControlOptions: { position: 1 },
};

const PlacesAutoComplete: React.FC<PlacesAutoCompleteProps> = ({
	inputRef,
	initialPlace,
	handlePlacePicked,
}) => {
	const [mapFocus, setMapFocus] = React.useState({
		center: { lat: 32.0853, lng: 34.7818 },
		zoomLevel: 13,
	});
	const [map, setMap] = React.useState<google.maps.Map<Element>>();

	const [mapBounds, setMapBounds] = React.useState<google.maps.LatLngBounds>();
	const [placePosition, setPlacePosition] = React.useState<google.maps.LatLngLiteral>();
	// const [placeAddress, setPlaceAddress] = React.useState<string>('');
	const [gPlaceLocation, setGPlaceLocation] = React.useState<google.maps.LatLngLiteral>();
	const autocomplete = React.useRef<google.maps.places.Autocomplete>();

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	const onPlaceChanged = () => {
		if (autocomplete.current) {
			try {
				const place = autocomplete.current.getPlace();
				const placeLocation = place.geometry?.location.toJSON();
				setMapFocus({
					center: placeLocation || { lat: 32.0853, lng: 34.7818 },
					zoomLevel: 16,
				});
				setGPlaceLocation(placeLocation);
				setPlacePosition(placeLocation);
				handlePlacePicked(placeLocation);
				//   setPlaceAddress(place.formatted_address.replace(/(.*),.*/, "$1"));
			} catch (e) {
				console.log(e);
			}
		} else {
			console.log('Autocomplete is not loaded yet!');
		}
	};

	const checkPositions = () => {
		const myPosition = placePosition;
		const googlePosition = gPlaceLocation;
		if (!myPosition || !googlePosition) return null;
		return myPosition.lat !== googlePosition.lat || myPosition.lng !== googlePosition.lng;
	};

	React.useEffect(() => {
		if (initialPlace) {
			setPlacePosition(initialPlace);
			setGPlaceLocation(initialPlace);
			setMapFocus({ center: initialPlace, zoomLevel: 16 });
		}
	}, []);

	return (
		<>
			<Autocomplete
				onLoad={(ac) => (autocomplete.current = ac)}
				onPlaceChanged={() => onPlaceChanged()}
				bounds={mapBounds}
				types={['geocode']}
				restrictions={{ country: 'il' }}
			>
				<FormInputText
					label="כתובת"
					name="placeAddress"
					placeholderText="חפש כתובת"
					ref={inputRef}
				/>
			</Autocomplete>
			{placePosition?.lat && (
				<GoogleMap
					mapContainerStyle={mapContainer}
					onLoad={(thisMap) => setMap(thisMap)}
					onTilesLoaded={() => setMapBounds(map?.getBounds() || undefined)}
					center={mapFocus.center}
					zoom={mapFocus.zoomLevel}
					options={mapOptions}
					onClick={(place) => {
						const newPosition = {
							lat: place.latLng.lat(),
							lng: place.latLng.lng(),
						};
						setPlacePosition(newPosition);
						handlePlacePicked(newPosition);
					}}
				>
					<Marker
						position={placePosition}
						draggable={true}
						onDragEnd={(e) => {
							const newPosition = e.latLng.toJSON();
							setPlacePosition(newPosition);
							handlePlacePicked(newPosition);
						}}
					/>
				</GoogleMap>
			)}
			{checkPositions() && (
				<Alert severity="error">שים לב! המיקום במפה אינו תואם את הכתובת שנבחרה!</Alert>
			)}
		</>
	);
};

export default React.forwardRef<HTMLInputElement, PlacesAutoCompleteProps>((props, ref) => (
	<PlacesAutoComplete inputRef={ref} {...props} />
));
