import React from 'react';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core';

const StyledRating = withStyles({
	root: { direction: 'ltr' },
	iconFilled: {
		color: 'var(--main-color)',
	},
	iconEmpty: {
		opacity: 0.7,
		color: '#ffdd81',
	},
})(Rating);
interface QuestRatingProps {
	[x: string]: any;
}
const QuestRating = React.forwardRef<React.RefObject<any>, QuestRatingProps>((props, ref) => {
	return <StyledRating innerRef={ref} {...props} />;
});

QuestRating.displayName = 'QuestRating';

export default React.memo(QuestRating);
