import MealsService from 'api/services/meals.service';
import * as React from 'react';
import Meal from 'types/meal';
import s from './SimilarMealsSectionStyle.module.scss';
import MealFeedItem from 'components/sidebar/new/MealFeedItem/MealFeedItem';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import { Box } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
export interface SimilarMealsSectionProps {
	meal: Meal;
	ref: { loadMore(): void };
}

const SimilarMealsSection = React.forwardRef<{ loadMore(): void }, SimilarMealsSectionProps>(
	({ meal }, ref) => {
		const [similarList, setSimilarList] = React.useState<Meal[]>([]);
		const [hasMoreItems, setHasMoreItems] = React.useState<boolean>(true);
		const history = useHistory();
		const [fetchFailed, setFetchFailed] = React.useState<boolean>(false);

		React.useImperativeHandle(ref, () => ({
			loadMore() {
				hasMoreItems && !fetchFailed && loadMoreItems();
			},
		}));

		const loadMoreItems = (): Promise<any> => {
			const limit = 5;
			return new Promise(() => {
				hasMoreItems &&
					MealsService.getSimilarMeals(meal._id, {
						skip: similarList.length,
						limit: limit,
					})
						.then((res: Meal[]) => {
							setSimilarList((currentList) => currentList.concat(res));
							setHasMoreItems(limit === res.length);
						})
						.catch((e) => {
							console.log(e);
							setFetchFailed(true);
						});
			});
		};

		React.useEffect(() => {
			const fetchMealSuggestions = () => {
				loadMoreItems();
			};
			fetchMealSuggestions();
		}, []);

		return similarList.length > 0 && !fetchFailed ? (
			<div className={s.root}>
				<div className={s.sectionTitle}>
					<ExploreIcon fontSize={'small'} />
					<h3>{`מנות שדומות ל${meal.title}`}</h3>
				</div>
				<div className={s.mealsList}>
					{similarList.map((meal, index) => (
						<MealFeedItem
							meal={meal}
							key={index}
							handleClick={() => history.push(`/meal/${meal._id}`)}
						/>
					))}
					{hasMoreItems && (
						<Box height={40} mt={1} position="relative">
							<LoadingOverlay size={10} />
						</Box>
					)}
				</div>
			</div>
		) : null;
	}
);

SimilarMealsSection.displayName = 'SimilarMealsSection';
export default SimilarMealsSection;
