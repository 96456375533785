import * as React from 'react';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import { MealContext } from 'context/ContextProvider';
import ResponsiveMealPage from 'pages/MealPage/ResponsiveMealPage';
import { UserContext, UserContextType } from 'context/UserProvider';
import { AnimatePresence, motion } from 'framer-motion';

const MealPage: React.FC = () => {
	const { activeMeal, loadingMeal } = React.useContext<any>(MealContext);
	const { loadingUser } = React.useContext<UserContextType>(UserContext);

	return (
		<AnimatePresence exitBeforeEnter>
			{loadingMeal || loadingUser ? (
				<div
					style={{
						height: 'calc(100% - 50px)',
						top: 50,
						position: 'relative',
						width: '100%',
					}}
				>
					<LoadingOverlay />
				</div>
			) : (
				<motion.div
					initial={{
						opacity: 0,
						scale: 1.1,
					}}
					transition={{
						duration: 0.1,
						type: 'spring',
						stiffness: 400,
						damping: 40,
					}}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0, scale: 1.1 }}
				>
					{activeMeal && !loadingUser && <ResponsiveMealPage meal={activeMeal} />}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default React.memo(MealPage);
