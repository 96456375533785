import * as React from 'react';
import MainFeed from 'components/sidebar/new/MainFeed/MainFeed';
import FiltersColumn from 'components/sidebar/new/FiltersColumn/FiltersColumn';
import { Box } from '@material-ui/core';

const DesktopSideBar: React.FC = () => {
	return (
		<div className="sideBarContainer">
			<Box display="flex" flexDirection="row" height="98%">
				<FiltersColumn />
				<MainFeed />
			</Box>
		</div>
	);
};

export default React.memo(DesktopSideBar);
