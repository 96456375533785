import { Slider, withStyles } from '@material-ui/core';

export const QuestSlider = withStyles({
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: '#bfbfbf',
	},
	mark: {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: 'currentColor',
	},
	thumb: {
		backgroundColor: '#fffaeb',
		height: 30,
		width: 30,
		marginLeft: -15,
		marginTop: -15,
		boxShadow:
			'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
	},
})(Slider);
