export type ValueType = {
	value: string;
	label: string;
};
export const ORDER_METHODS_LIST: ValueType[] = [
	{ value: 'wolt', label: 'וולט' },
	{ value: 'tenbis', label: 'תן ביס' },
	{ value: 'cibus', label: 'סיבוס' },
];

export const KOSHER_OPTIONS_LIST: ValueType[] = [
	{ value: '0', label: 'לא כשר' },
	{ value: '1', label: 'כשר עם תעודה' },
	{ value: '2', label: 'כשרות אלטרנטיבית' },
];

export const BUN_OPTIONS: ValueType[] = [
	{ value: 'לחם לבן', label: 'לחם לבן' },
	{ value: 'ללא גלוטן', label: 'ללא גלוטן' },
	{ value: 'אחר', label: 'אחר' },
	{ value: 'ללא', label: 'ללא' },
];

export const GEO_AREA_LIST = [
	{ value: 'all', label: 'כל הארץ' },
	{ value: '600bfc1d97f8ee26553b41c3', label: 'תל אביב' },
	{ value: '600bfc1d97f8ee26553b41c7', label: 'ירושלים' },
	{ value: '600bfc1d97f8ee26553b41c5', label: 'חיפה' },
	{ value: '600c127db8a49b2eb41008be', label: 'באר שבע' },
];

export const FEED_SORTING_OPTIONS = [
	{ value: 'top', label: 'לפי דירוג' },
	{ value: window.sessionStorage.getItem('qb_r_t'), label: 'אקראית' },
];

export const MOBILE_SIZE = '(max-width: 767px)';
