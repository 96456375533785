import * as React from 'react';
import UsersService from 'api/services/users.service';
import API from 'api/services/request-mgr.service';
import { ToastContext, ToastContextType, ToastType } from './ToastProvider';
import User from 'types/user';

export type UserContextType = {
	activeUser: User | null;
	setActiveUser(user: User): void;
	favoriteItem(itemId: string, isFav: boolean, type: string): Promise<any>;
	logUserOut(): void;
	loadingUser: boolean;
	setLoadingUser(state: boolean): void;
};

const localToken = window.localStorage.getItem('qb_last_user');

export const UserContext = React.createContext<UserContextType>({
	activeUser: null,
	setActiveUser: () => undefined,
	favoriteItem: async () => undefined,
	logUserOut: () => undefined,
	loadingUser: false,
	setLoadingUser: () => undefined,
});
UserContext.displayName = 'UserContext';

const favMsg: { [x: string]: ToastType } = {
	addSuccess: { message: 'נוסף לאוסף בהצלחה', type: 'success' },
	addFail: {
		message: 'הוספה לאוסף נכשלה, נסו לרענן את העמוד',
		type: 'error',
	},
	removeSuccess: { message: 'הוסר מהאוסף בהצלחה', type: 'success' },
	removeFail: {
		message: 'הסרה מהאוסף נכשלה, נסו לרענן את העמוד',
		type: 'error',
	},
};

export interface UserProviderProps {
	children: React.ReactNode;
	loadingUser: boolean;
	setLoadingUser(state: boolean): void;
}

const UserProvider: React.FC<UserProviderProps> = ({ children, loadingUser, setLoadingUser }) => {
	const [activeUser, setActiveUser] = React.useState<User | null>(null);
	const { toast, setToast } = React.useContext<ToastContextType>(ToastContext);

	const favoriteItem = async (itemId: string, isFav: boolean, type: string) => {
		if (!activeUser) {
			setToast({
				...toast,
				message: '(: כדי שנוכל לזכור את האוספים שלך צריך להתחבר לחשבון',
				type: 'warning',
			});
			return null;
		} else {
			const serverCall = isFav ? UsersService.removeFavorite : UsersService.addToFavorites;
			return serverCall(activeUser?._id, itemId, !isFav ? type : '')
				.then(() => {
					setToast({
						...toast,
						...favMsg[isFav ? 'removeSuccess' : 'addSuccess'],
					});
					return 'success';
				})
				.catch(() => {
					setToast({
						...toast,
						...favMsg[isFav ? 'removeFail' : 'addFail'],
					});
					return 'fail';
				});
		}
	};

	const logUserOut = () => {
		setActiveUser(null);
		window.localStorage.removeItem('qb_last_user');
		delete API.defaults.headers.common['Authorization'];
	};

	React.useEffect(() => {
		async function findUserByToken(token) {
			setLoadingUser(true);
			await UsersService.getMe(token)
				.then((res) => {
					res.token = token;
					API.defaults.headers.common.Authorization = token;
					return res;
				})
				.then((savedUser) => setActiveUser(savedUser))
				.catch(() => {
					window.localStorage.removeItem('qb_last_user');
				})
				.finally(() => setLoadingUser(false));
		}
		if (localToken) {
			findUserByToken(localToken);
		} else {
			setLoadingUser(false);
		}
	}, []);

	return (
		<UserContext.Provider
			value={{
				activeUser,
				setActiveUser,
				favoriteItem,
				logUserOut,
				loadingUser,
				setLoadingUser,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default React.memo(UserProvider);
