import * as React from 'react';
import s from './ImageDropZoneStyle.module.scss';
import Dropzone from 'react-dropzone';
import { unionBy } from 'lodash';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

export interface ImageDropZoneProps {
	text: React.ReactNode;
	isMulti?: boolean;
	onFileChange(files: File[] | null): void;
	onFilesReject(msg: string): void;
}

const ImageDropZone: React.FC<ImageDropZoneProps> = ({
	text,
	isMulti,
	onFileChange,
	onFilesReject,
}) => {
	const [files, setFiles] = React.useState<File[]>([]);

	const removeImg = (img: File) => {
		setFiles((currentFiles) => {
			const filtered = currentFiles.filter(
				(file) => file.name !== img.name && file.lastModified !== img.lastModified
			);
			onFileChange(filtered);
			return filtered;
		});
	};

	const handleFileChange = (filesArray: File[]) => {
		if (filesArray.length === 0) {
			setFiles([]);
			onFileChange(null);
		} else {
			if (files.length === 0) {
				setFiles(filesArray);
				onFileChange(filesArray);
			} else {
				const newFiles = unionBy(files, filesArray, 'name' && 'lastModified');
				console.log(newFiles);
				setFiles(newFiles);
				onFileChange(newFiles);
			}
		}
	};

	const handleFileReject = (value) => {
		let errorMsg = '';
		value[0].errors.forEach((error) => {
			if (error.code === 'file-invalid-type') {
				errorMsg = errorMsg + 'הפורמט הזה לא נתמך, יש להעלות תמונות בלבד. ';
			} else if (error.code === 'file-too-large') {
				errorMsg = errorMsg + ' הקובץ גדול מדי, יש להעלות תמונה עד 5MB.';
			} else if (error.code === 'too-many-files') {
				errorMsg = errorMsg + 'יותר מדי קבצים, ניתן להעלות עד 4 תמונות בכל פעם';
			}
		});
		return onFilesReject(errorMsg);
	};

	const selectedFiles = files && (
		<div className={s.previewWrapper}>
			{files &&
				Array.isArray(files) &&
				files.map((file, index) => (
					<div
						key={index}
						className={s.previewItem}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<span
							className={s.delBtn}
							onClick={(e) => {
								e.stopPropagation();
								removeImg(file);
							}}
						>
							<CloseRoundedIcon />
						</span>
						<div
							className={s.previewImage}
							style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}
						/>
						<span className={s.previewPath}>
							{(file as any).path} <br /> {(file.size / 1000).toFixed(2)}KB
						</span>
					</div>
				))}
		</div>
	);

	return (
		<Dropzone
			accept={['image/jpeg', 'image/png']}
			multiple={isMulti}
			maxSize={5242880}
			maxFiles={4}
			onDropRejected={(reject) => handleFileReject(reject)}
			onDropAccepted={(files) => handleFileChange(files)}
		>
			{({ getRootProps, getInputProps }) => (
				<div className={s.root} {...getRootProps()}>
					<input {...getInputProps()} />
					<div className={s.filesWrapper}>
						{files && files.length > 0 ? selectedFiles : text}
					</div>
					{files && files.length > 0 && (
						<>
							<span className="smallLink">עוד תמונות</span>
							<span
								style={{ marginBottom: 10 }}
								className="smallLink"
								onClick={(e) => {
									e.stopPropagation();
									handleFileChange([]);
								}}
							>
								איפוס
							</span>
						</>
					)}
				</div>
			)}
		</Dropzone>
	);
};

export default React.memo(ImageDropZone);
