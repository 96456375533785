import * as React from 'react';
import s from './StreetSearchBoxStyle.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Autocomplete } from '@react-google-maps/api';
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import { ButtonBase } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

interface StreetSearchBoxProps {
	mapRef?: google.maps.Map<Element>;
	updateMapFocus(location: any, zoomLevel?: number, searchBounds?: boolean): void;
	clearInput(): void;
}
const StreetSearchBox = React.forwardRef<HTMLInputElement, StreetSearchBoxProps>(
	({ mapRef, updateMapFocus, clearInput }, ref) => {
		const [
			autoComplete,
			setAutoComplete,
		] = React.useState<google.maps.places.Autocomplete | null>(null);

		const focusOnLocation = () => {
			navigator.geolocation.getCurrentPosition((position) => {
				updateMapFocus(
					{
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					17,
					true
				);
			});
		};

		return (
			<div className={s.root}>
				<Autocomplete
					onLoad={(ac) => setAutoComplete(ac as any)}
					onPlaceChanged={() => {
						autoComplete?.getPlace().geometry &&
							updateMapFocus(autoComplete.getPlace().geometry, 17);
					}}
					bounds={mapRef ? (mapRef.getBounds() as google.maps.LatLngBounds) : undefined}
					types={['geocode']}
					restrictions={{ country: 'il' }}
				>
					<>
						{!isMobile && <FontAwesomeIcon className={s.searchIcon} icon={faSearch} />}
						<input
							ref={ref}
							className={s.searchInput}
							placeholder="מקד מפה לפי עיר או רחוב"
						/>
						{isMobile && (
							<FontAwesomeIcon
								className={s.clearIcon}
								icon={faTimes}
								onClick={() => clearInput()}
							/>
						)}
						{/* {!isMobile && ( */}
						<ButtonBase className={s.focusButton} onClick={focusOnLocation}>
							<MyLocationRoundedIcon />
						</ButtonBase>
						{/* )} */}
					</>
				</Autocomplete>
			</div>
		);
	}
);
StreetSearchBox.displayName = 'StreetSearchBox';
export default React.memo(StreetSearchBox);
