import * as React from 'react';
import StarIcon from 'assets/score/star-full.svg';
import HalfStarIcon from 'assets/score/star-half.svg';
import EmptyStarIcon from 'assets/score/star-empty.svg';
import MedalIcon from 'assets/score/medal-full.svg';
import HalfMedalIcon from 'assets/score/medal-half.svg';
import EmptyMedalIcon from 'assets/score/medal-empty.svg';
import { Box } from '@material-ui/core';

const MedalStyle = { 0: EmptyMedalIcon, 0.5: HalfMedalIcon, 1: MedalIcon };
const StarStyle = { 0: EmptyStarIcon, 0.5: HalfStarIcon, 1: StarIcon };

interface ScoreStarsProps {
	count: number;
	rank?: number;
	starSize?: { small: number; large: number };
	starStyle?: string;
	[x: string]: any;
}

const ScoreStars: React.FC<ScoreStarsProps> = ({
	count,
	rank = 0,
	starSize,
	starStyle = 'stars',
	...rest
}) => {
	const sizes =
		starSize || (starStyle === 'medals' ? { small: 26, large: 34 } : { small: 13, large: 16 });
	const hasDecimal = Boolean(count % 1 > 0);
	const baseArray = [0, 0, 0, 0, 0];
	const mapArray = baseArray.fill(1, 0, Math.floor(count));
	if (hasDecimal) {
		mapArray.splice(Math.floor(count), 1, 0.5);
	}
	return (
		<Box display="flex" flexDirection="row-reverse" justifyContent="space-around" {...rest}>
			{mapArray.map((item, index) => (
				<img
					key={`${starStyle}-${index}`}
					src={starStyle === 'medals' ? MedalStyle[item] : StarStyle[item]}
					width={rank === 1 ? sizes.large : sizes.small}
					alt={`${starStyle}`}
				/>
			))}
		</Box>
	);
};

export default ScoreStars;
