import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

export const selectStyles = {
	container: (provided) => ({
		...provided,
		width: '100%',
		fontSize: 12,
	}),
	control: (base, state) => ({
		...base,
		border: state.isFocused ? '1px solid var(--main-color)' : '1px solid #b1b1b1',
		minHeight: '2em',
		cursor: state.isFocused ? 'pointer' : 'default',
		boxShadow: 'none',
		'&:hover': {
			borderColor: state.isFocused ? 'var(--main-color)' : 'default',
		},
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '2em',
		'&:hover': {
			cursor: 'pointer',
		},
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '2em',
		'&:hover': {
			cursor: 'pointer',
		},
	}),
	menu: (provided) => ({
		...provided,
		zIndex: '2',
	}),
	option: (provided, state) => ({
		...provided,
		color: 'black',
		backgroundColor: state.isFocused ? 'var(--secondary-color)' : 'inherit',
		cursor: state.isFocused ? 'pointer' : 'default',
	}),
};

export const hiddenSelectStyle = {
	container: (base) => ({
		...base,
		width: '100%',
		fontSize: 14,
		fontWeight: 600,
	}),
	singleValue: (base) => ({ ...base, color: 'var(--main-color)' }),
	control: (base, state) => ({
		...base,
		// border: state.isFocused ? '1px solid var(--main-color)' : '1px solid #b1b1b1',
		border: 'none',
		minHeight: '2em',
		backgroundColor: 'none',
		cursor: state.isFocused ? 'pointer' : 'default',
		boxShadow: 'none',
		// '&:hover': {
		// 	borderColor: state.isFocused ? 'var(--main-color)' : 'default',
		// },
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '2em',
		padding: '0 6px',
		'&:hover': {
			cursor: 'pointer',
		},
	}),
	menu: (provided) => ({
		...provided,
		zIndex: '2',
		left: 20,
	}),
	option: (provided, state) => ({
		...provided,
		color: 'black',
		backgroundColor: state.isFocused ? 'var(--secondary-color)' : 'inherit',
		cursor: state.isFocused ? 'pointer' : 'default',
	}),
};

export default function FormInputSelect({ control, name, rules, ...props }) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			as={<Select {...props} styles={selectStyles} isRtl={true} />}
		/>
	);
}
