import * as React from 'react';
import s from './MainMapStyle.module.scss';
import { MealContext } from 'context/ContextProvider';
import Meal, { MealContextType } from 'types/meal';
import { groupBy } from 'lodash';
import mapStyle from './MapComponentStyle';
import BlankMarker from 'assets/map/pin-blank.svg';
import GreenMarker from 'assets/map/pin-green.svg';
import MainColorMarker from 'assets/map/pin-regular.svg';
import SelectedMarker from 'assets/map/pin-selected.svg';
import SelectedBlankMarker from 'assets/map/pin-selected-blank.svg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
	GoogleMap,
	InfoBox,
	InfoWindow,
	Marker,
	MarkerClusterer,
	useLoadScript,
} from '@react-google-maps/api';
import { getGeoLiteral, roundNumber } from 'utils/functions.util';
import StreetSearchBox from 'components/pageBody/Map/StreetSearchBox/StreetSearchBox';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import MapMealsPreview from 'components/pageBody/Map/MapMealsPreivew/MapMealsPreview';
import { isMobile } from 'react-device-detect';
import Div100vh from 'react-div-100vh';
import MainButton from 'components/general/MainButton/MainButton';

const mapOptions: google.maps.MapOptions = {
	styles: mapStyle as any,
	disableDefaultUI: true,
	zoomControl: true,
	gestureHandling: 'greedy',
	clickableIcons: false,
	zoomControlOptions: { position: isMobile ? 9 : 6 },
};

const libraries: Libraries = ['places'];

export interface MainMapComponentProps {
	versionNum: string;
}

const MainMapComponent: React.FC<MainMapComponentProps> = ({ versionNum }) => {
	const [mapCenter] = React.useState({
		lat: 32.0853,
		lng: 34.7818,
	});
	const [mapZoom] = React.useState(16);

	const { mealsList } = React.useContext<MealContextType>(MealContext);
	const currentMeals = React.useRef<Meal[]>([]);
	const [placesList, setPlacesList] = React.useState<Record<string, Meal[]>>({});
	const { setFilters, searchFilters, isSearching } = React.useContext<SearchContextType>(
		SearchContext
	);

	const [map, setMap] = React.useState<google.maps.Map<Element>>();
	const [showSeachHereBtn, setShowSearchHereBtn] = React.useState<boolean>(true);
	const streetSearchInputRef = React.useRef<HTMLInputElement>(null);

	const clearStreetInput = () => {
		streetSearchInputRef.current ? (streetSearchInputRef.current.value = '') : null;
	};

	const [openInfoWindow, setOpenInfoWindow] = React.useState<string | null>(null);
	const { setSearchQuery } = React.useContext(SearchContext);
	const history = useHistory();
	const isHome = useRouteMatch({ path: '/', exact: true, strict: true });

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
		language: 'he',
		region: 'IL',
		libraries,
	});

	const mobileContainerStyle: React.CSSProperties = {
		opacity: isHome ? 1 : 0,
		height: 'calc(100vh - 105px)',
		width: '100vw',
		position: 'absolute',
		left: 0,
		top: 50,
	};
	const mapContainerStyle: React.CSSProperties = {
		opacity: isHome ? 1 : 0,
		height: 'calc(100vh - 50px)',
		width: 'calc(100vw - 810px)',
		position: 'absolute',
		left: 0,
		bottom: 0,
	};

	const updateMapFocus = React.useCallback(
		(location, zoomLevel?) => {
			if (location.viewport) {
				map?.fitBounds(location.viewport);
			} else {
				zoomLevel && map?.setZoom(zoomLevel);
				map?.panTo(location);
				if (isMobile) {
					map?.panBy(0, -40);
				}
			}
		},
		[map]
	);

	const handleBoundsChange = React.useCallback(() => {
		!showSeachHereBtn && setShowSearchHereBtn(true);
	}, [searchFilters]);

	const searchInBound = React.useCallback(async () => {
		const bounds = map?.getBounds();
		const toGeoJsonPoly = (bounds: google.maps.LatLngBounds) => {
			const topLeftPoint = [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()];
			const topRightPoint = [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()];
			const bottomRightPoint = [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()];
			const bottomLeftPoint = [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()];
			const polyCoordinates = [
				topLeftPoint,
				topRightPoint,
				bottomRightPoint,
				bottomLeftPoint,
				topLeftPoint,
			];
			return polyCoordinates;
		};
		if (bounds) {
			const geoRequest = {
				type: 'Polygon',
				coordinates: [toGeoJsonPoly(bounds)],
			};
			setFilters((current) => ({ ...current, zone: undefined, geo: geoRequest }));
			setShowSearchHereBtn(false);
			clearStreetInput();
		}
	}, [map]);

	const getMarkerProps = (meals: Meal[], selected?: boolean): { icon: string; color: string } => {
		const length = meals.length;
		let iconUrl: string, labelColor: string;
		if (selected) {
			iconUrl = length === 1 ? SelectedMarker : SelectedBlankMarker;
			labelColor = 'black';
		} else if (length === 1) {
			iconUrl =
				meals[0].vegan !== 'nope' || meals[0].vegetarian !== 'nope'
					? GreenMarker
					: MainColorMarker;
			labelColor = 'black';
		} else {
			iconUrl = BlankMarker;
			labelColor = 'black';
		}
		return { icon: iconUrl, color: labelColor };
	};

	React.useEffect(() => {
		const updatePlaces = () => {
			const places = groupBy(mealsList, (meal) => meal.place._id);
			setPlacesList(places);
			currentMeals.current = mealsList;
		};

		const fitMapBounds = (map: google.maps.Map<Element>) => {
			if (Object.keys(placesList).length > 0) {
				const bounds = new window.google.maps.LatLngBounds();
				Object.entries(placesList).map(([, meals]) =>
					bounds.extend(getGeoLiteral(meals[0].place.location))
				);
				map.fitBounds(bounds);
			}
			setOpenInfoWindow(null);
		};

		mealsList !== currentMeals.current && updatePlaces();
		map && !searchFilters?.geo && fitMapBounds(map);
	}, [mealsList, placesList, map]);

	return isLoaded ? (
		<Div100vh>
			<GoogleMap
				mapContainerStyle={isMobile ? mobileContainerStyle : mapContainerStyle}
				center={mapCenter}
				zoom={mapZoom}
				options={mapOptions}
				onLoad={(thisMap) => {
					setMap(thisMap);
				}}
				onClick={() => openInfoWindow && setOpenInfoWindow(null)}
				onIdle={handleBoundsChange}
			>
				<MarkerClusterer averageCenter>
					{(clusterer) =>
						Object.keys(placesList).length > 0 &&
						Object.entries(placesList).map(([key, meals]) => (
							<Marker
								key={key}
								position={getGeoLiteral(meals[0].place.location)}
								clusterer={clusterer}
								label={{
									text:
										meals.length > 1
											? `${meals.length.toString()}`
											: meals[0].activeScore
											? roundNumber(meals[0].activeScore).toString()
											: '-',
									color: getMarkerProps(meals).color,
									fontWeight: '600',
									fontSize: '12px',
								}}
								onClick={() => {
									updateMapFocus(getGeoLiteral(meals[0].place.location));
									setOpenInfoWindow(key);
								}}
								icon={{
									url: getMarkerProps(meals, openInfoWindow === key).icon,
									scaledSize: new window.google.maps.Size(64, 64),
									origin: new window.google.maps.Point(
										meals.length === 1 ? 0 : -0.5,
										meals.length === 1 ? 0 : -5
									),
									anchor: new window.google.maps.Point(37, 37),
								}}
							>
								<InfoBox
									key={`${meals[0].place._id}-nametag`}
									options={{
										alignBottom: true,
										disableAutoPan: true,
										pixelOffset: new window.google.maps.Size(
											-104,
											meals.length === 1 ? 30 : 40
										),
										maxWidth: 0,
										boxStyle: {
											textAlign: 'center',
											minWidth: '200px',
											padding: '0',
											fontSize: '14px',
											fontWeight: 'bold',
											fontFamily: 'Assistant',
											direction: 'rtl',
											webkitTextStroke: '0.4px white',
											pointerEvents: 'none',
										},
										closeBoxURL: '',
										infoBoxClearance: new window.google.maps.Size(1, 1),
									}}
								>
									<div>
										{meals.length === 1 ? meals[0].title : meals[0].place.title}
									</div>
								</InfoBox>
								{openInfoWindow === key && (
									<InfoWindow
										position={getGeoLiteral(meals[0].place.location)}
										onCloseClick={() => setOpenInfoWindow(null)}
										zIndex={15}
									>
										<div className={s.infoWindow}>
											<div className={s.mealPreviewList}>
												{meals.slice(0, 3).map((meal) => (
													<MapMealsPreview
														key={meal._id}
														meal={meal}
														onMealClick={({ _id }) =>
															history.push(`meal/${_id}`)
														}
														showPlace={meals.length === 1}
													/>
												))}
											</div>
											{meals.length > 1 && (
												<p
													className={`smallLink ${s.mealPlaceName}`}
													onClick={() =>
														setSearchQuery(`"${meals[0].place.title}"`)
													}
												>
													{`כל המנות של מסעדת ${meals[0].place.title} (${meals.length})`}
												</p>
											)}
										</div>
									</InfoWindow>
								)}
							</Marker>
						))
					}
				</MarkerClusterer>
				<StreetSearchBox
					ref={streetSearchInputRef}
					clearInput={clearStreetInput}
					updateMapFocus={updateMapFocus}
					mapRef={map}
				/>
				{((showSeachHereBtn && !openInfoWindow) || isSearching) && (
					<MainButton
						loading={isSearching}
						className={s.searchHereBtn}
						handleClick={searchInBound}
					>
						חפשו לי באזור הזה
					</MainButton>
				)}
				<div className={s.versionWrapper}>
					<span className={s.versionNumber}>{`© QuestBurger Version ${versionNum}`}</span>
				</div>
			</GoogleMap>
		</Div100vh>
	) : (
		<h4>מפה בטעינה...</h4>
	);
};

export default React.memo(MainMapComponent);
