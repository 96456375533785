import * as React from 'react';
import s from './LoginStyle.module.scss';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import { UserContext, UserContextType } from 'context/UserProvider';
import BackgroundImage from 'assets/login-background.svg';
import BackgroundImageMobile from 'assets/login-background-mobile.svg';
import LoadingOverlay from 'components/general/LoadingOverlay/LoadingOverlayComponent';
import UsersService from 'api/services/users.service';
import API from 'api/services/request-mgr.service';
import LoginButton from './buttons/LoginButton';
import ReactGA from 'react-ga';
import { matchPath, useHistory } from 'react-router-dom';
import { MealContext, PlaceContext } from 'context/ContextProvider';
import { MealContextType } from 'types/meal';
import { isMobile, getUA } from 'react-device-detect';

interface LoginComponentProps {
	errorMsg?: React.ReactNode;
	closeOnLogin?: boolean;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ errorMsg, closeOnLogin = true }) => {
	const [isLoading, setLoading] = React.useState<boolean>(false);
	const { setActiveUser } = React.useContext<UserContextType>(UserContext);
	const { refreshMeal } = React.useContext<MealContextType>(MealContext);
	const { refreshPlace } = React.useContext<any>(PlaceContext);
	const { setOpenDialog } = React.useContext<DialogContextType>(DialogContext);
	const [loginError, setLoginError] = React.useState<string | null>(null);
	const mounted = React.useRef<boolean>(true);
	const history = useHistory();

	function isInApp() {
		if (getUA.indexOf('Instagram') > -1) {
			return 'אינסטגרם';
		} else if (getUA.indexOf('FBAN') > -1 || getUA.indexOf('FBAV') > -1) {
			return 'פייסבוק';
		} else return false;
	}

	const refreshSidebar = () => {
		const checkMealPath = matchPath(location.pathname, {
			path: '/meal/:mealId',
			exact: true,
		});
		const checkPlacePath = matchPath(location.pathname, {
			path: '/place/:placeId',
			exact: true,
		});
		checkMealPath && refreshMeal(checkMealPath);
		checkPlacePath && refreshPlace(checkPlacePath);
	};

	const getUser = async (provider, token) => {
		const loginPromise =
			provider === 'google'
				? UsersService.loginWithGoogle(token)
				: UsersService.loginWithFacebook(token);

		await loginPromise
			.then((res) => {
				const userData = {
					...res.user,
					token: res.token,
				};
				API.defaults.headers.common.Authorization = res.token;
				setActiveUser(userData);
				window.localStorage.setItem('qb_last_user', res.token);
				ReactGA.event({
					category: 'User',
					action: 'Logged in',
					label: `User id: ${userData._id}`,
				});
			})
			.catch((error) => {
				console.error('Error logging in to server: \n' + error);
				if (error?.response?.status === 406) {
					setLoginError('מצטערים, הרשמה למשתמשים חדשים סגורה כרגע');
				} else {
					setLoginError(error?.response?.message || 'קרתה תקלה בהתחברות');
				}
				setLoading(false);
				return error;
			});

		return null;
	};

	const loginOAuthUser = async (provider, response) => {
		setLoading(true);
		const token = provider === 'google' ? response.tokenId : response.accessToken;
		if (!token) {
			delete API.defaults.headers.common['Authorization'];
			if (response.status && response.status === 'not_authorized') {
				setLoginError('קרתה תקלה בהתחברות דרך הפייסבוק');
			} else {
				setLoginError('קרתה תקלה בהתחברות, נסה שוב');
			}
			setLoading(false);
			return;
		} else {
			getUser(provider, token);
			refreshSidebar();
		}

		if (mounted.current && closeOnLogin) {
			setLoading(false);
			setOpenDialog(null);
		}
	};

	React.useEffect(() => {
		return () => {
			mounted.current = false;
			setLoading(false);
		};
	}, []);

	return (
		<div
			className={s.loginContainer}
			style={{
				backgroundImage: `url(${isMobile ? BackgroundImageMobile : BackgroundImage})`,
			}}
		>
			{isInApp() && (
				<div className={s.webViewWarn}>
					נראה שהגלישה שלך דרך הדפדפן של {isInApp()}
					<br />
					לצערינו, זה עלול לגרום לבעיות בהתחברות דרך גוגל
					<br />
					ממליצים להעתיק את הכתובת ולהתחבר באפליקציית הדפדפן{' '}
					<span className={s.questLink}> QuestBurger.com</span> (Chrome/Firefox/Safari)
				</div>
			)}
			<h1 className={s.loginTitle}>הצטרפות לקווסט בורגר</h1>
			{errorMsg && <span className={s.loginBlock}>{errorMsg}</span>}
			<div className={s.facebookButton}>
				<FacebookLogin
					appId={process.env.REACT_APP_FACEBOOK_APP_ID}
					fields="name,email,picture"
					disableMobileRedirect={true}
					isMobile={false}
					callback={(response) => loginOAuthUser('facebook', response)}
					render={(renderProps) => (
						<LoginButton
							type="facebook"
							onClick={renderProps.onClick}
							disabled={renderProps.disabled}
						/>
					)}
				/>
			</div>
			<div className={s.googleButton}>
				<GoogleLogin
					clientId={process.env.REACT_APP_GOOGLE_AUTH_TOKEN || ''}
					onSuccess={(response) => loginOAuthUser('google', response)}
					onFailure={(error) =>
						error.error !== 'popup_closed_by_user' &&
						console.log('Google login failed: \n', error)
					}
					render={(renderProps) => (
						<LoginButton
							type="google"
							onClick={renderProps.onClick}
							disabled={renderProps.disabled}
						/>
					)}
					autoLoad={false}
					// cookiePolicy="http://questburger.com"
					// cookiePolicy='single_host_origin'
				/>
			</div>
			{loginError && <span className={`errorMsg ${s.loginError}`}>{loginError}</span>}
			<p className={`smallLink ${s.termsLink}`} onClick={() => history.push('/terms')}>
				הרשמה והתחברות לחשבון בכפוף לתנאי השימוש באתר
			</p>
			{isLoading && <LoadingOverlay />}
		</div>
	);
};

export default LoginComponent;
