import * as React from 'react';
import s from './MealFeedItemStyle.module.scss';
import Meal, { MealPart } from 'types/meal';
import ImageLoader from 'components/general/ImageLoader';
import PlaceholderImage from 'assets/image-placeholder.svg';
import MealFocusCrown from 'components/sidebar/new/MealFeedItem/MealCrown/MealCrown';
import { Grid } from '@material-ui/core';
import { getImageUrl, getMealParts, roundNumber } from 'utils/functions.util';
import ReviewPreview from 'components/sidebar/new/MealFeedItem/ReviewPreview/ReviewPreview';
import ItemTag from 'components/general/ItemTag/ItemTag';
import ScoreStars from 'components/sidebar/new/ScoreStars';
import { isMobile } from 'react-device-detect';
import FavoriteIcon from '@material-ui/icons/Favorite';

export interface MealFeedItemProps {
	meal: Meal;
	handleClick(item: Meal): any;
	style?: React.CSSProperties;
	hideRatings?: boolean;
}

const MealFeedItem: React.FC<MealFeedItemProps> = ({ meal, hideRatings = false, handleClick }) => {
	const mealAddons: MealPart[] = getMealParts(meal, 'addon') as MealPart[];
	const displayAddons: MealPart[] = mealAddons.length > 5 ? mealAddons.slice(0, 5) : mealAddons;
	const extraAddonsCount = mealAddons.length - displayAddons.length;
	const mealImage = getImageUrl(meal);

	return (
		<div className={s.root}>
			<Grid container className={s.paddingWrapper} onClick={() => handleClick(meal)}>
				<Grid item sm={4} xs={12}>
					<div className={s.imageCarousel}>
						{mealImage ? (
							<ImageLoader alt="Meal Image" item={meal} size="medium" />
						) : (
							<img width={54} src={PlaceholderImage} alt="placeholder" />
						)}
					</div>
				</Grid>
				<Grid item sm xs className={s.details}>
					<div>
						{meal.matchingPercent && (
							<span className={s.matchPrecent}>
								{`${Math.round(meal.matchingPercent)}% התאמה לחיפוש`}
							</span>
						)}
						<h3 className={s.title}>{meal.title}</h3>
						<p className={s.place}>
							מסעדת{' '}
							{meal.cachedPlace
								? meal.cachedPlace.title
								: meal.place.title || meal.place}
						</p>
						{isMobile && (
							<div className={s.reviewSection}>
								<span className={s.scoreCount}>{`(${meal.reviewsCount})`}</span>
								<div className={s.starsWrapper}>
									<ScoreStars
										count={meal.activeScore || 0}
										starSize={{ small: 12, large: 12 }}
									/>
								</div>
								<span className={s.score}>
									{meal.activeScore ? roundNumber(meal.activeScore) : '-'}
								</span>
							</div>
						)}
						<div className={s.chipWrapper}>
							{meal.vegan !== 'nope' ? (
								<ItemTag label="טבעוני" style="green" />
							) : meal.vegetarian !== 'nope' ? (
								<ItemTag label="צמחוני" style="green" />
							) : (
								<ItemTag label="בשרי" style="red" />
							)}
							{displayAddons.map((item) => (
								<span key={item._id} className={s.partChip}>
									{item.name}
								</span>
							))}
							{extraAddonsCount > 0 && (
								<span className={s.extraChips}>{`${extraAddonsCount}+`}</span>
							)}
						</div>
					</div>
					{!hideRatings && (
						<>
							{meal.reviewer ? (
								<ReviewPreview
									reviewer={meal.reviewer}
									reviewCount={meal.reviewsCount}
								/>
							) : (
								<div className={s.noReviews}>אין חוות דעת</div>
							)}
						</>
					)}
				</Grid>
				{!isMobile && !hideRatings && (
					<Grid item xs={3}>
						<MealFocusCrown meal={meal} />
					</Grid>
				)}
			</Grid>
			{meal.isFavorite && (
				<div className={s.favBadge}>
					<FavoriteIcon />
				</div>
			)}
		</div>
	);
};

export default React.memo(MealFeedItem);
