import React from 'react';
import { Box, ButtonBase, makeStyles, withStyles } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/arrow-collapse-white.svg';

const StyledButton = withStyles({
	root: {
		width: '100%',
		marginTop: 10,
		margin: 'auto',
		maxWidth: 370,
		borderRadius: 3,
		boxShadow: ({ shadow }) => (shadow ? ` 0 2px 4px 0 ${shadow}` : '0 2px 4px 0 #00000018'),
		backgroundColor: ({ background }) => background || '#fffcf3',
		'&$disabled': {
			background: 'none',
			boxShadow: 'none',
		},
	},
	disabled: {},
})(ButtonBase);

const useStyles = makeStyles({
	label: { fontSize: 13, fontWeight: 'bold' },
	labelWrapper: {
		maxWidth: 300,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	helperText: {
		fontSize: 13,
		fontWeight: ({ disabled }) => (disabled ? 'normal' : 600),
		color: '#52555c',
	},
	actionBlob: {
		backgroundColor: ({ blob }) => blob || 'black',
		borderRadius: 100,
		width: 30,
		height: 18,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		marginRight: 5,
		position: 'relative',
	},
	arrowIcon: { transform: 'rotate(-90deg)', position: 'absolute', left: 1 },
});

const RaisedLinkButton = ({ label, helperText = '', disabled = false, iconUrl=null, ...rest }) => {
	const classes = useStyles({ disabled, ...rest });

	return (
		<StyledButton {...rest} disabled={disabled}>
			<Box
				p={'15px 10px'}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				width="100%"
			>
				<div
					className={classes.labelWrapper}
					style={{ direction: label.length > 15 && 'ltr' }}
				>
					<span className={classes.label}>{label}</span>
				</div>
				<Box display="flex" alignItems="center">
					<span className={classes.helperText}>{helperText}</span>
					{!disabled &&
						(iconUrl ? (
							<img src={iconUrl} height={25} alt="Link Icon" />
						) : (
							<div className={classes.actionBlob}>
								<ArrowIcon className={classes.arrowIcon} />
							</div>
						))}
				</Box>
			</Box>
		</StyledButton>
	);
};

export default RaisedLinkButton;
