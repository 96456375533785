import * as React from 'react';
import { DialogContext } from 'context/DialogManager';
import { UserContext } from 'context/UserProvider';
import { useHistory } from 'react-router-dom';
import { ButtonBase, Menu, ListItem, Button, List, withStyles, Box } from '@material-ui/core';
import AcUnitRoundedIcon from '@material-ui/icons/AcUnitRounded';
import { isUserRole } from 'utils/functions.util';
// import ProgressBar from 'assets/profileprogress.png';
import ProfileShape from 'assets/profile-score.svg';
import UserImg from 'components/general/user/UserImg/UserImg';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledMenu: any = withStyles({
	paper: { backgroundColor: 'var(--background-color)', borderRadius: '2vh' },
})(Menu);

const StyledListItem: any = withStyles({
	divider: {
		borderColor: 'var(--secondary-color)',
		width: '85%',
		margin: 'auto',
	},
})(ListItem);

const StyledProggressBar: any = withStyles(() => ({
	root: {
		height: 5,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: 'var(--background-color)',
	},
	bar: {
		borderRadius: 5,
	},
}))(LinearProgress);
export interface UserSectionProps {
	handleLogout(): void;
}

const UserSection: React.FC<UserSectionProps> = ({ handleLogout }) => {
	const { activeUser } = React.useContext<any>(UserContext);
	const { setOpenDialog } = React.useContext(DialogContext);

	const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);
	const history = useHistory();

	return activeUser ? (
		<>
			<ButtonBase onClick={(event) => setPopoverAnchor(event.currentTarget)}>
				<p className="userName">
					{activeUser.firstName} {activeUser.lastName || ''}
				</p>
				<UserImg user={activeUser} borderColor="white" />
			</ButtonBase>

			<StyledMenu
				open={Boolean(popoverAnchor)}
				anchorEl={popoverAnchor}
				getContentAnchorEl={null}
				onClose={() => setPopoverAnchor(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<List disablePadding style={{ width: '220px' }}>
					<StyledListItem disableGutters dense>
						<div
							className="profileShape"
							style={{ backgroundImage: `url(${ProfileShape})` }}
						>
							<AcUnitRoundedIcon color="primary" style={{ fontSize: '3em' }} />
							<h3>קווסטר מתחיל</h3>
							{/* <img src={ProgressBar} width="90%" alt="User Progress Bar" /> */}
							<Box width="90%">
								<StyledProggressBar
									color="primary"
									value={25}
									variant="determinate"
								/>
							</Box>
						</div>
					</StyledListItem>
					{isUserRole(activeUser, 'admin') && (
						<StyledListItem disableGutters dense divider>
							<Button
								fullWidth
								onClick={() => {
									setPopoverAnchor(null);
									history.push('/adminpanel');
								}}
							>
								{'Admins hangout'}
							</Button>
						</StyledListItem>
					)}
					<StyledListItem divider disableGutters dense>
						<Button
							fullWidth
							onClick={() => {
								setPopoverAnchor(null);
								history.push(`/profile/${activeUser._id}`);
							}}
						>
							פרופיל
						</Button>
					</StyledListItem>
					<StyledListItem disableGutters dense>
						<Button
							fullWidth
							onClick={() => {
								setPopoverAnchor(null);
								handleLogout();
							}}
						>
							התנתקות
						</Button>
					</StyledListItem>
				</List>
			</StyledMenu>
		</>
	) : (
		<ButtonBase
			onClick={() => {
				setOpenDialog('login');
				setPopoverAnchor(null);
			}}
		>
			<p className="userName"> הרשמה/התחברות</p>
			<UserImg borderColor="white" />
		</ButtonBase>
	);
};

export default React.memo(UserSection);
