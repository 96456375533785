import * as React from 'react';
import s from './MapMealsPreviewStyle.module.scss';
import { Grid } from '@material-ui/core';
import Meal from 'types/meal';
import ImageLoader from 'components/general/ImageLoader';
import PlaceholderImage from 'assets/image-placeholder.svg';
import { getImageUrl, roundNumber } from 'utils/functions.util';
import ScoreStars from 'components/sidebar/new/ScoreStars';

export interface MapMealsPreviewProps {
	meal: Meal;
	onMealClick(meal: Meal): void;
	showPlace?: boolean;
}

const MapMealsPreview: React.FC<MapMealsPreviewProps> = ({
	meal,
	onMealClick,
	showPlace = false,
}) => {
	const imageUrl = getImageUrl(meal);
	return (
		<Grid container className={s.root} onClick={() => onMealClick(meal)}>
			<Grid item xs={4}>
				<div className={s.imageCarousel}>
					{imageUrl ? (
						<ImageLoader alt="Meal Image" item={meal} size="small" />
					) : (
						<img width={28} src={PlaceholderImage} alt="placeholder" />
					)}
				</div>
			</Grid>
			<Grid item xs className={s.details}>
				<p className={s.mealTitle}>{meal.title}</p>
				{showPlace && <p className={s.placeTitle}>{`מסעדת ${meal.place.title}`}</p>}
				<div className={s.reviewSection}>
					<span className={s.scoreCount}>{`(${meal.reviewsCount})`}</span>
					<div className={s.starsWrapper}>
						<ScoreStars
							count={meal.activeScore || 0}
							starSize={{ small: 12, large: 12 }}
						/>
					</div>
					<span className={s.score}>
						{meal.activeScore ? roundNumber(meal.activeScore) : 'טרם דורג'}
					</span>
				</div>
			</Grid>
		</Grid>
	);
};

export default MapMealsPreview;
