import * as React from 'react';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { ReactComponent as BurgerMenuIcon } from 'assets/icons/icons-menu.svg';
import { Button, Box, Grid, Drawer, ListItem, withStyles } from '@material-ui/core';
import UserSection from 'components/topbar/UserSection';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import { UserContext, UserContextType } from 'context/UserProvider';
// import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { openInNewTab } from 'utils/functions.util';
import LogoImg from 'assets/quest-logo.png';
import { MealContext } from 'context/ContextProvider';
import { MealContextType } from 'types/meal';
import { SearchContext, SearchContextType } from 'context/SearchProvider';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const StyledListItem: any = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
		alignItems: 'flex-start',
		color: (props: any) => (props.disabled ? 'gray' : 'black'),
		'&:hover': { cursor: 'pointer' },
	},
	divider: {
		borderColor: 'rgba(0,0,0,.2)',
	},
})(ListItem);

const DesktopTopBar: React.FC = () => {
	const inAdminPanel = useRouteMatch('/adminpanel');
	const { setOpenDialog } = React.useContext<DialogContextType>(DialogContext);
	const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
	const isHome = useRouteMatch({ path: '/', exact: true, strict: true });

	const { logUserOut } = React.useContext<UserContextType>(UserContext);
	const { getTopMeals } = React.useContext<MealContextType>(MealContext);
	const { cleanFilters, checkFilters, setSearching } = React.useContext<SearchContextType>(
		SearchContext
	);
	const history = useHistory();
	const currentPath = history.location.pathname;

	const LINK_LIST = [
		{
			title: 'יצירת קשר',
			desc: 'שיתוף פעולה, תרומה לקהילה, אוזן קשבת',
			onClick: () => {
				window.location.assign('mailto:questburger1@gmail.com');
				setOpenDrawer(false);
			},
		},
		// { title: 'על הקווסט', desc: '' },
	];

	const refreshPage = async () => {
		window.sessionStorage.removeItem('qb_r_t');
		if (!isHome) {
			history.replace('/');
		} else {
			if (checkFilters()) cleanFilters();
			else {
				setSearching(true);
				const top = await getTopMeals();
				if (top) setSearching(false);
			}
		}
	};

	return (
		<div className="topbarWrapper">
			<div className="barContainter">
				<Grid container alignItems="center">
					{inAdminPanel ? (
						<Grid item xs={3}>
							<Link
								to="/"
								replace
								style={{
									textAlign: 'right',
									float: 'right',
									fontSize: 15,
									color: 'var(--main-color)',
								}}
							>
								חזרה לדף הראשי
							</Link>
						</Grid>
					) : (
						<Grid item xs={3} className="searchContainer">
							<BurgerMenuIcon
								className="barsMenu"
								onClick={() => setOpenDrawer(true)}
							/>
							<div className="logoWrapper" onClick={refreshPage}>
								<img src={LogoImg} alt="QuestBurger Logo" width={28} />
								<h3 className="logoImage">קווסט בורגר</h3>
							</div>
							<InfoOutlinedIcon
								fontSize="small"
								className={'infoIcon'}
								onClick={() => setOpenDialog('welcome')}
							/>
						</Grid>
					)}
					<Drawer anchor={'right'} open={openDrawer} onClose={() => setOpenDrawer(false)}>
						<Box
							p={2}
							display="flex"
							flexDirection="column"
							height="100%"
							justifyContent="space-between"
							dir="rtl"
						>
							<Box display="flex" flexDirection="column">
								{LINK_LIST.map((item, index) => (
									<StyledListItem
										disabled={!item.onClick}
										divider={index < LINK_LIST.length - 1 && true}
										key={index}
										onClick={item.onClick || null}
									>
										<h2 className={'itemTitle'}>{item.title}</h2>
										<p className={'itemDesc'}>{item.desc}</p>
									</StyledListItem>
								))}
								<Button
									onClick={() =>
										openInNewTab('https://instagram.com/questburger')
									}
									color="primary"
									dir="ltr"
									variant="contained"
									endIcon={<InstagramIcon style={{ marginTop: 2 }} />}
								>
									!חפשו אותנו באינסטגרם
								</Button>
							</Box>
							<span
								className="smallLink"
								style={{ fontSize: 15 }}
								onClick={() => {
									history.push('/terms');
									setOpenDrawer(false);
								}}
							>
								תנאי השימוש באתר קווסט בורגר
							</span>
						</Box>
					</Drawer>
					<Grid item xs>
						<Button
							color="primary"
							className="textLink"
							onClick={() => currentPath !== '/' && history.push('/')}
						>
							<span>המבורגרים</span>
						</Button>
						<Button color="primary" className="textLink" disabled>
							<span>קווסטים (בקרוב)</span>
						</Button>
						<Button color="primary" className="textLink" disabled>
							<span>אוספים (בקרוב)</span>
						</Button>
					</Grid>
					<Grid item xs={4} className="userContainer">
						<Button
							color="primary"
							className="textLink"
							onClick={() => setOpenDialog('meal')}
						>
							<Box ml={1}>+ הוספת מנה</Box>
						</Button>
						<Button
							color="primary"
							className="textLink"
							onClick={() => setOpenDialog('place')}
						>
							<Box ml={1}>+ הוספת מסעדה</Box>
						</Button>
						<UserSection handleLogout={logUserOut} />
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default React.memo(DesktopTopBar);
