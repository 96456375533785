import * as React from 'react';
import MainButton from 'components/general/MainButton/MainButton';

const sectionStyle: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: ' space-between',
	height: 50,
	margin: '20px 0',
};

export interface FormButtonFooterProps {
	handleSubmit(): void;
	handleCancel(): void;
	loading: boolean;
	cancelLabel?: string;
	submitLabel?: string;
	[x: string]: any;
}

const FormButtonFooter: React.FC<FormButtonFooterProps> = ({
	handleSubmit,
	handleCancel,
	cancelLabel,
	submitLabel,
	loading,
	...rest
}) => {
	return (
		<div style={sectionStyle}>
			<MainButton
				backgroundColor="black"
				color="white"
				fontSize={16}
				type="button"
				handleClick={handleCancel}
				minWidth={80}
				disabled={loading}
			>
				{cancelLabel || 'ביטול'}
			</MainButton>
			<MainButton
				fontSize={16}
				minWidth={210}
				type="button"
				handleClick={handleSubmit}
				loading={loading}
				{...rest}
			>
				{submitLabel || 'שליחה'}
			</MainButton>
		</div>
	);
};

export default React.memo(FormButtonFooter);
