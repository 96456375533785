import React from 'react';
import { Box, makeStyles, ButtonBase } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		height: 35,
		width: 35,
	},
});
export default function NumberScaleComponent({ size, onChange }) {
	const classes = useStyles();
	const [selected, setSelected] = React.useState(null);

	const renderNumbers = () => {
		const numberBoxes = [];
		for (let i = 1; i <= size; i++) {
			numberBoxes.push(
				<ButtonBase
					key={i}
					style={
						selected === i
							? { backgroundColor: 'var(--main-color)' }
							: { backgroundColor: 'lightgray' }
					}
					classes={{ root: classes.root }}
					onClick={() => {
						setSelected(i);
						onChange(i);
					}}
				>
					{i}
				</ButtonBase>,
			);
		}
		return numberBoxes;
	};

	return (
		<>
			<Box
				width="80%"
				justifyContent="space-between"
				display="flex"
				flexDirection="row"
				dir="ltr"
			>
				{renderNumbers((items) => items.map((item) => item))}
			</Box>
			<Box
				display="flex"
				width="80%"
				flexDirection="row"
				justifyContent="space-between"
			>
				<p>סבירות גבוהה מאוד</p>
				<p>סבירות נמוכה מאוד</p>
			</Box>
		</>
	);
}
