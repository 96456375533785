import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { SelectComponentsProps } from 'react-select/src/Select';

const styles: SelectComponentsProps = {
	container: (base: React.CSSProperties) => ({
		...base,
		fontSize: 13,
		width: 'calc(100% - 1px)',
	}),
	control: (base: React.CSSProperties, state: SelectComponentsProps) => ({
		...base,
		border: state.isFocused && '1px solid var(--main-color)',
		// This line disable the blue border
		boxShadow: 'none',
		cursor: 'text',
	}),
	multiValueRemove: (base: React.CSSProperties) => ({
		...base,
		':hover': {
			backgroundColor: 'var(--secondary-color)',
			cursor: 'pointer',
		},
	}),
	Input: (base: React.CSSProperties) => ({
		...base,
		fontSize: 13,
	}),
	indicatorsContainer: (base: React.CSSProperties) => ({
		...base,
		cursor: 'pointer',
	}),
	placeholder: (base: React.CSSProperties) => ({
		...base,
		fontSize: 13,
	}),
	option: (base: React.CSSProperties, state: SelectComponentsProps) => ({
		...base,
		color: 'black',
		backgroundColor: state.isFocused ? 'rgba(255, 221, 129, 0.7)' : 'inherit',
		cursor: state.isFocused ? 'pointer' : 'default',
	}),
	menu: (base: React.CSSProperties) => ({
		...base,
		zIndex: 30,
		maxHeight: 140,
		width: 'calc(100% - 6px)',
		marginRight: 3,
	}),
	menuList: (base: React.CSSProperties) => ({
		...base,
		zIndex: 30,
		maxHeight: 140,
		scrollbarWidth: 'thin',
	}),
};

interface AutoCompleteInputProps {
	type: 'createable' | 'async' | 'normal';
	noOptionsMessage?: (obj: { inputValue: string }) => string | null;
	loadOptions?(inputValue: string): Promise<any>;
	[x: string]: any;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
	type = 'normal',
	loadOptions,
	...props
}) => {
	if (type === 'createable') {
		return (
			<CreatableSelect
				{...props}
				hideSelectedOptions
				isRtl
				noOptionsMessage={() => 'אין לנו הצעות הפעם :/'}
				isClearable={false}
				styles={styles}
				theme={(theme) => ({
					...theme,
					borderRadius: 3,
					colors: {
						...theme.colors,
						primary: 'var(--main-color)',
					},
				})}
			/>
		);
	} else if (type === 'async' && loadOptions) {
		return (
			<AsyncSelect
				{...props}
				loadOptions={loadOptions}
				hideSelectedOptions
				styles={styles}
				theme={(theme) => ({
					...theme,
					borderRadius: 3,
					colors: {
						...theme.colors,
						primary: 'var(--main-color)',
					},
				})}
			/>
		);
	} else {
		return (
			<Select
				{...props}
				hideSelectedOptions
				styles={styles}
				theme={(theme) => ({
					...theme,
					borderRadius: 3,
					colors: {
						...theme.colors,
						primary: 'var(--main-color)',
					},
				})}
			/>
		);
	}
};

export default AutoCompleteInput;
