import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NoLocationComponent = ({ message }) => {
	const history = useHistory();
	return (
		<Box
			display="flex"
			m="auto"
			flexDirection="column"
			alignItems="center"
			height="100%"
			top={50}
			position="relative"
			justifyContent="center"
			zIndex={100}
		>
			<p>{message}</p>
			<Button color="primary" variant="contained" onClick={() => history.replace('/')}>
				לדף הבית
			</Button>
		</Box>
	);
};
export default NoLocationComponent;
