import * as React from 'react';
import s from './UserMenuSectionStyle.module.scss';
import { ReactComponent as BackgroundImage } from 'assets/login-mini-background.svg';
import UserImg from 'components/general/user/UserImg/UserImg';
import { DialogContext, DialogContextType } from 'context/DialogManager';
import User from 'types/user';
import { UserContext } from 'context/UserProvider';
import { ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface UserMenuSectionProps {
	user: User;
	afterClickEvent(): void;
}
const UserMenuSection: React.FC<UserMenuSectionProps> = ({ user, afterClickEvent }) => {
	const { logUserOut } = React.useContext<any>(UserContext);
	const { setOpenDialog } = React.useContext<DialogContextType>(DialogContext);
	const history = useHistory();

	const openUserPanel = () => {
		history.push(`/profile/${user._id}`);
		afterClickEvent();
	};

	const handleLogout = () => {
		logUserOut();
		afterClickEvent();
	};

	const handleLogin = () => {
		setOpenDialog('login');
		afterClickEvent();
	};

	return (
		<div className={s.root} style={{ height: user ? 150 : 200 }}>
			<BackgroundImage className={s.backgroundImage} />
			{user ? (
				<>
					<ButtonBase onClick={openUserPanel}>
						<UserImg
							size={60}
							borderColor="white"
							backgroundColor="black"
							user={user}
						/>
					</ButtonBase>
					<span className="userName" onClick={openUserPanel}>
						{user.firstName} {user.lastName || ''}
					</span>
					<p className={`mainTextLink ${s.logoutBtn}`} onClick={handleLogout}>
						התנתקות
					</p>
				</>
			) : (
				<div onClick={handleLogin}>
					<h1 className={s.callToAction}>הרשמה / התחברות</h1>
					<h3 className={s.ctaDescription}>הצטרפו לקווסט לשיקום עולם המסעדנות</h3>
				</div>
			)}
		</div>
	);
};

export default React.memo(UserMenuSection);
