import React from 'react';
import { PulseLoader } from 'react-spinners';
import s from './LoadingOverlayStyle.module.scss';

export interface LoadingOverlayProps {
	message?: string;
	size?: number;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
	message,
	size = 20,
	...props
}) => {
	return (
		<div className={s.root}>
			{message && <h2>{message}</h2>}
			<PulseLoader
				color={'var(--main-color)'}
				size={size}
				margin={3}
				{...props}
			/>
		</div>
	);
};

export default LoadingOverlay;
