import * as React from 'react';
import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AnimatePresence, motion } from 'framer-motion';

interface QuestSnakbarProps {
	message: string | null;
	handleClose?(event: React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason): void;
	noIcon: boolean;
	duration?: number;
	alertType?: 'error' | 'success' | 'info' | 'warning' | undefined;
}

const QuestSnakbar: React.FC<QuestSnakbarProps> = ({
	message,
	handleClose,
	noIcon,
	duration = 4000,
	alertType = 'error',
}) => {
	return (
		<Snackbar
			open={Boolean(message)}
			autoHideDuration={duration}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			dir="ltr"
		>
			<Alert
				elevation={6}
				onClose={handleClose}
				style={{ fontSize: 16, textAlign: 'right' }}
				variant="filled"
				icon={noIcon && ' '}
				severity={alertType}
			>
				<AnimatePresence>
					{message && (
						<motion.span
							initial={{ opacity: 0, direction: 'rtl' }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							<span dir="rtl">{message}</span>
						</motion.span>
					)}
				</AnimatePresence>
			</Alert>
		</Snackbar>
	);
};

export default React.memo(QuestSnakbar);
