import * as React from 'react';
import './TopBarStyle.scss';
import DesktopTopBar from 'components/topbar/DesktopTopBar';
import MobileTopBar from 'components/topbar/MobileTopBar';
import { BrowserView, MobileView } from 'react-device-detect';

export interface TopBarComponentProps {
	versionNum: string;
}

const TopBarComponent: React.FC<TopBarComponentProps> = ({ versionNum }) => {
	return (
		<>
			<BrowserView>
				<DesktopTopBar />
			</BrowserView>
			<MobileView>
				<MobileTopBar versionNum={versionNum} />
			</MobileView>
		</>
	);
};

export default React.memo(TopBarComponent);
