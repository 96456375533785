import * as React from 'react';
import User from 'types/user';
import s from './UserImgStyle.module.scss';

export interface UserImgProps {
	user?: User;
	borderColor?: string;
	backgroundColor?: string;
	size?: number;
}

const UserImg: React.FC<UserImgProps> = ({
	user,
	borderColor = 'black',
	backgroundColor = 'inherit',
	size = 23,
}) => {
	return (
		<div
			className={s.root}
			style={{ borderColor: borderColor, backgroundColor, width: size, height: size }}
		>
			{user &&
				(user.photoUrl ? (
					<div style={{ backgroundImage: `url(${user.photoUrl})` }} className={s.image} />
				) : (
					<span
						className={s.nameTag}
						style={{ color: borderColor, fontSize: size > 23 ? size / 2.5 : 13 }}
					>
						{`${user.firstName.charAt(0)}${user.lastName?.charAt(0) || ''}`}
					</span>
				))}
		</div>
	);
};

export default React.memo(UserImg);
